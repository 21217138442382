import Environment, { baseUrl } from "./Environment";

class PageService {
    async getPage(page, locale) {

        
        const response = await Environment.get(`${baseUrl}/api/${page}?locale=${locale}&populate[banner][populate]=*&populate[banner_mobile][populate]=*&populate[box_header][populate]=*&populate[about][populate]=*&populate[board_product][populate]=*`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async getExecutiveById(payload) {
        const response = await Environment.get(`${baseUrl}/api/home/${payload}?populate=*`)
            .then((response) => {
                return response.data;
            }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
}

export default new PageService();