import React, { useEffect } from "react";
import { baseUrl } from "../../services/Environment";

const BoardProduct = ({ title, details, image, id }) => {
  return (
    <>
      <div key={id} className="slick-slide w-100">
        <div
          className="slick-slide-content section sec-cta overlay "
          style={{
            backgroundImage: `url(${baseUrl + image.data.attributes.url})`,
            backgroundPosition: "center",
          }}
        >
          <div
            className="container d-flex justify-content-between"
            style={{
              minHeight: "30em",
              alignItems: "center",
            }}
          >
            <div></div>
            <div
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-delay="0"
              style={{ textAlign: "right" }}
            >
              <h1
                className="heading fw-bold text-white"
                style={{ color: "#fff" }}
              >
                {title}
              </h1>
              <p>{details[0].children[0].text}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardProduct;
