/** @format */

import React, { useState } from "react";
import {
  HeaderOverlay,
  HeaderOverlayTwo,
  HeaderOverlayTwoMobile,
} from "../../components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const qs = require("qs");

const Header = ({ pagesResult }) => {
  // ## SLIDE ##
  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: false,
    nextArrow: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnFocus: false,
  };
  // ## SLIDE ##
  const banner = pagesResult?.data.attributes.banner.data.attributes;
  const bannerMobile =
    pagesResult?.data.attributes.banner_mobile.data.attributes;

  return (
    <>
      <div className="d-none d-md-block">
        <HeaderOverlay
          bannerURL={banner.url}
          bannerALT={banner.alternativeText}
        ></HeaderOverlay>
      </div>

      <div className="d-md-none d-sm-block">
        <HeaderOverlay
          bannerURL={bannerMobile.url}
          bannerALT={bannerMobile.alternativeText}
        ></HeaderOverlay>
      </div>

      <div className="container">
        <div className="row">
          {pagesResult.data.attributes.box_header.map((element, index) => (
            <div
              className="col-md-4 d-none d-xxl-block"
              data-aos="fade-up"
              data-aos-delay="0"
              key={element.id}
            >
              <HeaderOverlayTwo
                details={element.details}
                images={element.images}
                imagesFull={element.images_full}
                title={element.title}
                id={element.id}
              />
            </div>
          ))}
          <div className="slider-container mx-auto" style={{ width: "99%" }}>
            <Slider className="page-banner-position " {...settings}>
              {pagesResult.data.attributes.box_header.map((element, index) => (
                <div
                  className="col-12 d-xxl-none d-sm-block"
                  data-aos="fade-up"
                  data-aos-delay="0"
                  key={element.id}
                >
                  <HeaderOverlayTwoMobile
                    details={element.details}
                    images={element.images}
                    imagesFull={element.images_full}
                    title={element.title}
                    id={element.id}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
