import React from "react";
import SustainableItem from "../SustainableItem";
import MidLine from "../../MidLine/MidLine";

function Development({ lang }) {
  return (
    <>
      {/*---------- การขับเคลื่อนธุรกิจเพื่อความยั่งยืน --------------*/}
      <section className="container section">
        <h1 className="title-line fw-bold ">
          {lang === "th"
            ? `การขับเคลื่อนธุรกิจเพื่อความยั่งยืน `
            : ` Sustainable Development for business`}
        </h1>
        <p>
          {lang === "th"
            ? `บริษัท เอ็นซีแอล อินเตอร์เนชั่นแนล โลจิสติกส์ จำกัด (มหาชน) มีความมุ่งมั่นในการดำเนินธุรกิจที่เป็นประโยชน์ต่อเศรษฐกิจ สังคม และสิ่งแวดล้อม โดยที่มีแนวทางการดำเนินธุรกิจควบคู่กับการจัดการด้วยความยั่งยืนด้วยความรับผิดชอบต่อสังคมและสิ่งแวดล้อมภายใต้หลักการ 8 ข้อดังนี้`
            : `NCL International Logistics Plc. is committed to operating businesses that are beneficial to the economy society and environmental. The policy to conduct business with social and environmental responsibility under the 8 principles as follows: `}
        </p>
        <SustainableItem
          LorR="left"
          title={
            lang === "th"
              ? `การกำกับดูแลกิจการที่ดี `
              : `Good Corporate Governance `
          }
          content={
            lang === "th"
              ? `บริษัทฯจะดำเนินธุรกิจอย่างถูกต้องตามกฎหมายและกฎระเบียบที่เกี่ยวข้อง
            มีความโปร่งใส เปิดเผยข้อมูลที่สำคัญและตรวจสอบได้
            โดยบริษัทจะปฏิบัติตามนโยบายการกำกับดูแลกิจการที่ดีโดยคำนึงถึงประโยชน์ที่จะเกิดขึ้นกับผู้มีส่วนได้เสียทุกฝ่าย`
              : `The Company will operate its business in accordance with relevant laws and regulations, transparency, disclosure of important information and audit. The Company will comply with the good corporate governance policy, considering the benefits with all stakeholders.
  `
          }
          src="/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)-38_0.png"
        />
        <SustainableItem
          title={
            lang === "th"
              ? ` การประกอบธุรกิจด้วยความเป็นธรรม`
              : `Operating business with fairness `
          }
          content={
            lang === "th"
              ? ` บริษัทฯจะส่งเสริมการแข่งขันทางการค้าอย่างเสรี หลีกเลี่ยงการดำเนินการที่อาจก่อให้เกิดความขัดแย้งทางผลประโยชน์และการละเมิดทรัพย์สินทางปัญญา รวมถึงส่งเสริมความรับผิดชอบต่อสังคมในห่วงโซ่ธุรกิจทุกขั้นตอน (Business Chain)`
              : ` The Company will promote free trade, avoid potential conflicts of interest and infringe on intellectual property rights, and promote social responsibility in the business chain at all stages.
   `
          }
          src="/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)-39_0.png"
        />
        <SustainableItem
          LorR="left"
          title={
            lang === "th" ? `การต่อต้านทุจริตคอร์รัปชัน ` : `Anti-Corruption `
          }
          content={
            lang === "th"
              ? `  บริษัทฯต่อต้านการทุจริตคอร์รัปชันทั้งภายในและภายนอกองค์กรเพื่อความโปร่งใสและสามารถตรวจสอบได้ รวมทั้งให้ความร่วมมือกับองค์กรต่าง ๆ เพื่อการต่อต้านทุจริตคอร์รัปชันทุกรูปแบบ และส่งเสริมให้พนักงานตระหนักในการต่อต้านการทุจริตคอร์รัปชัน `
              : `The Company has the policy to support anti-corruption both inside and outside the organization for transparency and accountability. It also cooperates with various organizations to fight against corruption and encourages employees to be aware of the anti-corruption. 
   `
          }
          src="/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)-40_0.png"
        />
        <SustainableItem
          title={
            lang === "th"
              ? `สิทธิมนุษยชนและการปฏิบัติต่อพนักงาน `
              : `Human Right and Employee Treatment `
          }
          content={
            lang === "th"
              ? ` บริษัทฯสนับสนุนและเคารพในการปกป้องสิทธิมนุษยชน โดยจะปฏิบัติต่อพนักงานอย่างเท่าเทียมและเป็นธรรมด้วยการจัดให้มีสวัสดิการ ความปลอดภัยและสุขอนามัยในสถานที่ทำงาน รวมถึงส่งเสริมการเรียนรู้และพัฒนาบุคลากรเพื่อยกระดับการทำงานของพนักงานอย่างมืออาชีพ บริษัทฯจะสนับสนุนการพัฒนาระบบการทำงานและส่งเสริมให้พนักงานมีส่วนร่วมในการทำประโยชน์กับสังคมทั้งทางตรงและทางอ้อม`
              : ` The Company supports and respects the protection of human rights by ensuring equal and fair treatment of employees by providing welfare, safety, and hygiene in the workplace, as well as promoting learning and development of personnel to enhance Professional work. The Company will support the development of the work system and encourage its employees to participate in direct and indirect social benefits. 
   `
          }
          src="/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)-41_0.png"
        />

        <SustainableItem
          LorR="left"
          title={
            lang === "th"
              ? ` ความรับผิดชอบต่อผู้บริโภค`
              : `Company’s responsibility on consumer `
          }
          content={
            lang === "th"
              ? `บริษัทฯจะพัฒนาการให้บริการที่ไม่ก่อให้เกิดอันตรายต่อผู้บริโภคและผลกระทบต่อสิ่งแวดล้อม โดยรักษาคุณภาพให้ตรงตามหรือสูงกว่าความคาดหมายของผู้บริโภคภายใต้เงื่อนไขที่เป็นธรรม และจะให้ข้อมูลเกี่ยวกับผลิตภัณฑ์และบริการที่ถูกต้อง เพียงพอ ไม่เกินความเป็นจริง พร้อมทั้งรักษาความลับของลูกค้าไม่นำไปใช้เพื่อประโยชน์ของตนเองหรือผู้ที่เกี่ยวข้องโดยมิชอบ`
              : `The Company will develop services that do not harm consumers and impact the environment by maintaining quality to meet or exceed consumer expectations under fair conditions. It will provide information about products and services that are accurate, adequate, without exaggeration, and keeping the confidentiality of clients and not used it to their own interests or those involving wrongfully.`
          }
          src="/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)-42_0.png "
        />

        <SustainableItem
          title={
            lang === "th"
              ? ` สิ่งแวดล้อมและความปลอดภัย`
              : `Environment and Safety `
          }
          content={
            lang === "th"
              ? `บริษัทฯจะดำเนินการให้มีกระบวนการวิเคราะห์ความเสี่ยงและผลกระทบด้านสิ่งแวดล้อมและความปลอดภัยในทุกกระบวนการทางธุรกิจ รวมถึงใช้ทรัพยากรอย่างมีประสิทธิภาพและประหยัดพลังงานตามหลักการสากล `
              : `The Company will undertake risk and environmental impact analysis and safety in all business processes. It also uses resources efficiently and energy-saving in accordance with international principles. `
          }
          src="/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)-43_0.png"
        />

        <SustainableItem
          LorR="left"
          title={
            lang === "th"
              ? ` การมีส่วนร่วมพัฒนาชุมชนและสังคม`
              : ` Participation in Community and Society`
          }
          content={
            lang === "th"
              ? ` บริษัทฯส่งเสริมการใช้กระบวนการทางธุรกิจเพื่อประโยชน์ในการพัฒนาคุณภาพชีวิต ช่วยสร้างเศรษฐกิจและความเข้มแข็งให้กับชุมชนเพื่อนบ้าน และสังคมไทย `
              : ` The Company will undertake risk and environmental impact analysis and safety in all business processes. It also uses resources efficiently and energy-saving in accordance with international principles.
  `
          }
          src="/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)-44_0.png"
        />
        <SustainableItem
          title={
            lang === "th"
              ? `การพัฒนาและเผยแพร่นวัตกรรมจากการดำเนินความรับผิดชอบต่อสังคม  `
              : `Develop and Disseminate Innovation from CSR `
          }
          content={
            lang === "th"
              ? `บริษัทฯจะสนับสนุนการสร้างสรรค์และส่งเสริมการมีส่วนร่วมของผู้มีส่วนได้เสีย (Co-Creation) ในการพัฒนานวัตกรรมที่สร้างความสมดุลระหว่างมูลค่าและคุณค่าต่อชุมชน สังคม และสิ่งแวดล้อมควบคู่ไปกับความเจริญเติบโตของธุรกิจอย่างยั่งยืน  `
              : `The Company shall promote the creation and promotion of co-creation of all stakeholders in the development of innovation that balances values and worth of the community, society, and the environment, along with sustainable business growth.
  `
          }
          src="/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)-44_0.png"
        />
      </section>

      {/*---------- การจัดการผลกระทบต่อผู้มีส่วนได้เสีย 1.ห่วงโซ่คุณค่าของธุรกิจ (Value Chain) --------------*/}
      <section className="bg-gray">
        <div className="row container mx-auto xl-py35">
          <div className="col-lg-8 my-auto">
            <h1 className="title-line py-4">
              {lang === "th"
                ? `การจัดการผลกระทบต่อผู้มีส่วนได้เสีย`
                : `Managing the impact on stakeholders `}
            </h1>
            <p className="fw-bold">
              {lang === "th"
                ? `1.ห่วงโซ่คุณค่าของธุรกิจ (Value Chain)`
                : `1. Business Value Chain`}
            </p>
            <p>
              {lang === "th"
                ? `บริษัท เอ็นซีแอล อินเตอร์เนชั่นแนล โลจิสติกส์ จำกัด (มหาชน) และบริษัทในเครือ ดำเนินธุรกิจด้วยความรับผิดชอบตามหลักธรรมาภิบาล โดยคำนึงถึงผล กระทบด้านเศรษฐกิจ สังคมและสิ่งแวดล้อม เพื่อขับเคลื่อนองค์กรให้มีการพัฒนาอย่างยั่งยืนและได้มีการยกระดับการดำเนินการนี้ไปยังผู้มีส่วนได้เสียของบริษัทฯ โดยมีวัตถุประสงค์ผลักดันและส่งเสริมให้เกิดความร่วมมือกันในการดำเนินธุรกิจอย่างยั่งยืนไปพร้อม ๆ กัน บริษัทฯคำนึงถึงความสัมพันธ์ของผู้มีส่วนได้เสียหลักในกิจกรรมซึ่งเกี่ยวข้องกับการดำเนินธุรกิจตั้งแต่ต้นน้ำจนถึงปลายน้ำเพื่อสะท้อนให้เห็นว่าบริษัทฯมีความมุ่งมั่นที่จะสร้างคุณค่าให้แก่สินค้าและบริการเพื่อตอบสนองความคาดหวังของผู้มีส่วนได้เสียหาย`
                : `NCL International Logistics Public Company Limited and its subsidiaries operate with responsibility in accordance with good governance principles. Considering the economic social and environmental impacts to drive the organization to sustain development and to enhance this operation to its stakeholders. With the aim of driving and encouraging cooperation in sustainable business operations at the same time, the Company considers the relationship of key stakeholders in activities related to business operations from upstream to downstream to reflect that the Company is committed to create value for goods and services to meet the expectation of all major stakeholders.`}
            </p>
          </div>
          <div className="col-lg-4">
            <div
              id="BgBanner"
              style={{
                backgroundImage:
                  "url(/images/sustainable/ความรับผิดชอบต่อสังคม-46_1.jpg)",
                height: "400px",
                backgroundSize: "350px 400px",
              }}
            ></div>
          </div>
        </div>
      </section>

      {/*------ ห่วงโซ่คุณค่าของธุรกิจ -----*/}
      <section className="container section">
        <h4 className="fw-bold">
          {lang === "th"
            ? `ห่วงโซ่คุณค่าของธุรกิจในกิจกรรมหลักของบริษัทฯแสดงได้ ดังนี้`
            : `Business Value Chain`}
        </h4>
        <div className="d-flex justify-content-center">
          <img
            className="my-5 mobile_bgsize "
            src={
              lang === "th"
                ? "/images/sustainable/ความรับผิดชอบต่อสังคม-47_0.png"
                : "/images/sustainable/ความรับผิดชอบต่อสังคมENG2-38.jpg"
            }
            style={{
              height: "clamp(70px,22vw,420px)",
            }}
          ></img>
        </div>

        <div>
          <MidLine
            color={`#d3aa94`}
            title={
              lang === "th" ? `การบริหารปัจจัยการผลิต ` : ` Inbound Logistics`
            }
          />
          <ul>
            <li>
              {lang === "th"
                ? `การหาพันธมิตรหรือคู่ค้าทางการค้าเพื่อเพิ่มการทำงานที่มีประสิทธิภาพในต่างประเทศ เพื่อให้สามารถเพิ่มศักยภาพการทำงานในจุดหมายปลายทางในประเทศต่าง ๆ ได้ทั่วโลก`
                : `Finding a partner or trade partners to increase effective work abroad to be able to increase the potential working in destinations in countries around the world.
  `}
            </li>
            <li>
              {lang === "th"
                ? `การได้มาของพันธมิตรหรือคู่ค้าที่จะส่งผลต่อจุดแข็งและโอกาสของบริษัทฯในการเป็นผู้นำทางด้านขนส่งในเส้นทางการค้าหลักของโลก ทั้งในและต่างประเทศ`
                : `Acquisition of alliances or business partners that will affect the strengths and opportunities of the Company to become a leading logistics provider in the world’s major trade routes.
  `}
            </li>
            <li>
              {lang === "th"
                ? `การหาผู้เชี่ยวชาญเฉพาะด้านเพื่อเพิ่มศักยภาพในการให้บริการลูกค้าเช่น หน่วยงานภาครัฐ (ศุลกากร)`
                : `Finding specialists to enhance the potential in customer service such as Government Agencies (Customs) for domestic and international route.
  `}
            </li>
            <li>
              {lang === "th"
                ? `การขยายรายได้ของบริษัทฯจากการเป็นผู้ให้บริการด้านโลจิสติกส์ ให้ครบวงจรมากขึ้นโดยการขยายธุรกิจคลังจัดเก็บสินค้าและบริการขนส่งสินค้าภายในประเทศและประเทศเพื่อนบ้าน`
                : `Increasing the Company’s revenue from becoming a leading logistics service provider to be more integrated by expanding its warehouse business, inland transportation in domestic and neighboring countries.
  `}
            </li>
          </ul>
          <MidLine
            color={`#a9bece`}
            title={lang === "th" ? `การปฏิบัติการ` : `Operations`}
          />
          <ul>
            <li>
              {lang === "th"
                ? `อำนวยความสะดวกในการขนส่งและเปรียบเทียบราคาตลาดรวมถึงประสานงานกับสายเรือเพื่อให้ลูกค้าได้รับต้นทุนในการขนส่งที่ต่ำที่สุด
  `
                : `Facilitate transportation and price comparison process to customers, as well as coordinate with the shipping agent so that customers receive the lowest transportation costs.
  `}
            </li>
            <li>
              {lang === "th"
                ? `พนักงานได้รับการอบรมเพื่อให้มีความรู้และศักยภาพในการทำงานแต่ละภาคส่วนเพื่อให้การบริการลูกค้าเป็นไปได้อย่างมีประสิทธิภาพ
  `
                : `Employees are trained to have knowledge and potential to work in each sector to ensure effective customer service.
  `}
            </li>
            <li>
              {lang === "th"
                ? `บริษัทฯมีการปรับปรุง พัฒนาระบบปฏิบัติงานของพนักงานให้ตอบสนองต่อเทคโนโลยีใหม่อยู่เสมอ
  `
                : `The Company constantly improves the work system of employees to respond the new technologies.
  `}
            </li>
          </ul>

          <MidLine
            color={`#64a9cb`}
            title={
              lang === "th"
                ? `การกระจายสินค้าและบริการ`
                : `Outbound Logistics
  `
            }
          />
          <ul>
            <li>
              {lang === "th"
                ? `บริษัทฯมีอาคารสำนักงานเพื่อให้ลูกค้าติดต่อและเพื่ออำนวยความสะดวกแก่ลูกค้าในการรับและให้บริการ
  `
                : `The Company has an office building for customers to contact and to facilitate customers in receiving and providing services.
  `}
            </li>
            <li>
              {lang === "th"
                ? `บริษัทฯมีช่องทางการติดต่อที่หลากหลายสำหรับลูกค้าเพื่ออำนวยความสะดวกและรวดเร็วต่อการติดต่อหรือเข้าถึงข้อมูลการดำเนินงาน
  `
                : `The Company has several contact channels for customers to facilitate and access operational information promptly.
  `}
            </li>
          </ul>

          <MidLine
            color={`#1c5880`}
            title={
              lang === "th" ? `การตลาดและการขาย  ` : `Marketing and Sales  `
            }
          />
          <ul>
            <li>
              {lang === "th"
                ? `พนักงานมีการสำรวจราคาตลาดในธุรกิจอุตสาหกรรมเดียวกันเพื่อเสนอราคาแก่ลูกค้าในราคาที่ต่ำที่สุดหรือตามความพึงพอใจของลูกค้า
  `
                : `Employees survey market price in the same industrial business to quote customers at the lowest prices or according to customer satisfaction
  `}
            </li>
            <li>
              {lang === "th"
                ? `บริษัทฯมีการแก้ไขเพิ่มเติมข้อมูลช่องทางการติดต่อลูกค้าให้เป็นปัจจุบันอยู่เสมอเพื่อใช้ในการสื่อสารและการประชาสัมพันธ์ผ่านช่องทางต่าง ๆ ให้ครอบคลุมมากที่สุด
  `
                : `The Company  has amendment customer contact channels information are always up to date for communication and public relations purposes    through various channels to cover as much as possible
  `}
            </li>
          </ul>

          <MidLine
            color={`#213b51`}
            title={lang === "th" ? `การบริการหลังการขาย` : `Customer Services`}
          />
          <ul>
            <li>
              {lang === "th"
                ? `บริษัทฯมีหน่วยงานที่รับฟังความคิดเห็นของลูกค้าเพื่อนำไปแก้ไข ปรับปรุงการปฏิบัติงานและบุคลากรให้มีประสิทธิภาพยิ่งขึ้น
  `
                : `There are agencies that gathering customer feedbacks to improve the Company operation and performance.
  `}
            </li>
            <li>
              {lang === "th"
                ? `บริษัทฯมีการสำรวจความต้องการของลูกค้าเพื่อนำไปพัฒนาระบบการดำเนินงานต่อไป
  `
                : `Customer needs are surveyed to further develop the operating system.
  `}
            </li>
            <li>
              {lang === "th"
                ? `บริษัทฯมีหน่วยงานที่รับเรื่องร้องเรียนของลูกค้าเพื่อนำไปพิจารณาดำเนินการแก้ไขในส่วนงานที่เกี่ยวข้อง
  `
                : `There is a department that accept customer complaints and feedback to consider and take corrective action in the relevant departments.`}
            </li>
          </ul>
        </div>
      </section>

      {/*------- 2. วิเคราะห์ผู้มีส่วนได้เสียในห่วงโซ่คุณค่าของธุรกิจ ---------*/}
      <section className="bg-gray ">
        <div className="row container mx-auto xl-py35">
          <div className="col-lg-8 my-auto">
            <p className="fw-bold ">
              {lang === "th"
                ? `2. วิเคราะห์ผู้มีส่วนได้เสียในห่วงโซ่คุณค่าของธุรกิจ`
                : `2.Stakeholder Analysis of the Business Value Chain
  `}
            </p>
            <p>
              {lang === "th"
                ? `การวิเคราะห์ความต้องการของผู้มีส่วนได้เสียของบริษัทฯ ช่วยให้บริษัทเข้าใจความต้องการและ ความคาดหวังของผู้มีส่วนได้เสียได้เป็นอย่างดี และทำให้บริษัทฯ สามารถพัฒนาการให้บริการรวมถึงกำหนดกิจกรรมที่สามารถตอบสนองความต้องการของผู้มีส่วนได้เสียแต่ละกลุ่มได้อย่างใกล้เคียงมากที่สุด          
  
  `
                : `Analysis of the needs of the Company's stakeholders help the Company understand the needs and the expectations of the stakeholders and enable the Company to develop services, as well as defining activities that can meet the needs of each group of stakeholders closely. 
  `}
            </p>
            <p>
              {lang === "th"
                ? `บริษัทฯตระหนักถึงความสำคัญของผู้มีส่วนได้เสียทั้งภายในและภายนอกบริษัท กล่าวคือ ผู้ถือหุ้น พนักงาน ผู้บริหารของบริษัทฯ และบริษัทย่อย เจ้าหนี้ คู่ค้า ลูกค้า ผู้ลงทุน ชุมชน หน่วยงานราชการและองค์กรอื่น ๆ ที่เกี่ยวข้องกับบริษัทฯและมีผลกระทบต่อการดำเนินธุรกิจของบริษัทฯ บริษัทฯ จึงได้กำหนดหลักปฏิบัติและการมีส่วนร่วมต่อผู้มีส่วนได้เสียเพื่อแสดงให้เห็นว่าบริษัทฯ มีความมุ่งมั่นที่จะสร้างคุณค่าให้แก่การบริการเพื่อตอบสนองความคาดหวังของผู้มีส่วนได้เสียทุกภาคส่วน
  `
                : `The Company recognizes the importance of all stakeholder groups whether it is the internal and external stakeholders such as shareholders, employees, executives of the Company and subsidiaries. Creditors, partners, customers, investors, communities, government agencies and other organizations related to the Company and affect the Company's business operations. Therefore, the Company has established guidelines and engagements with stakeholders to demonstrate that the Company commit to create service value to meet the expectations of all stakeholders.
  `}
            </p>
          </div>
          <div className="col-lg-4">
            <div
              id="BgBanner"
              style={{
                backgroundImage:
                  "url(/images/sustainable/ความรับผิดชอบต่อสังคม-48_0.jpg)",
                height: "350px",
                backgroundSize: "300px 350px",
              }}
            ></div>
          </div>
        </div>
      </section>
      {/*--------- วิเคราะห์ผู้มีส่วนได้เสียในห่วงโซ่คุณค่าของธุรกิจ ---------*/}
      <section className="container section">
        <h4 className="fw-bold">
          {lang === "th"
            ? ` วิเคราะห์ผู้มีส่วนได้เสียในห่วงโซ่คุณค่าของธุรกิจ`
            : ` Analysis of Stakeholders in Business Value Chain`}
        </h4>
        <div className="row">
          <img
            className="col-lg-3 col-md-4 col-6 py-4"
            src={
              lang === "th"
                ? ` /images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)--49_0.png`
                : `/images/sustainable/ความรับผิดชอบต่อสังคม 2 Eng-24_0.png `
            }
          />
          <img
            className="col-lg-3 col-md-4 col-6 py-4"
            src={
              lang === "th"
                ? `/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)--50_0.png `
                : `/images/sustainable/ความรับผิดชอบต่อสังคม 2 Eng-25_0.png `
            }
          />
          <img
            className="col-lg-3 col-md-4 col-6 py-4"
            src={
              lang === "th"
                ? `/images/sustainable/ความรับผิดชอบต่อสังคม 2 [Recovered]-39.png`
                : `/images/sustainable/ความรับผิดชอบต่อสังคม 2 Eng-26_0.png `
            }
          />
          <img
            className="col-lg-3 col-md-4 col-6 py-4"
            src={
              lang === "th"
                ? `/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)--52_0.png `
                : `/images/sustainable/ความรับผิดชอบต่อสังคม 2 Eng-27_0.png `
            }
          />

          <img
            className="col-lg-3 col-md-4 col-6 py-4"
            src={
              lang === "th"
                ? `/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)--53_0.png `
                : `/images/sustainable/ความรับผิดชอบต่อสังคม 2 Eng-28_0.png  `
            }
          />
          <img
            className="col-lg-3 col-md-4 col-6 py-4"
            src={
              lang === "th"
                ? ` /images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)--54_0.png`
                : `/images/sustainable/ความรับผิดชอบต่อสังคม 2 Eng-29_0.png  `
            }
          />
          {lang === "th" ? ` ` : ` `}

          <img
            className="col-lg-3 col-md-4 col-6 py-4"
            src={
              lang === "th"
                ? `/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)--55_0.png `
                : `/images/sustainable/ความรับผิดชอบต่อสังคม 2 Eng-30_0.png  `
            }
          />
          <img
            className="col-lg-3 col-md-4 col-6 py-4"
            src={
              lang === "th"
                ? `/images/sustainable/ความรับผิดชอบต่อสังคม-(การขับเคลื่อน)--55_0.png `
                : `/images/sustainable/ความรับผิดชอบต่อสังคม 2 Eng-31_0.png  `
            }
          />
        </div>
      </section>
    </>
  );
}

export default Development;
