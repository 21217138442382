import React from "react";

function OnlineList({ content, row }) {
  const backgroundColor = row % 2 === 0 ? "#eef3f7" : "white";

  return (
    <div className="d-flex mobile_col justify-content-between py-2">
      <h5
        className="m-0 w-100 px-4 py-2 "
        style={{ backgroundColor: backgroundColor }}
      >
        {content.title}
      </h5>
      <a
        href={content.url}
        className="d-flex online border-0 btn navHV align-items-center justify-content-center gap-1 bg-Lgray ms-3 px-3 py-2 rounded-pill"
        style={{ textTransform: "none" }}
      >
        <h6 className="m-0 ">Online</h6>
        <i
          className="fa-solid fa-magnifying-glass fa-lg"
          style={{ color: "black" }}
        ></i>
      </a>
    </div>
  );
}

export default OnlineList;
