import React, { useRef } from "react";

function TimeLineItem({ title, subTitle, content, row }) {
  const backgroundColor = row % 2 === 0 ? "#cccccc" : "#e6e6e6";

  return (
    <div className="text-black">
      <div
        // className="sticky-lg-top bg-Lgray py-5  px-5 boxShadow "
        className="sticky-lg-top py-4 px-5 border "
        style={{
          zIndex: "10",
          // itemStyle,
          backgroundColor: backgroundColor,
        }}
      >
        <div
          className="d-flex align-items-center timeline_Item "
          style={{ wordWrap: "break-word" }}
        >
          <h2
            className=" fw-bold "
            style={{
              // color: "#2d4f62",
              letterSpacing: "0.1em",
              lineHeight: "1.5em",
            }}
          >
            {title}
          </h2>
          <span className="fs-4 ps-4 timeline_sub">{subTitle}</span>
        </div>
      </div>
      <div className="p-4 border-start border-4 section container mx-2 my-3">
        <div className="xl_start" style={{ width: "85%" }}>
          <p className="text_size  text-start xl_start ">{content}</p>
        </div>
      </div>
    </div>
  );
}

export default TimeLineItem;
