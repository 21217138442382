import React from "react";

function HeaderCard({ title, content }) {
  return (
    <div>
      <h1 className=" fw-bold title-line-center text-center">{title}</h1>
      <p className=" py-5 text_size ">{content}</p>
    </div>
  );
}

export default HeaderCard;
