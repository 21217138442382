import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SideBar from "./SideBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Padding, RoundedCorner } from "@mui/icons-material";
import ModalCard from "../ModalCard/ModalCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  bgcolor: "#e6e6e6",
  // border: "1px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  py: 6,
  px: 8,
};

const HeaderTop = ({ children }) => {
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const lang = localStorage.getItem("ncl_lang");

  window.addEventListener("scroll", function () {
    const scrollPosition = window.scrollY;
    const targetPosition = 80; // ตำแหน่งที่คุณต้องการให้เปลี่ยนสี

    const contentElement = document.getElementById("content");

    if (scrollPosition >= targetPosition) {
      contentElement.classList.add("change-color");
    } else {
      contentElement.classList.remove("change-color");
    }
  });

  return (
    <Navbar
      key={""}
      expand={""}
      id="content"
      className="site-nav px-0 pb-0 navbar_padding"
      style={{ position: "fixed", zIndex: "40" }}
    >
      <Container>
        <Navbar.Brand className="navbar_padding">
          <a href="/">
            <img
              src="/images/icon/icon-09.png"
              // height="40"
              className="logo m-0 narbar_brand "
              alt="NCL Thailand logo"
            />
          </a>
        </Navbar.Brand>
        <Navbar className="justify-content-end flex-grow-1">
          <Nav.Link className="icon-nav-custom d-sm-flex" onClick={handleOpen}>
            <img
              src="/images/icon/icon-10.png"
              alt={"NCL Thailand" + t("str_siteLocation")}
              className="narbar_link img-icon-nav"
              style={{ cursor: "pointer" }}
            />
            <span className="pt-1 narbar_font">{t("str_siteLocation")}</span>
          </Nav.Link>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="modalWidth">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="d-flex justify-content-between">
                  <p
                    className="fs-4 m-0 "
                    style={{ cursor: "pointer", visibility: "hidden" }}
                    onClick={handleClose}
                  >
                    ✖
                  </p>
                  <div className="d-flex gap-2 align-items-center">
                    <h3 className="m-0 text-center fw-bold">
                      {lang === "th" ? "สถานที่ตั้ง" : "Site location"}
                    </h3>
                    <i class="fa-solid fa-map-location-dot"></i>
                  </div>
                  <p
                    className="fs-4 m-0 "
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  >
                    ✖
                  </p>
                </div>
              </Typography>
              {/* <hr className="border border-secondary " /> */}
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="row">
                  <ModalCard
                    href="/"
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/640px-Flag_of_Thailand.svg.png"
                    }
                    title={lang === "th" ? `ประเทศไทย` : `Thailand`}
                    content={"NCL INTERNATIONAL LOGISTICS PCL."}
                  />
                  <ModalCard
                    href={"https://www.nclinter.com/sg"}
                    target="_blank"
                    src={
                      "https://w7.pngwing.com/pngs/326/80/png-transparent-logo-singapore-brand-font-design-flag-logo-vector-icons.png"
                    }
                    title={lang === "th" ? `ประเทศสิงคโปร์` : `Singapore`}
                    content={"NCL INTER LOGISTICS (S) PTE LTD."}
                  />
                  <ModalCard
                    href={"https://www.nclinter.com/usa"}
                    target="_blank"
                    src={
                      "https://media.istockphoto.com/id/523382953/th/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%96%E0%B9%88%E0%B8%B2%E0%B8%A2/%E0%B8%98%E0%B8%87%E0%B8%AA%E0%B8%AB%E0%B8%A3%E0%B8%B1%E0%B8%90%E0%B8%AD%E0%B9%80%E0%B8%A1%E0%B8%A3%E0%B8%B4%E0%B8%81%E0%B8%B2.jpg?s=612x612&w=0&k=20&c=HECDBs_7bOuy4AQIqmwE2WWgWjNCXQvlzEb9GHQIFO8="
                    }
                    title={lang === "th" ? `ประเทศอเมริกา` : `America`}
                    content={"NCL INTERNATIONAL LOGISTICS USA INC."}
                  />
                </div>
              </Typography>
            </Box>
          </Modal>

          <Nav.Link
            href="#contact"
            className="icon-nav-custom d-none d-sm-flex"
          >
            <img
              src="/images/icon/icon-11.png"
              alt={"NCL Thailand" + t("str_contract")}
              className="narbar_link"
              style={{ cursor: "pointer" }}
            />
            <span className="pt-1 narbar_font">{t("str_contract")}</span>
          </Nav.Link>
          <Nav.Link>{children}</Nav.Link>
        </Navbar>

        <a
          data-bs-toggle="offcanvas"
          href="#offcanvasWithBothOptions"
          role="button"
          aria-controls="offcanvasWithBothOptions"
        >
          <Navbar.Toggle className="navbar-dark " />
        </a>
        {/* <div
          class="offcanvas offcanvas-start"
          data-bs-scroll="true"
          tabindex="-1"
          id="offcanvasWithBothOptions"
          aria-labelledby="offcanvasWithBothOptionsLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">
              Backdroped with scrolling
            </h5>
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <p>
              Try scrolling the rest of the page to see this option in action.
            </p>
          </div>
        </div> */}

        <div
          className="offcanvas offcanvas-end bg-blue px-3 container pt-3 sidebar_width "
          data-bs-scroll="true"
          tabIndex="-1"
          id="offcanvasWithBothOptions"
          aria-labelledby="offcanvasWithBothOptions"
        >
          <div className="offcanvas-header">
            <div
              className="offcanvas-title d-flex justify-content-between w-100"
              id="offcanvasWithBothOptionsLabel"
            >
              <img
                src="/images/icon/icon-09.png"
                className="logo m-0 narbar_brand "
                alt="NCL Thailand logo"
              />
              <div
                type="button"
                className="btn-close btn-close-white border-0 text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></div>
            </div>
          </div>
          <div className="offcanvas-body ">
            <SideBar />
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

HeaderTop.Left = ({ children }) => (
  <div className="col-md-auto text-center text-md-start">{children}</div>
);

HeaderTop.Right = ({ children }) => (
  <div className="col-auto d-none d-md-block">{children}</div>
);

export default HeaderTop;
