import { useState, useEffect } from "react";
import StrAPI from "../services/StrAPI";

export default function useStrAPIFinancialHighlights() {
    const [LoadingFinancialHighlights, setLoadingFinancialHighlights] = useState(false);
    const [GetDatasFinancialHighlights, setGetDatasFinancialHighlights] = useState(null);
    const [getContact, setContactForm] = useState(null);
    const [Path, setPath] = useState(null);

    return {

        LoadingFinancialHighlights,
        GetDatasFinancialHighlights,
        Path,
        getContact,
        setPagesFinancialHighlights: (payload) => {
            setGetDatasFinancialHighlights(payload);
        },

        setLoadingFinancialHighlights: (payload) => {
            setLoadingFinancialHighlights(payload);
        },

        getStrAPIFinancialHighlights: async (Path, lang, populate = '&populate=*') => {

            console.log(Path,lang,populate);
            setLoadingFinancialHighlights(true);
            let responseData = await StrAPI.getStrAPI(Path, lang, populate);

            if (responseData) {
                setGetDatasFinancialHighlights(responseData);
                setTimeout(
                    function () {
                        setLoadingFinancialHighlights(false);
                    }.bind(this),
                    1000
                );
            }
        },

        postForm: async (Path, json) => {
            setLoadingFinancialHighlights(true);
            const responseData = await StrAPI.postAPI(Path, json);
            if (responseData) {
                setContactForm(responseData);
                setTimeout(
                    function () {
                        setLoadingFinancialHighlights(false);
                    }.bind(this),
                    250
                );
            }
        }
    }
}