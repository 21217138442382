import React, { useEffect, useState } from "react";
import { baseUrl } from "../../services/Environment";
import Moment from "react-moment";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import GLightbox from "../../assets/template/js/glightbox.min.js";
// import "../../assets/template/css/glightbox.min.css";

const InterViews = ({ data, id }) => {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var urlVideo, replaceVDO, splitVDO, videoID, publishedAt, title = '';
  if(data !== 'nodata'){
    urlVideo = data.url_video;
    replaceVDO = data.url_video.replace("watch?v=", "");
    splitVDO = replaceVDO.split(/[\s/]+/);
    videoID = splitVDO[2];
    publishedAt = data.publishedAt;
    title = data.title;
  }
  


  return (
    <>
      <Modal
        show={modalShow === id}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="example-modal-sizes-title-xl"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              src={urlVideo}
              width="100%"
              height="500"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </Modal.Body>
      </Modal>

      <div
        className="col-md-6 col-lg-3 mb-4"
        data-aos="fade-up"
        data-aos-delay="0"
      >
        <div className="card post-entry box show" style={{ minHeight: "28em" }}>
          {/* <a onClick={() => setModalShow(id)}> */}
          <a href={urlVideo} target="_blank">
            <img
              src={`https://i3.ytimg.com/vi/${videoID}/hqdefault.jpg`}
              className="card-img-top"
              alt={title}
            />
          </a>
          <div className="card-body pb-4">
            <div>
              <p className="text-uppercase font-weight-bold date">
                <Moment format="LL">{publishedAt}</Moment>
              </p>
            </div>
            <p className="card-title text-dark line_2">
              {title}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterViews;
