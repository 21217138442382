import React from "react";
import ReadMore from "../ReadMore/ReadMore";
// import { BlocksRenderer } from '@strapi/blocks-react-renderer';
// import Blocks from 'editorjs-blocks-react-renderer';
import { baseUrl } from "../../services/Environment";

function Card(item) {
  // console.log(item.data.logo.data.attributes.url)
  return (
    <div className="col-xxl-4 col-md-6 col-sm-12 lg_p0 ">
      <a
        className="cardItem d-flex bg-one flex-column  border rounded-4 shadow m-5 p-2 lgheight"
        style={{ height: "420px" }}
        href={"/companygroup/" + item.data.slug}
        target="_blank"
      >
        <img
          className="logoImg3 rounded-bottom rounded-4 "
          src={baseUrl + item.data.logo.data.attributes.url}
        />

        <div className="px-3 pt-3 lg_pt0">
          <h5 style={{ cursor: "pointer" }}>{item.data.name}</h5>
          <p className="mb-2 pt-4 lg_pt5 xl:text-lg text-base text-ellipsis overflow-hidden line-clamp-3">
            {item.data.detail[0].children[0].text}
            {/* <BlocksRenderer content={item.data.detail} /> */}
            {/* <Blocks data={item.data.detail} /> */}
            {/* {documentToReactComponents(item.data.detail)} */}
          </p>
        </div>
        {/* <ReadMore /> */}
      </a>
    </div>
  );
}

export default Card;
