import React, { Fragment, useEffect, useState } from "react";
import { MenuNavbar, Seo } from "../components";
import { useTranslation } from "react-i18next";
import useGetPage from "../hooks/useGetPage";
import Footer from "../components/Footer/Footer";
import HeaderBannerOne from "../components/HeaderCore/HeaderBannerOne";

function Bussiness() {
  const [lang, setLang] = useState();
  const { t, i18n } = useTranslation();
  const { pages, loading, getPage } = useGetPage();

  useEffect(() => {
    if (localStorage.getItem("ncl_lang")) {
      setLang(localStorage.getItem("ncl_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }

    if (lang !== undefined) {
      getPage("home", lang);
    }
  }, [lang]);

  const handleLang = (event, attrLang) => {
    let changeText = attrLang === "th" ? "en" : "th";
    localStorage.setItem("ncl_lang", changeText);
    setLang(localStorage.getItem("ncl_lang"));
    i18n.changeLanguage(lang);
  };

  return (
    <Fragment>
      <Seo
        title="Home"
        description="A description of the page"
        name="Company name"
        type="articlec"
        keywords="HTML, CSS, JavaScript"
      />

      <MenuNavbar>
        <div className="align-items-center icon-nav-custom">
          <img
            src="images/icon/icon-12.png"
            alt={"NCL Language " + lang}
            // height="30"
            className="narbar_link"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          />
          <span
            style={{ textTransform: "uppercase" }}
            className="pt-1 narbar_font"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          >
            {lang}
          </span>
        </div>
      </MenuNavbar>

      <HeaderBannerOne
        img={
          "https://cdn.pixabay.com/photo/2018/09/19/23/03/sunset-3689760_1280.jpg"
        }
        id={"BgBanner"}
        title={"Bussiness NCL"}
        content={`There are many variations os passages of Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua.`}
        colorH1={"text-white"}
        colorP={"text-white"}
      />

      {/* กลาง */}
      <section>
        <div className="section container d-flex flex-column justify-content-center align-items-center w-50 BS ">
          <h2 className="display-4  fw-bold py-4">Lorem ipsum dolor</h2>
          <p className="text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc
            lobortis mattis aliquam faucibus. Arcu vitae elementum curabitur
            vitae nunc sed velit dignissim. Facilisis magna etiam tempor orci eu
            lobortis. Dictum sit amet justo donec enim diam. Tincidunt ornare .
          </p>
          <div className="row text-center">
            <div className="col-xl-3 col-sm-6 p-4 d-flex flex-column justify-content-center align-items-center gap-2 ">
              <img className=" logoImg1"></img>
              <div>Lorem ipsum dolor sit amet</div>
            </div>
            <div className="col-xl-3 col-sm-6 p-4 d-flex flex-column justify-content-center align-items-center gap-2 ">
              <img className=" logoImg1"></img>
              <div>Lorem ipsum dolor sit amet</div>
            </div>
            <div className="col-xl-3 col-sm-6 p-4 d-flex flex-column justify-content-center align-items-center gap-2 ">
              <img className=" logoImg1"></img>
              <div>Lorem ipsum dolor sit amet</div>
            </div>
            <div className="col-xl-3 col-sm-6 p-4 d-flex flex-column justify-content-center align-items-center gap-2 ">
              <img className=" logoImg1"></img>
              <div>Lorem ipsum dolor sit amet</div>
            </div>
          </div>
        </div>
      </section>

      {/* ล่าง */}
      <section className="bg-Lgray">
        <div className="row section mx-0">
          <div className="col-lg-5 col-sm-12 container section ">
            <div className="text-lg-start text-center ">
              <h3>Lorem ipsum dolor sit amet</h3>
              <p className="py-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed
                faucibus turpis in eu mi. Ligula ullamcorper malesuada proin
                libero. Turpis egestas sed tempus urna{" "}
              </p>
            </div>
            <div className="row">
              <div className="col-xl-6 ">
                <div className="d-flex flex-column p-4 border border-dark">
                  <h4>One lorem</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="d-flex flex-column p-4">
                  <h4>Two lorem</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="d-flex flex-column p-4">
                  <h4>Three lorem</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="d-flex flex-column p-4">
                  <h4>Four lorem</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="BusinessBg" className="col-lg-4 col-sm-12 "></div>
        </div>
      </section>

      {/* รูปภาพ */}
      <section className="bg-gray section">
        <div className="container ">
          <div className="row" style={{ "min-height": "1000px" }}>
            <div className="col-lg-4">
              <div
                id="BusinessBg1"
                className="w-100 h-100 border border-white "
              ></div>
            </div>
            <div className="col-lg-8 d-flex flex-column lg_mt-5 gap-4 ">
              <div
                id="BusinessBg2"
                className="w-100 h-50 border border-white"
              />
              <div
                id="BusinessBg3"
                className="w-100 h-50 border  border-white"
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
}

export default Bussiness;
