import { hover } from "@testing-library/user-event/dist/hover";
import React from "react";

function NavBarItem({ children, pMin, pMax, id }) {
  function scrollToElement(id) {
    let offset = 100; // ระยะ offset ที่ต้องการ
    const element = document.getElementById(id);
    const offsetTop = element.offsetTop - offset; // คำนวณระยะ offset ที่ต้องการเลื่อน

    window.scrollTo({
      top: offsetTop,
      behavior: "smooth", // เลื่อนโดยการ animate ให้เรียบร้อย
    });
  }

  return (
    <>
      {/* // <div className=" text-black d-flex ">
    //   <button
    //     className="nav-bar border-0 text-nowrap  text-center bg-Lgray navHV rounded-pill "
    //     style={{ paddingInline: `clamp(${pMin},6vw,${pMax})` }}
    //     onClick={() => scrollToElement(id)}
    //   >
    //     {children}
    //   </button>
    // </div> */}
      <button
        className="border-0 text-black d-flex justify-content-center navHV bg-Lgray rounded-pill align-items-center"
        style={{
          height: "clamp(37px,10vw,60px)",
          width: "clamp(180px,23vw,350px)",
        }}
        onClick={() => scrollToElement(id)}
      >
        <h5 className="font text-center m-0 ">{children}</h5>
      </button>
    </>
  );
}

export default NavBarItem;
