import React, { useState } from "react";
import ViewPDF from "../../ViewPDF/ViewPDF";
import { Pagination, Stack, Typography } from "@mui/material";

const Lists = [
  <ViewPDF
    target="_blank"
    href="/images/รายงานการพัฒนาอย่างยั่งยืน_2561 _Sustainability Report_2018.pdf"
    title={
      "รายงานการพัฒนาอย่างยั่งยืน 2561 / sustainability Report 2018 (in Thai version only)"
    }
  />,
  <ViewPDF
    target="_blank"
    href="/images/รายงานการพัฒนาอย่างยั่งยืน 2562  Sustainability Report 2019 (in Thai version only).pdf"
    title={
      "รายงานการพัฒนาอย่างยั่งยืน 2562 / sustainability Report 2019 (in Thai version only)"
    }
  />,
  <ViewPDF
    target="_blank"
    href="/images/รายงานการพัฒนาอย่างยั่งยืน 2563  Sustainability Report 2020 (in Thai version only).pdf"
    title={
      "รายงานการพัฒนาอย่างยั่งยืน 2563 / sustainability Report 2020 (in Thai version only)"
    }
  />,
];

function Report({ lang }) {
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  const itemsPerPage = 3;
  const pageCount = Math.ceil(Lists.length / itemsPerPage);
  const paginatedLists = Array.from({ length: pageCount }, (_, index) => {
    const startIndex = index * itemsPerPage;
    return Lists.slice(startIndex, startIndex + itemsPerPage);
  });

  return (
    <>
      <section className="container section">
        <h1 className="title-line">
          {lang === "th"
            ? `รายงานการพัฒนาอย่างยังยื่น`
            : `Sustainability Report `}
        </h1>
        <h3 className="fw-bold mt-5">
          {lang === "th" ? `ดาวน์โหลดเอกสาร ` : `Downlaod Document `}
        </h3>
        <Stack spacing={2}>
          <Typography>
            <div className="row d-flex justify-content-start">
              {paginatedLists[currentPage - 1].map((list, index) => list)}
            </div>
          </Typography>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handleChange}
            className="d-flex justify-content-end p-4"
            hidePrevButton
            hideNextButton
            sx={{
              "& .Mui-selected": { bgcolor: "#cccccc !important" },
              "& button": { bgcolor: "#e6e6e6" },
              "& button:hover": { bgcolor: "lightgray" },
            }}
            shape="rounded"
            size="large"
          ></Pagination>
        </Stack>
      </section>
    </>
  );
}

export default Report;
