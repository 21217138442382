import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MenuNavbar, Seo } from "../components";
import { useTranslation } from "react-i18next";
// import useGetPage from "../hooks/useGetPage";
import Footer from "../components/Footer/Footer";
import useStrAPI from "../hooks/useStrAPI";
import { baseUrl } from "../services/Environment";
import DOMPurify from "dompurify";
import { Renderers } from "../components";
import HeaderBannerOne from "../components/HeaderCore/HeaderBannerOne";
import BannerDetail from "../components/BannerDetail/BannerDetail";

function ItemsNews(props) {
  const { slug } = useParams();
  const [lang, setLang] = useState();
  const { t, i18n } = useTranslation();
  // const { pages, loading, getPage } = useGetPage();
  const { GetDatas, loading, getStrAPI } = useStrAPI();

  console.log("GetDatas", GetDatas);

  // console.log(slug);
  // useEffect(() => {
  //   if (localStorage.getItem("ncl_lang")) {
  //     setLang(localStorage.getItem("ncl_lang"));
  //     i18n.changeLanguage(lang);
  //   } else {
  //     setLang("th");
  //   }

  //   if (lang !== undefined) {
  //     getPage("home", lang);
  //   }
  // }, [lang]);
  useEffect(() => {
    // console.log(localStorage.getItem("itemsHref"));
    if (localStorage.getItem("ncl_lang")) {
      setLang(localStorage.getItem("ncl_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }

    if (lang !== undefined) {
      getStrAPI(
        "plural-company-news",
        lang,
        `&populate=*&filters[slug][$eq]=${slug}`
      );
      // http://192.168.1.57:1337/api/affiliated-companies/?populate=*&locale=en&filters[slug][$eq]=golden-supply-company-limited-gds
    }
  }, [lang]);
  const handleLang = (event, attrLang) => {
    let changeText = attrLang === "th" ? "en" : "th";
    localStorage.setItem("ncl_lang", changeText);
    setLang(localStorage.getItem("ncl_lang"));
    i18n.changeLanguage(lang);
  };
  const blockHtml = (block) => {
    return block.children.map((block_1, index_1) => {
      // console.log('block',block_1.text);
      switch (block_1.type) {
        case "text":
          // const cleanHTML = DOMPurify.sanitize(block_1.text);
          // console.log('cleanHTML',cleanHTML);
          return (
            <div
              key={index_1}
              dangerouslySetInnerHTML={{ __html: block_1.text }}
            />
          );
        // case 'image':
        //   return <img key={index} src={block_1.url} alt={block_1.alt} />;
        // case 'video':
        //   return (
        //     <video key={index} controls>
        //       <source src={block_1.url} type="video/mp4" />
        //     </video>
        //   );
        default:
          return "null";
      }
    });
  };

  console.log(
    "first",
    `${baseUrl}${GetDatas?.data[0].attributes.cover_image.data.attributes.url}`
  );

  return (
    <Fragment>
      <Seo
        title="Home"
        description="A description of the page"
        name="Company name"
        type="articlec"
        keywords="HTML, CSS, JavaScript"
      />

      <MenuNavbar>
        <div className="align-items-center icon-nav-custom ">
          <img
            src="../images/icon/icon-12.png"
            alt={"NCL Language " + lang}
            height="30"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          />
          <span
            style={{ textTransform: "uppercase" }}
            className="pt-1"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          >
            {lang}
          </span>
        </div>
      </MenuNavbar>
      <div
        className="bg-blue"
        style={{ height: "clamp(70px,10vw,110px)" }}
      ></div>
      <BannerDetail
        src={`${baseUrl}${GetDatas?.data[0].attributes.cover_image.data.attributes.url}`}
      />

      <section>
        <div className="container  section newsC " style={{ width: "60%" }}>
          <div>
            {/* <div className="fw-bold fs-6">
              <div>PRESS RELEASE</div>
              <div>May 2, 2024</div>
            </div> */}
            <h1 className=" fw-bold mt-4">
              {GetDatas?.data[0].attributes.title}{" "}
            </h1>
            {/* <h2>consectetur adipiscing elit</h2>
            <h2>incididunt ut labore et</h2> */}
            <hr />
            <div className="fs-5  d-flex flex-column gap-4">
              {/* <span className="fw-bold text-black ">
                massa sapien faucibus et molestie ac
              </span> */}
              <Renderers
                markdownContent={GetDatas?.data[0].attributes.detail}
              ></Renderers>

              {/* {GetDatas?.data[0].attributes.detail.map((block) => {
                // console.log('block',block.children);
                  return blockHtml(block);
              })} */}
              {/* <h3>Share article</h3>
              <div className="d-flex gap-4 py-2">
                <a href="#">
                  <i
                    class="fa-brands fa-facebook fa-xl"
                    style={{ color: "#727274" }}
                  ></i>
                </a>
                <a href="#">
                  <i
                    class="fa-brands fa-x-twitter fa-xl"
                    style={{ color: "#727274" }}
                  ></i>
                </a>
                <a href="#">
                  <i
                    class="fa-solid fa-envelope fa-xl"
                    style={{ color: "#727274" }}
                  ></i>
                </a>
                <a href="#">
                  <i
                    class="fa-solid fa-link fa-xl"
                    style={{ color: "#727274" }}
                  ></i>
                </a>
              </div> */}
              {/* <div className="bg-Lgray d-flex n justify-content-between p-5">
                <div className=" d-flex gap-4 align-items-center">
                  <i
                    class="fa-regular fa-file-lines fa-xl"
                    style={{ color: "black" }}
                  ></i>
                  <h4 className="m-0 fw-bold">
                    Consoildated Financial Statements
                  </h4>
                </div>

                <a
                  href="#"
                  className="d-flex gap-2 align-items-center bg-white py-3 px-4 rounded-pill"
                >
                  <div className="text-black text-nowrap">View PDF</div>
                  <i
                    class="fa-solid fa-location-arrow"
                    style={{ color: "black" }}
                  ></i>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
}

export default ItemsNews;
