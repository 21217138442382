import React, { Fragment, useEffect, useState } from "react";
import { MenuNavbar, Seo } from "../components";
import { useTranslation } from "react-i18next";
import useGetPage from "../hooks/useGetPage";
import Footer from "../components/Footer/Footer";
import HeaderBannerOne from "../components/HeaderCore/HeaderBannerOne";

function Management() {
  const [lang, setLang] = useState();
  const { t, i18n } = useTranslation();
  const { pages, loading, getPage } = useGetPage();

  useEffect(() => {
    if (localStorage.getItem("ncl_lang")) {
      setLang(localStorage.getItem("ncl_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }

    if (lang !== undefined) {
      getPage("home", lang);
    }
  }, [lang]);

  const handleLang = (event, attrLang) => {
    let changeText = attrLang === "th" ? "en" : "th";
    localStorage.setItem("ncl_lang", changeText);
    setLang(localStorage.getItem("ncl_lang"));
    i18n.changeLanguage(lang);
  };

  // SMOOTH SCROLLING SECTIONS

  return (
    <Fragment>
      <Seo
        title="Home"
        description="A description of the page"
        name="Company name"
        type="articlec"
        keywords="HTML, CSS, JavaScript"
      />
      <MenuNavbar>
        <div className="align-items-center icon-nav-custom">
          <img
            src="images/icon/icon-12.png"
            alt={"NCL Language " + lang}
            // height="30"
            className="narbar_link img-icon-nav"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          />
          <span
            style={{ textTransform: "uppercase" }}
            className="pt-1 narbar_font"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          >
            {lang}
          </span>
        </div>
      </MenuNavbar>
      <HeaderBannerOne
        id={"BgBanner"}
        // img={"/images/governance/Header การกำกับดูแลกิจการที่ดี-25.jpg"}
        title1={"NCL"}
        title2={"Good Goverment"}
        content={``}
        colorH1={"text-white"}
      />

      {/*---------- Upper ----------*/}
      <section className="bg-Lgray">
        <div className=" container ">
          <div className="row">
            <div className="col-lg-8 d-flex align-items-center lg_mt60  ">
              <div className="lg_w100" style={{ width: "85%" }}>
                <img
                  className="w-100 "
                  src="https://media.istockphoto.com/id/1166811341/th/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%96%E0%B9%88%E0%B8%B2%E0%B8%A2/%E0%B8%97%E0%B8%B8%E0%B8%81%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%A3%E0%B8%B2%E0%B8%81%E0%B9%8D%E0%B8%B2%E0%B8%A5%E0%B8%B1%E0%B8%87%E0%B8%94%E0%B9%8D%E0%B8%B2%E0%B9%80%E0%B8%99%E0%B8%B4%E0%B8%99%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AD%E0%B8%A2%E0%B8%B9%E0%B9%88%E0%B8%88%E0%B8%B0%E0%B8%AA%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B8%82%E0%B8%B6%E0%B9%89%E0%B8%99%E0%B9%83%E0%B8%99%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%8A%E0%B9%89%E0%B8%B2.jpg?s=2048x2048&w=is&k=20&c=FH6Hpe1SygN8wvCkTSGVr_oS0JM0NA-njzJYMDIKsFU="
                />
              </div>
            </div>
            <div className="col-lg-4 section d-flex flex-column justify-content-center">
              <div className="d-flex flex-column align-items-start py-2">
                <img className="logoImg1" />
                <div className="py-4">
                  <h5>porttitor rhoncus dolor</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ultrices eros in cursus turpis massa tincidunt dui
                    ut.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-column align-items-start py-2">
                <img className="logoImg1" />
                <div className="py-4">
                  <h5>porttitor rhoncus dolor</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ultrices eros in cursus turpis massa tincidunt dui
                    ut.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*---------- Lower ----------*/}

      <section>
        <div className="section container">
          <div className="row bp_gover">
            <div className="col-xl-7 col-md-12 pb-4 d-flex flex-column justify-content-center">
              <h2 className="fw-bold w-75">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut.
              </h2>
              <ul className="text-black fs-5 d-flex flex-column gap-3 pt-3">
                <li>orci sagittis eu volutpat odio</li>
                <li>
                  sed id semper risus in hendrerit gravida rutrum quisque non
                </li>
                <li>orci sagittis eu volutpat odio</li>
                <li>orci sagittis eu volutpat odio</li>
              </ul>
            </div>
            <div className="col-xl-5 col-md-12 bg-Lgray p-5">
              <div className="d-flex flex-column ">
                <small className="m-0" style={{ textTransform: "uppercase" }}>
                  lobortis feugia0t
                </small>
                <div className="d-flex align-items-center xl_col  mobile_col">
                  <h2 className="fw-bold">Cursus risus at ultrices mi</h2>
                  <img
                    className="logoImg2 ms-5 my-2 mobile_col xl_m-0"
                    src="https://cdn.pixabay.com/photo/2024/05/17/01/59/lamb-8767143_1280.jpg"
                  />
                </div>
                <h6 className="fw-bold mt-2">
                  feugiat in fermentum posuere urna
                </h6>
                <p>
                  orem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Tristique senectus et netus et malesuada fames ac turpis. Nunc
                  id cursus metus aliquam eleifend mi in nulla. Bibendum ut
                  tristique et egestas quis ipsum suspendisse ultrices. Ac
                  turpis egestas sed tempus urna et pharetra pharetra. Suscipit
                  adipiscing bibendum est ultricies integer quis auctor elit
                  sed. In ornare quam viverra orci sagittis eu volutpat odio
                  facilisis. Eros donec ac odio tempor orci.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
}

export default Management;
