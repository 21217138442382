import React, { Fragment, useEffect, useRef, useState } from "react";
import { HeaderOverlay, MenuNavbar, Seo } from "../components";
import { useTranslation } from "react-i18next";
// import useGetPage from "../hooks/useGetPage";
import Footer from "../components/Footer/Footer";
import HeaderBannerOne from "../components/HeaderCore/HeaderBannerOne";
import useStrAPI from "../hooks/useStrAPI";

function NotFound() {
  const [lang, setLang] = useState();
  const { t, i18n } = useTranslation();
  const { GetDatas, loading, getStrAPI } = useStrAPI();

  useEffect(() => {
    // console.log(localStorage.getItem("itemsHref"));
    if (localStorage.getItem("ncl_lang")) {
      setLang(localStorage.getItem("ncl_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }

    if (lang !== undefined) {
      getStrAPI("file-managements", lang);
    }
  }, [lang]);

  let itemsHref = window.location.hash;
  if (
    itemsHref !== "" &&
    itemsHref !== "#" &&
    itemsHref !== "itemsHref" &&
    itemsHref !== undefined &&
    itemsHref !== null
  ) {
    const element = document.querySelector(itemsHref);
    if (element) {
      const offset = 80;
      const offsetTop = element.offsetTop - offset;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth", // เลื่อนโดยการ animate ให้เรียบร้อย
      });
    }

    // console.log("itemsHref", itemsHref);
  }

  const handleLang = (event, attrLang) => {
    window.location.hash = "";
    let changeText = attrLang === "th" ? "en" : "th";
    localStorage.setItem("ncl_lang", changeText);
    setLang(localStorage.getItem("ncl_lang"));
    i18n.changeLanguage(lang);
  };

  // console.log(baseUrl+GetDatas?.data[0].attributes.file.data.attributes.url)
  return (
    <Fragment>
      <Seo
        title="Home"
        description="A description of the page"
        name="Company name"
        type="articlec"
        keywords="HTML, CSS, JavaScript"
      />

      <MenuNavbar>
        <div className="align-items-center icon-nav-custom">
          <img
            src="images/icon/icon-12.png"
            alt={"NCL Language " + lang}
            // height="30"
            className="narbar_link img-icon-nav"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          />
          <span
            style={{ textTransform: "uppercase" }}
            className="pt-1 narbar_font"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          >
            {lang}
          </span>
        </div>
      </MenuNavbar>

      {/* Header */}

      <div
        className="bg-blue"
        style={{ height: "clamp(70px,10vw,110px)" }}
      ></div>
      <section
        style={{
          height: "1000px",
          background:
            "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(217,225,245,1) 100%)",
        }}
      >
        <div className="d-flex container justify-content-center align-items-center h-100 ">
          <div className="row ">
            <div className="col-xxl-6">
              <div className="d-flex flex-column justify-content-center align-items-xxl-start align-items-center xxl-center h-100 p-5 gap-3">
                <h1
                  style={{
                    fontSize: "clamp(130px,20vw,200px)",
                    color: "#000B61",
                  }}
                >
                  404
                </h1>
                <h1>Page not found</h1>
                <p className="text-secondary">
                  Sorry, the page you’re looking for does not exist or has been
                  moved please go back to the Home page
                </p>
                <a href="/">
                  <button
                    className=" btn text-white rounded-pill px-5"
                    style={{ backgroundColor: "#000B61" }}
                  >
                    {" "}
                    Go back Home
                  </button>
                </a>
              </div>
            </div>
            <div
              className="col-xxl-6 xxl-invisible"
              style={{ display: "block" }}
            >
              <div className="d-flex  justify-content-center p-5">
                <img style={{ width: "85%" }} src="/images/404.png" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
}

export default NotFound;
