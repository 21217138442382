import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: " clamp(300px,40vw,800px)",
  borderRadius: "clamp(5px,2vw,10px)",
  border: "1px solid #000",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ContainerLogo({ img, content, titleModal, textModal, src }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button
        className="d-flex flex-column align-items-center col-lg-4 col-sm-6 col-12  py-3 gap-3 "
        onClick={handleOpen}
      >
        <img className="logoImg2 btn p-0" src={img} />
        <p className="text-center">{content}</p>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="mobile_w95  ">
          <div className="d-flex justify-content-between">
            <p
              className="m-0 "
              style={{
                cursor: "pointer",
                visibility: "hidden",
                fontSize: "clamp(10px,2vw,15px)",
              }}
              onClick={handleClose}
            >
              ✖
            </p>
            <h3 className="m-0" style={{ fontSize: "clamp(18px,2vw,25px)" }}>
              {titleModal}
            </h3>
            <p
              className="m-0 "
              style={{ cursor: "pointer", fontSize: "clamp(10px,2vw,15px)" }}
              onClick={handleClose}
            >
              ✖
            </p>
          </div>
          {src ? "" : <hr style={{ border: "1px solid grey" }} />}
          <Typography
            id="modal-modal-description"
            className="d-flex flex-column align-items-center"
          >
            <p className="m-0" style={{ textIndent: "25px" }}>
              {textModal}
            </p>
            {src ? (
              <a href={src} target="_blank">
                <img
                  src={src}
                  className="mobile_w250"
                  style={{ height: "clamp(450px,40vw,700px)" }}
                />
              </a>
            ) : (
              ""
            )}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default ContainerLogo;
