import React from "react";

function Policy({ lang }) {
  return (
    <>
      {lang === "th" ? ` ` : ` `}
      <section className="container section">
        <h1 className="title-line">
          {lang === "th" ? `นโยบายการจัดการความยั่งยืน ` : `Sustainable Policy`}
        </h1>
        <p>
          {lang === "th"
            ? `บริษัทฯ มีความมุ่งมั่นในการพัฒนาบริษัท ให้เป็นผู้นำในการให้บริการด้านโลจิสติกส์แบบครบวงจรตามมาตรฐานสากล โดยจะเป็นทางเลือกแรกและทางเลือกที่ดีที่สุดให้แก่ลูกค้าและคู่ค้าของบริษัท เน้นการทำงานอย่างมีคุณภาพและประสิทธิภาพ ซึ่งเป็นรากฐานสำคัญที่นำไปสู่การเติบโตอย่างยั่งยืนของบริษัทฯ และบริษัทในเครือ ด้วยการมุ่งเน้นดำเนินงานทั้งในด้านเศรษฐกิจ ด้านสังคม และ สิ่งแวดล้อม ควบคู่ไปด้วยกัน โดยอยู่ภายใต้การปฏิบัติตามมาตรฐานสากลและหลักบรรษัทภิบาลที่ดี ซึ่งได้เป็นนโยบายไว้ดังนี้
`
            : `The Company is committed to developing the company to be a leader in providing total logistics services. It will be the first and best choice for our customers and partners. The company focuses on quality and efficient work, which is the cornerstone of the company's sustainable growth, and affiliates with a focus on the economy, society, and environment which is under the international code of practice which has set the following guidelines: `}
        </p>

        <ol>
          <h5 className="fw-bold" style={{ textIndent: "-33px" }}>
            {lang === "th"
              ? `มิติเศรษฐกิจและบรรษัทภิบาล`
              : `Economic and Corporate Governance Dimension `}
          </h5>
          <li>
            {lang === "th"
              ? `การประกอบธุรกิจให้บริการด้านโลจิสติกส์แบบครบวงจรด้วยบุคลากรที่มีความรู้ความชำนาญและให้บริการแก่ลูกค้าด้วยความจริงใจ ภายใต้หลักการกำกับดูแลกิจการที่ดี พร้อมคำนึงถึงผลกระทบในด้านเศรษฐกิจ สังคม และสิ่งแวดล้อม `
              : ` Conducting a full-service logistics business with knowledgeable and skilled personnel who provide genuine customer service, under good corporate governance principles, while considering the economic, social, and environmental impacts.
`}
          </li>
          <li>
            {lang === "th"
              ? `ดำเนินธุรกิจอย่างซื่อสัตย์ เป็นธรรม โดยยึดถือการปฏิบัติตามกฎหมายและข้อกำหนดทางการค้า เสริมสร้างการบริหารงานภายในองค์กรให้เกิดความชัดเจนโปร่งใส สามารถตรวจสอบได้ รวมถึงสนับสนุนการต่อต้านการทุจริต การป้องกันการแสวงหาผลประโยชน์ และการใช้อำนาจในทางมิชอบ เพื่อสร้างประโยชน์สูงสุดที่เป็นธรรมต่อผู้มีส่วนได้เสีย
 `
              : `Conducting business with honesty and integrity, adhering to laws and commercial regulations, promoting clear and transparent internal management that can be audited, supporting anti-corruption efforts, and preventing seeking personal benefits and abusing power, in order to create the maximum benefit that is fair to all stakeholders.
 `}
          </li>
          <li>
            {lang === "th"
              ? ` ส่งเสริมการปฏิบัติตามหลักสิทธิมนุษยชน โดยคำนึงถึงศักดิ์ศรีความเป็นมนุษย์ สิทธิ เสรีภาพ และความเสมอภาค ของบุคคลที่ได้รับการคุ้มครองตามรัฐธรรมนูญแห่งราชอาณาจักรไทย และกฎหมายระหว่างประเทศที่เกี่ยวข้องรวมถึงการให้ความเคารพต่อขนบธรรมเนียมประเพณีท้องถิ่น ที่บริษัท หรือบริษัทในเครือตั้งอยู่
 `
              : `Promote the practice of human rights principles, with consideration for the dignity of human beings, their rights, freedoms, and equality, of individuals protected by the Constitution of the Kingdom of Thailand and relevant international laws, including respecting local customs and traditions, at the Company or subsidiary level.
 `}
          </li>
          <li>
            {lang === "th"
              ? `มุ่งเน้นการปรับปรุง พัฒนา กระบวนการทำงานและการให้บริการ ตลอดห่วงโซ่คุณค่าตามหลักการบริหารคุณภาพทั่วทั้งองค์กร เพื่อสร้างทัศนคติด้านคุณภาพแก่บุคลากรในองค์กร ส่งผลต่อการสร้างคุณค่าเพื่อให้แก่ลูกค้าและสร้างการเจริญเติบโตในระยะยาว
 `
              : `Emphasize improving and developing work processes and services throughout the value chain based on the principles of quality management throughout the organization to create a quality mindset among personnel in the organization, resulting in creating value for customers and generating long-term growth.
 `}
          </li>
          <li>
            {lang === "th"
              ? ` มุ่งมั่นในการบริหารจัดการความเสี่ยงอย่างครบถ้วน และรอบด้าน เพื่อป้องกันและลดความสูญเสียหรือผลกระทบที่อาจเกิดขึ้นกับกลุ่มบริษัท ชุมชน สังคม และสิ่งแวดล้อม
`
              : `Committed to comprehensive and all-around risk management, in order to prevent and minimize losses or impacts that may occur to the group of companies, communities, society, and the environment.
 `}
          </li>
          <li>
            {lang === "th"
              ? `ส่งเสริมการสร้างสรรค์นวัตกรรมด้วยความรับผิดชอบต่อสังคมและสิ่งแวดล้อม เพื่อเพิ่มประสิทธิภาพ และ ประสิทธิผลรวมถึงการสร้างคุณค่าให้แก่องค์กร และ ผู้มีส่วนได้เสีย
 `
              : `Promote innovation with social and environmental responsibility, in order to increase efficiency and effectiveness, as well as creating value for the organization and stakeholders.
 `}
          </li>
          <div>
            <img
              className="w-100 py-4"
              src="/images/Sustainable/ความรับผิดชอบต่อสังคม-(นโยบาย)-36_0.jpg"
            />
          </div>
          <h5 className="fw-bold pt-3" style={{ textIndent: "-33px" }}>
            {lang === "th" ? `มิติสังคม ` : `Sustainable Policy`}
          </h5>
          <li>
            {lang === "th"
              ? `บริหารจัดการบุคลากรด้วยความเป็นธรรม เท่าเทียม ยุติธรรมตามหลักสิทธิมนุษยชนและปฏิบัติตามกฎหมายแรงงาน ให้ความสำคัญกับการพัฒนาความสามารถด้านแรงงาน และส่งเสริมให้เกิดความสุขในการทำงาน อันจะนำไปสู่การขับเคลื่อนองค์กรได้อย่างมีประสิทธิภาพ
 `
              : `Manage human resources with fairness, equality, and justice according to human rights principles and labor laws. Place importance on developing workforce capabilities and promoting happiness at work, which will lead to efficient organizational performance.
 `}
          </li>
          <li>
            {lang === "th"
              ? `ส่งเสริมให้ผู้บริหารและพนักงานมีความเข้าใจและปฏิบัติตามจริยธรรมทางธุรกิจอย่างเคร่งครัด เพื่อให้บรรลุเป้าหมายทางธุรกิจและเพื่อประโยชน์ของผู้มีส่วนได้เสียทุกฝ่าย
 `
              : ` Promote understanding and adherence to business ethics among executives and employees, in order to achieve business objectives and benefit all stakeholders involved.
`}
          </li>
          <li>
            {lang === "th"
              ? ` ดำเนินกิจการด้วยความรับผิดชอบต่อสังคม และผู้มีส่วนได้เสีย อันจะนำไปสู่การเติบโตร่วมกันของบริษัทฯ และสังคม เพื่อก่อให้เกิดคุณภาพชีวิตที่ดีขึ้น
 `
              : ` Conduct business with responsibility towards society and stakeholders, which will lead to the growth and development of both the Company and the community, and contribute to an improved quality of life.
`}
          </li>
          <div>
            <img
              className="w-100 py-4"
              src="/images/sustainable/ความรับผิดชอบต่อสังคม-(นโยบาย)-37_0.jpg"
            />
          </div>
          <h5 className="fw-bold pt-3" style={{ textIndent: "-33px" }}>
            {lang === "th" ? `มิติสิ่งแวดล้อม ` : `Societal Dimension`}
          </h5>
          <li>
            {lang === "th"
              ? ` ส่งเสริมและสนับสนุนให้มีการใช้ทรัพยากรอย่างมีประสิทธิภาพ รวมถึงการลดและควบคุมผลกระทบต่อสังคมและสิ่งแวดล้อม ซึ่งเป็นผลมาจากการดำเนินงานของบริษัทฯ และ บริษัทในเครือ รวมถึงคู่ค้าที่เกี่ยวข้อง`
              : `Promote and support efficient use of resources, including reducing and controlling the social and environmental impacts resulting from the operations of the Company, its subsidiaries, and relevant trading partners.`}
          </li>
          <li>
            {lang === "th"
              ? ` ตระหนักและให้ความสำคัญต่อความปลอดภัย อาชีวอนามัย และ สภาพแวดล้อมในการทำงานของพนักงาน คู่ค้า ลูกค้า ผู้มาติดต่อ เพื่อป้องกันการสูญเสียชีวิต และทรัพย์สิน ตลอดจนการบาดเจ็บ หรือเจ็บป่วย อันเนื่องมาจากการทำงาน`
              : `Raise awareness of and prioritize occupational safety, health, and environmental conditions for employees, trading partners, customers, and visitors to prevent loss of life and assets, as well as injuries or illnesses resulting from work.`}
          </li>
          <li>
            {lang === "th"
              ? ` สื่อสารและทำความเข้าใจกับกรรมการ ผู้บริหาร พนักงาน คู่ค้า และผู้มีส่วนได้เสียที่เกี่ยวข้อง ให้มีความตระหนักด้านสิ่งแวดล้อม
 `
              : `Communicate and ensure understanding with the Board of Directors, executives, employees, partners, and stakeholders to raise awareness of environmental concerns.
`}
          </li>
        </ol>
      </section>
    </>
  );
}

export default Policy;
