import React from "react";

function MidLine({ title, color }) {
  return (
    <div className="d-flex align-items-center pb-3">
      <h5 className="fw-bold text-nowrap my-0 mx-3">{title} </h5>
      <div
        className="block w-100"
        style={{ border: `1px ${color} solid`, height: "2px" }}
      ></div>
    </div>
  );
}

export default MidLine;
