import React, { Fragment, useEffect, useState } from "react";
import { MenuNavbar, Seo } from "../components";
import { useTranslation } from "react-i18next";
import useGetPage from "../hooks/useGetPage";
import Footer from "../components/Footer/Footer";
import HeaderBannerOne from "../components/HeaderCore/HeaderBannerOne";

function ItemsNews() {
  const [lang, setLang] = useState();
  const { t, i18n } = useTranslation();
  const { pages, loading, getPage } = useGetPage();

  useEffect(() => {
    if (localStorage.getItem("ncl_lang")) {
      setLang(localStorage.getItem("ncl_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }

    if (lang !== undefined) {
      getPage("home", lang);
    }
  }, [lang]);

  const handleLang = (event, attrLang) => {
    let changeText = attrLang === "th" ? "en" : "th";
    localStorage.setItem("ncl_lang", changeText);
    setLang(localStorage.getItem("ncl_lang"));
    i18n.changeLanguage(lang);
  };

  return (
    <Fragment>
      <Seo
        title="Home"
        description="A description of the page"
        name="Company name"
        type="articlec"
        keywords="HTML, CSS, JavaScript"
      />
      <MenuNavbar>
        <div className="align-items-center icon-nav-custom ">
          <img
            src="images/icon/icon-12.png"
            alt={"NCL Language " + lang}
            height="30"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          />
          <span
            style={{ textTransform: "uppercase" }}
            className="pt-1"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          >
            {lang}
          </span>
        </div>
      </MenuNavbar>

      <HeaderBannerOne
        img={
          "https://media.istockphoto.com/id/1065782390/th/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B8%96%E0%B9%88%E0%B8%B2%E0%B8%A2/%E0%B9%81%E0%B8%AD%E0%B8%9B%E0%B8%9E%E0%B8%A5%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B8%82%E0%B9%88%E0%B8%B2%E0%B8%A7%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%96%E0%B8%B7%E0%B8%AD%E0%B9%83%E0%B8%99%E0%B8%AA%E0%B8%A1%E0%B8%B2%E0%B8%A3%E0%B9%8C%E0%B8%97%E0%B9%82%E0%B8%9F%E0%B8%99-%E0%B8%8A%E0%B8%B2%E0%B8%A2%E0%B8%84%E0%B8%99%E0%B8%AB%E0%B8%99%E0%B8%B6%E0%B9%88%E0%B8%87%E0%B8%AD%E0%B9%88%E0%B8%B2%E0%B8%99%E0%B8%82%E0%B9%88%E0%B8%B2%E0%B8%A7%E0%B8%AD%E0%B8%AD%E0%B8%99%E0%B9%84%E0%B8%A5%E0%B8%99%E0%B9%8C%E0%B8%9A%E0%B8%99%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9A%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%94%E0%B9%89%E0%B8%A7%E0%B8%A2%E0%B9%82%E0%B8%97%E0%B8%A3%E0%B8%A8%E0%B8%B1%E0%B8%9E%E0%B8%97%E0%B9%8C%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%96%E0%B8%B7.jpg?s=2048x2048&w=is&k=20&c=1y8J2adMP_bc3sGhfeDzCLrDHvyhxc8S24K10zmfinI="
        }
        id={"NewsBgBanner"}
        // title={"NCL X LM"}
        // content={`consectetur adipiscing elit, sed do eiusmod tempor incididunt
        // ut labore et dolore magna aliqua. Euismod quis viverra nibh
        // cras pulvinar. In eu mi bibendum neque egestas congue quisque
        // egestas diam.`}
        colorH1={"text-white"}
        colorP={"text-white"}
      />

      <section>
        <div className="container section mt-5 newsC " style={{ width: "60%" }}>
          <div>
            <div className="fw-bold fs-6">
              <div>PRESS RELEASE</div>
              <div>May 2, 2024</div>
            </div>
            <h1 className=" fw-bold mt-4">
              sed do eiusmod tempor incididunt labore{" "}
            </h1>
            <h2>consectetur adipiscing elit</h2>
            <h2>incididunt ut labore et</h2>
            <hr />
            <div className="fs-5 section d-flex flex-column gap-4">
              <span className="fw-bold text-black ">
                massa sapien faucibus et molestie ac
              </span>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet
                consectetur adipiscing elit duis tristique sollicitudin nibh sit
                amet. Arcu non sodales neque sodales ut etiam. Consectetur lorem
                donec massa sapien faucibus et molestie ac.{" "}
              </p>{" "}
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet
                consectetur adipiscing elit duis tristique sollicitudin nibh sit
                amet. Arcu non sodales neque sodales ut etiam. Consectetur lorem
                amet. Arcu non sodales neque sodales ut etiam. Consectetur lorem
                amet. Arcu non sodales neque sodales ut etiam. Consectetur lorem
                donec massa sapien faucibus et molestie ac. amet. Arcu non
                sodales neque sodales ut etiam. Consectetur lorem donec massa
                sapien faucibus et molestie ac.
              </p>{" "}
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet
                consectetur adipiscing elit duis tristique sollicitudin nibh sit
                amet. Arcu non sodales neque sodales ut etiam. Consectetur lorem
                donec massa sapien faucibus et molestie ac.{" "}
              </p>{" "}
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet
                consectetur adipiscing elit duis tristique sollicitudin nibh sit
                amet. Arcu non sodales neque sodales ut etiam. Consectetur lorem
                donec massa sapien faucibus et molestie ac. Lorem ipsum dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Amet consectetur
                adipiscing elit duis tristique sollicitudin nibh sit amet. Arcu
                non sodales neque sodales ut etiam. Consectetur lorem donec
                massa sapien faucibus et molestie ac.
              </p>{" "}
              <h3>Share article</h3>
              <div className="d-flex gap-4 py-2">
                <a href="#">
                  <i
                    class="fa-brands fa-facebook fa-xl"
                    style={{ color: "#727274" }}
                  ></i>
                </a>
                <a href="#">
                  <i
                    class="fa-brands fa-x-twitter fa-xl"
                    style={{ color: "#727274" }}
                  ></i>
                </a>
                <a href="#">
                  <i
                    class="fa-solid fa-envelope fa-xl"
                    style={{ color: "#727274" }}
                  ></i>
                </a>
                <a href="#">
                  <i
                    class="fa-solid fa-link fa-xl"
                    style={{ color: "#727274" }}
                  ></i>
                </a>
              </div>
              <div className="bg-Lgray d-flex n justify-content-between p-5">
                <div className=" d-flex gap-4 align-items-center">
                  <i
                    class="fa-regular fa-file-lines fa-xl"
                    style={{ color: "black" }}
                  ></i>
                  <h4 className="m-0 fw-bold">
                    Consoildated Financial Statements
                  </h4>
                </div>

                <a
                  href="#"
                  className="d-flex gap-2 align-items-center bg-white py-3 px-4 rounded-pill"
                >
                  <div className="text-black text-nowrap">View PDF</div>
                  <i
                    class="fa-solid fa-location-arrow"
                    style={{ color: "black" }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
}

export default ItemsNews;
