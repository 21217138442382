import React, { useEffect, useState } from "react";
import NavItemAndDropdown from "./NavItemAndDropdown";
import NavItem from "./NavItem";

function SideBar() {
  const lang = localStorage.getItem("ncl_lang");

  return (
    <div className="d-flex flex-column align-items-center align-items-sm-start  pt-2 text-white">
      <ul
        className="w-100 nav nav-pills d-flex flex-column
           mb-sm-auto mb-0 align-items-center align-items-sm-start mt-2"
        id="menu"
      >
        <NavItem title={lang === "en" ? "Home" : "หน้าแรก"} href="/" />
        <NavItemAndDropdown
          title={lang === "en" ? "About NCL" : "เกี่ยวกับ NCL"}
          // titleHref={"/aboutus"}
          href={"/aboutus"}
          // id="submenu1"
          isDropdown={true}
          itemsHref={[
            "/aboutus#business",
            "/aboutus#history",
            "/aboutus#message",
            "/aboutus#partner",
          ]}
          items={
            lang === "en"
              ? [
                  "Business NCL",
                  "Company History",
                  "Message from The Board Of Directors",
                  "Company and NCL Group",
                ]
              : [
                  `ธุรกิจ NCL`,
                  "ความเป็นมาของบริษัท",
                  `สารจากกรรมการบริษัท`,
                  `บริษัทในเครือ`,
                ]
          }
        />
        <NavItemAndDropdown
          title={lang === "en" ? "Good Governance" : "การกำกับดูแลกิจการที่ดี"}
          // titleHref={"/governance"}
          href={"/governance"}
          id="submenu2"
          isDropdown={true}
          itemsOnClick={["criteria", "article", "charter"]}
          // itemsOnClick={[() => Home(), () => News()]}
          itemsHref={[
            "/governance#criteria",
            "/governance#article",
            "/governance#charter",
          ]}
          items={
            lang === "en"
              ? [
                  `Criteria for Independent Directors`,
                  `Article of Association`,
                  `Board Charter`,
                ]
              : [`นิยามกรรมการอิสระ`, `ข้อบังคับบริษัท`, `กฎบัตรกรรมการ`]
          }
        />
        <NavItemAndDropdown
          title={lang === "en" ? "Investor Relations" : "นักลงทุนสัมพันธ์"}
          // titleHref={"/shareholding/company"}
          href={"/shareholding/company"}
          // href="#submenu3"
          id="submenu3"
          isDropdown={true}
          itemsHref={[
            "/shareholding/company",
            "/shareholding/financial",
            "/shareholding/Information",
            "/shareholding/publication",
          ]}
          items={
            lang === "en"
              ? [
                  `Corporate Information`,
                  `Financial Highlights`,
                  `Shareholder Information`,
                  `Publications`,
                ]
              : [
                  `ข้อมูลบริษัท`,
                  `ข้อมูลทางการเงิน`,
                  `ข้อมูลสำหรับผู้ถือหุ้น`,
                  `เอกสารเผยแพร่`,
                ]
          }
        />
        <NavItemAndDropdown
          title={lang === "en" ? "News & Events" : "ข่าวสารและกิจกรรม"}
          // titleHref={"/news"}
          href={"/news"}
          id="submenu4"
          // ids={["", "down"]}
          isDropdown={true}
          itemsHref={[
            "/news#market",
            "/news#news",
            "/news#media",
            "/news#management",
          ]}
          items={
            lang === "en"
              ? [
                  `Stock Market News`,
                  `NCL News`,
                  `News Clippings`,
                  `Management Interview`,
                ]
              : [
                  `ข่าวแจ้งตลาดหลักทรัพย์`,
                  `ข่าวแจ้งสื่อมวลชน `,
                  `ข่าวจากสื่อสิ่งพิมพ์`,
                  `สัมภาษณ์ผู้บริหาร`,
                ]
          }
        />
        <NavItemAndDropdown
          title={lang === "en" ? "Corporate Social" : "ความรับผิดชอบต่อสังคม"}
          // titleHref={"/sustainable/development"}
          href={"/sustainable/development"}
          id="submenu5"
          // ids={["", "down"]}
          isDropdown={true}
          itemsHref={[
            "/sustainable/development",
            "/sustainable/policy",
            "/sustainable/operation",
            "/sustainable/social",
            "/sustainable/report",
          ]}
          items={
            lang === "en"
              ? [
                  "Sustainable Development for business",
                  "Sustainable Policy",
                  "Operation and Report Preparation",
                  "Social and Environmental Activities (After Process)",
                  "Sustainability Report",
                ]
              : [
                  `การขับเคลื่อนธุรกิจเพื่อความยั่งยืน`,
                  `นโยบายการจัดการความยั่งยืน`,
                  `การดำเนินงานและการจัดทำรายงาน`,
                  `กิจกรรมเพื่อประโยชน์ต่อสังคมและสิ่งแวดล้อม(After Process)`,
                  `รายงานการพัฒนาอย่างยั่งยืน`,
                ]
          }
        />
      </ul>
    </div>
  );
}

export default SideBar;
