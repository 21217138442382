import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";
import VisibilitySensor from "react-visibility-sensor";

function ShareholdersCard({ src, lists, num, classes, decimals }) {
  // const [hasAnimated, setHasAnimated] = useState(false);
  // const rowRef = useRef(null);

  // const genNumber = () => {
  //   document
  //     .querySelector(".animate .three")
  //     ?.style.setProperty("--percent", 74.06);
  //   document
  //     .querySelector(".animate .four")
  //     ?.style.setProperty("--percent", 100.0);
  // };

  // setTimeout(genNumber);
  // useEffect(() => {
  //   const rowObserver = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting && !hasAnimated) {
  //           // เมื่อองค์ประกอบอยู่ในมุมมอง
  //           rowRef.current.classList.add("animate"); // เพิ่มคลาส animate เพื่อเริ่ม animation
  //           setHasAnimated(true);
  //         }
  //       });
  //     },
  //     { threshold: 0.5 } // เป็นค่าเปอร์เซ็นต์ของการมองเห็นที่ต้องการสำหรับการเริ่มต้น animation
  //   );

  //   if (rowRef.current) {
  //     rowObserver.observe(rowRef.current);
  //   }

  //   return () => {
  //     if (rowRef.current) {
  //       rowObserver.unobserve(rowRef.current);
  //     }
  //   };
  // }, [hasAnimated]);

  // useEffect(() => {
  //   if (hasAnimated) {
  //     var numOneValue = 212;
  //     var elements = document.querySelectorAll(".animate .one");

  //     elements.forEach(function (element) {
  //       element.style.counterReset = "num-one " + numOneValue;
  //     });
  //   }
  // }, [hasAnimated]);

  return (
    <div
      className={`d-col d-sm-flex gap-4 align-items-center justify-content-center pt-5`}
      // ref={rowRef}
    >
      <div className="d-flex justify-content-center">
        <img src={src} className="logoImg5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        {lists?.map((list, index) => (
          <h5 key={index}>{list}</h5>
        ))}

        <VisibilitySensor>
          {({ isVisible }) => (
            <h1 className={`display-2 fw-bold mt-4`}>
              {isVisible ? (
                <CountUp end={num} duration={3} decimals={decimals} />
              ) : (
                "invisible"
              )}
            </h1>
          )}
        </VisibilitySensor>
      </div>
    </div>
  );
}

export default ShareholdersCard;
