import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const Renderers = ({ markdownContent }) => {
  const components = {
    code: ({ children }) => <>{children}</>,
    // pre: ({ children }) => <>{children}</>,
    // p: ({ children }) => <pre>{children}</pre>,
  };

  return (
    <>
      <div>
        <ReactMarkdown components={components} rehypePlugins={[rehypeRaw]}>{markdownContent}</ReactMarkdown>
      </div>
    </>
  );
};

export default Renderers;
