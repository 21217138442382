import { useState, useEffect } from "react";
import StrAPI from "../services/StrAPI";

export default function useStrAPICompanyNews() {
    const [loading_CompanyNews, setLoadingCompanyNews] = useState(false);
    const [GetDatas_CompanyNews, setGetDatasCompanyNews] = useState(null);
    const [getContact, setContactForm] = useState(null);
    const [Path, setPath] = useState(null);

    return {

        loading_CompanyNews,
        GetDatas_CompanyNews,
        Path,
        setPages: (payload) => {
            setGetDatasCompanyNews(payload);
        },

        setLoadingCompanyNews: (payload) => {
            setLoadingCompanyNews(payload);
        },

        getStrAPI_CompanyNews: async (Path, lang, populate = '&populate=*') => {
            // console.log(lang,populate);
            setLoadingCompanyNews(true);
            let responseData = await StrAPI.getStrAPI(Path, lang, populate);

            if (responseData) {
                setGetDatasCompanyNews(responseData);
                setTimeout(
                    function () {
                        setLoadingCompanyNews(false);
                    }.bind(this),
                    3000
                );
            }
        },

        // postForm: async (Path, json) => {
        //     setLoadingCompanyNews(true);
        //     const responseData = await StrAPI.postAPI(Path, json);
        //     if (responseData) {
        //         setContactFormCompanyNews(responseData);
        //         setTimeout(
        //             function () {
        //                 setLoadingCompanyNews(false);
        //             }.bind(this),
        //             250
        //         );
        //     }
        // }
    }
}