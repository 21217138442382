import Environment, { baseUrl,baseUrlemail } from "./Environment";
// import Environment_Email, { baseUrlemail } from "./Environment_Email";
import _Email from "./Environment_Email";

class StrAPI {
    async getStrAPI(path, locale='th' ,populate = '&populate=*') {
        console.log(`${baseUrl}/api/${path}?locale=${locale}${populate}`)
        const response = await Environment.get(`${baseUrl}/api/${path}?locale=${locale}${populate}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async postAPI(path, json) {
        const response = await Environment.post(`${baseUrl}/api/${path}`, json)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
// baseDomain = "https://admin.nclthailand.com";
    async emailAPI(json) {
        const response = await _Email.post(`https://cors-anywhere.herokuapp.com/http://api.nclthailand.com/sendmail.php`, json)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    // async getById(payload) {
    //     const response = await Environment.get(`${baseUrl}/api/home/${payload}?populate=*`)
    //         .then((response) => {
    //             return response.data;
    //         }).catch((error) => ({ error: JSON.stringify(error) }));
    //     return response;
    // }
    // async post(path, locale) {
    //     const response = await Environment.post(`${baseUrl}/api/${path}?locale=${locale}&populate=*`)
    //         .then((response) => {
    //             return response.data;
    //         })
    //         .catch((error) => ({ error: JSON.stringify(error) }));
    //     return response;
    // }

    // async postById(payload) {
    //     const response = await Environment.post(`${baseUrl}/api/home/${payload}?populate=*`)
    //         .then((response) => {
    //             return response.data;
    //         }).catch((error) => ({ error: JSON.stringify(error) }));
    //     return response;
    // }
}

export default new StrAPI();