import { useState, useEffect } from "react";
import PageService from "../services/PageService";

export default function useGetPage() {
    const [loading, setLoading] = useState(false);
    const [pages, setPages] = useState(null);
    const [page, setPage] = useState(null);

    return {

        loading,
        pages,
        page,
        setPages: (payload) => {
            setPages(payload);
        },

        setLoading: (payload) => {
            setLoading(payload);
        },

        getPage: async (page, lang) => {
            setLoading(true);
            let responseData = await PageService.getPage(page, lang);

            if (responseData) {
                setPages(responseData);
                setTimeout(
                    function () {
                        setLoading(false);
                    }.bind(this),
                    250
                );
            }
        },

        // getExecutiveById: async (payload) => {
        //     setLoading(true);
        //     const responseData = await PageService.getExecutiveById(payload);
        //     if (responseData) {
        //         setExecutive(responseData);
        //         setTimeout(
        //             function () {
        //                 setLoading(false);
        //             }.bind(this),
        //             250
        //         );
        //     }
        // }
    }
}