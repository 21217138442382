import React, { Fragment, useEffect, useState } from "react";
import { HeaderOverlay, MenuNavbar, Seo } from "../components";
import { useTranslation } from "react-i18next";
// import useGetPage from "../hooks/useGetPage";
import { Header } from "../containers";
import Footer from "../components/Footer/Footer";
import HeaderBanner from "../components/HeaderCore/Banner";
import ListNews from "../containers/News/ListNews";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import HeaderBannerOne from "../components/HeaderCore/HeaderBannerOne";
import Company from "../components/Shareholding/MainPage/Company/Company";
import Financial from "../components/Shareholding/MainPage/Financial/Financial";
import Information from "../components/Shareholding/MainPage/Information/Information";
import Publication from "../components/Shareholding/MainPage/Publication/Publication";
import Social from "../components/Sustainable/MainPages/Social";
import useStrAPIPage from "../hooks/useStrAPIPage";

function Shareholding() {
  const [lang, setLang] = useState();
  const { t, i18n } = useTranslation();
  // const { pages, loading, getPage } = useGetPage();
  const { PageAPI, loadingPage, getAPIPage } = useStrAPIPage();

  let { mainpage } = useParams();

  useEffect(() => {
    if (localStorage.getItem("ncl_lang")) {
      setLang(localStorage.getItem("ncl_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (lang !== undefined) {
      getAPIPage(
        "shareholding",
        lang,
        "&populate[board_of_directors][populate]=*&populate[executive_committee][populate]=*"
      );
    }
  }, [lang]);

  const handleLang = (event, attrLang) => {
    window.location.hash = "";
    let changeText = attrLang === "th" ? "en" : "th";
    localStorage.setItem("ncl_lang", changeText);
    setLang(localStorage.getItem("ncl_lang"));
    i18n.changeLanguage(lang);
  };
  // console.log('PageAPI',PageAPI.data.attributes.board_of_directors)
  return (
    <Fragment>
      <Seo
        title="Home"
        description="A description of the page"
        name="Company name"
        type="articlec"
        keywords="HTML, CSS, JavaScript"
      />

      <MenuNavbar>
        <div className="align-items-center icon-nav-custom">
          <img
            src="/images/icon/icon-12.png"
            alt={"NCL Language " + lang}
            // height="30"
            className="narbar_link img-icon-nav"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          />
          <span
            style={{ textTransform: "uppercase" }}
            className="pt-1 narbar_font"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          >
            {lang}
          </span>
        </div>
      </MenuNavbar>

      <HeaderBannerOne
        img={"/images/banner/Bannerนักลงทุนสัมพันธ์.jpg"}
        imgMobile={"/images/banner/นักลงทุนสัมพันธ์-ข้อมูลบริษัท-Mobile-01.jpg"}
        id={"BgBanner"}
        title={lang === "th" ? "นักลงทุนสัมพันธ์" : "Investor Relations "}
        colorH1={"text-white"}
        colorP={"text-white"}
      />
      {mainpage === "company" && <Company lang={lang} data={PageAPI?.data} />}
      {mainpage === "financial" && <Financial lang={lang} />}
      {mainpage === "Information" && <Information lang={lang} />}
      {mainpage === "publication" && <Publication lang={lang} />}
      {mainpage === "social" && <Social lang={lang} />}

      <Footer />
    </Fragment>
  );
}

export default Shareholding;
