import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { HeaderOverlay, MenuNavbar, Seo } from "../components";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer/Footer";
import HeaderBanner from "../components/HeaderCore/Banner";
import ListNews from "../containers/News/ListNews";
import HeaderBannerOne from "../components/HeaderCore/HeaderBannerOne";
import { baseUrl } from "../services/Environment";
import ViewPDF from "../components/ViewPDF/ViewPDF";
import useStrAPI from "../hooks/useStrAPI";
import useStrAPIStockMarketNewsNCL from "../hooks/useStrAPIStockMarketNewsNCL";
import useStrAPICompanyNews from "../hooks/useStrAPICompanyNews";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Box, Tab, Tabs, tabsClasses } from "@mui/material";

import { InterViews } from "../containers";

import useStrAPIInterview from "../hooks/useStrAPIInterview";

const MarketList = [
  <ViewPDF
    date={"09/08/2566"}
    title={"Lorem ipsum dolor sit amet, consectetur "}
    year="2566"
  />,
];

function News() {
  const [lang, setLang] = useState();
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMPage, setCurrentMPage] = useState(1);
  const [currentMePage, setCurrentMePage] = useState(1);
  const [currentMePage_Clipping, setCurrentMePage_Clipping] = useState(1);
  const [value, setValue] = useState(0);
  const [filter, setFilter] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const { GetDatas, loading, getStrAPI } = useStrAPI();
  const [YearNow, setYearNow] = useState();
  const [currentPageInterView, setCurrentPageInterView] = useState(1);

  const {
    GetDatas_StockMarketNewsNCL,
    loading_StockMarketNewsNCL,
    getStrAPI_StockMarketNewsNCL,
  } = useStrAPIStockMarketNewsNCL();

  function scrollToElement(id) {
    let offset = 70; // ระยะ offset ที่ต้องการ
    const element = document.getElementById(id);
    const offsetTop = element.offsetTop - offset; // คำนวณระยะ offset ที่ต้องการเลื่อน
    if (GetDatas_Interview && !loading) {
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth", // เลื่อนโดยการ animate ให้เรียบร้อย
      });
    }
  }

  // --------------------------------------------------------------------

  const [
    currentMePage_StockMarketNewsNCL,
    setCurrentMePage_StockMarketNewsNCL,
  ] = useState(1);
  const { GetDatas_CompanyNews, loading_CompanyNews, getStrAPI_CompanyNews } =
    useStrAPICompanyNews();
  // --------------------------------------------------------------------

  const { GetDatas_Interview, loading_Interview, getStrAPI_Interview } =
    useStrAPIInterview();

  const [NewsList, setNewsList] = useState([
    <ViewPDF date={""} title={"nodata"} year={""} />,
  ]);

  const [StockMarketNewsNCL, setStockMarketNewsNCL] = useState([
    <ViewPDF date={""} title={"nodata"} year={""} />,
  ]);
  const [CompanyNewsList, setCompanyNews] = useState([]);
  const [InterViewList, setInterView] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("ncl_lang")) {
      setLang(localStorage.getItem("ncl_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }

    const itemsHref = window.location.hash;
    if (
      itemsHref !== "" &&
      itemsHref !== "#" &&
      itemsHref !== "itemsHref" &&
      itemsHref !== undefined &&
      itemsHref !== null
    ) {
      const element = document.querySelector(itemsHref);
      if (element) {
        const offset = 80;
        const offsetTop = element.offsetTop - offset;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth", // เลื่อนโดยการ animate ให้เรียบร้อย
        });
      }
    }
    if (lang !== undefined) {
      // getStrAPI_CompanyNews("plural-company-news", lang,'&populate[detail][populate]=*&populate[cover_image][populate]=*')
      // getStrAPI("news-clippings", lang,'&populate[detail][populate]=*');
      // getStrAPI_StockMarketNewsNCL("stock-market-news-ncls", lang,'&populate[detail][populate]=*')

      getStrAPI_CompanyNews(
        "plural-company-news",
        lang,
        "&sort[0]=publishedAt:DESC&populate[detail][populate]=*&populate[cover_image][populate]=*"
      );
      getStrAPI(
        "news-clippings",
        lang,
        "&sort[0]=year:DESC&populate[detail][populate]=*"
      );
      getStrAPI_StockMarketNewsNCL(
        "stock-market-news-ncls",
        lang,
        "&sort[0]=year:DESC&populate[detail][populate]=*"
      );
      getStrAPI_Interview("interviews", lang, "&populate=*&sort[0]=id:desc");
    }
    setYearNow(
      lang === "en"
        ? moment().format("YYYY")
        : moment().add(543, `year`).format("YYYY")
    );
    setValue(
      lang === "en"
        ? moment().format("YYYY")
        : moment().add(543, `year`).format("YYYY")
    );
  }, [lang]);

  useEffect(() => {
    if (!loading_CompanyNews) {
      const CompanyNewsArray = GetDatas_CompanyNews?.data.map((item, index) => (
        <ListNews
          key={item.id}
          date={moment(item.attributes.publishedAt).format("DD")}
          subDate={moment(item.attributes.publishedAt).format("MMMM DD,YYYY")}
          title={item.attributes.title}
          content={item.attributes.detail}
          src={baseUrl + item.attributes.cover_image.data.attributes.url}
          slug={item.attributes.slug}
        />
      )) || [
        <ListNews date="" subDate="" title={"nodata"} content="" src="" />,
        <ListNews date="" subDate="" title={"nodata"} content="" src="" />,
        <ListNews date="" subDate="" title={"nodata"} content="" src="" />,
        <ListNews date="" subDate="" title={"nodata"} content="" src="" />,
        <ListNews date="" subDate="" title={"nodata"} content="" src="" />,
        <ListNews date="" subDate="" title={"nodata"} content="" src="" />,
      ];
      setCompanyNews(CompanyNewsArray);
    }
  }, [GetDatas_CompanyNews, loading_CompanyNews]);

  useEffect(() => {
    const InterViewsArray = GetDatas_Interview?.data.map((element, index) => (
      <InterViews data={element.attributes} id={element.id} key={element.id} />
    )) || [<InterViews data={"nodata"} id={""} key={""} />];
    setInterView(InterViewsArray);
  }, [GetDatas_Interview, loading_Interview]);

  useEffect(() => {
    const StockMarketNewsNCLArray = [];
    if (!loading_StockMarketNewsNCL) {
      GetDatas_StockMarketNewsNCL?.data.forEach((item) => {
        // console.log('item',item.attributes)
        item.attributes.detail?.forEach((item_1) => {
          // console.log('item_1',baseUrl,item_1.file)
          StockMarketNewsNCLArray.push([
            <ViewPDF
              // date={"09/08/2567"}
              href={baseUrl + item_1.file?.data?.attributes.url}
              date={
                lang === "en"
                  ? moment(item_1.date).format("DD/MM/YYYY")
                  : moment(item_1.date).add(543, `year`).format("DD/MM/YYYY")
              }
              title={item_1.name}
              year={"2012"}
            />,
          ]);
        });
      });
    }

    setStockMarketNewsNCL(StockMarketNewsNCLArray);
  }, [GetDatas_StockMarketNewsNCL, loading_StockMarketNewsNCL]);

  useEffect(() => {
    const GetDatasArray = [];
    if (!loading) {
      GetDatas?.data.forEach((item) => {
        // console.log('item',item.attributes)
        item.attributes.detail?.forEach((item_1) => {
          // console.log('item_1',baseUrl,item_1.file)
          GetDatasArray.push([
            <ViewPDF
              // date={"09/08/2567"}
              href={baseUrl + item_1.file?.data?.attributes.url}
              date={
                lang === "en"
                  ? moment(item_1.date).format("DD/MM/YYYY")
                  : moment(item_1.date).add(543, `year`).format("DD/MM/YYYY")
              }
              title={item_1.name}
              year={"2012"}
            />,
          ]);
        });
      });
    }
    //  GetDatas, loading,
    setNewsList(GetDatasArray);
  }, [GetDatas, loading]);

  // console.log('YearNow',YearNow)
  const handleLang = (event, attrLang) => {
    window.location.hash = "";
    let changeText = attrLang === "th" ? "en" : "th";
    localStorage.setItem("ncl_lang", changeText);
    setLang(localStorage.getItem("ncl_lang"));
    i18n.changeLanguage(lang);
  };

  //------ Year --------//
  const changePDFView = (selectedYear) => {
    // หา ViewPDF ที่ตรงกับปีที่เลือก
    const selectedPDFs = MarketList.filter(
      (pdf) => pdf.props.year === selectedYear
    );
    // แสดง ViewPDF ที่เลือก หรือจะทำอะไรก็ตามที่ต้องการ
    setFilterItems(selectedPDFs);
    // console.log("selectedPDFs", selectedPDFs);
  };

  // console.log("filterItems", filterItems);

  const changeYear = (event, newValue) => {
    setValue(newValue);
    changePDFView(newValue);
    setFilter(true);
  };

  //------ Market --------//
  const handleMediaChange_StockMarketNewsNCL = (event, value) => {
    setCurrentMePage_StockMarketNewsNCL(value);
    scrollToElement("market");
  };

  const itemsPerMePage_StockMarketNewsNCL = 8;
  // const pageMeCount = Math.ceil(MarketList.length / itemsPerMePage);

  const pageMeCount_StockMarketNewsNCL = Math.ceil(
    StockMarketNewsNCL.length / itemsPerMePage_StockMarketNewsNCL
  );

  const paginatedMeLists_StockMarketNewsNCL = Array.from(
    { length: pageMeCount_StockMarketNewsNCL },
    (_, index) => {
      const startIndex = index * itemsPerMePage_StockMarketNewsNCL;
      return StockMarketNewsNCL.slice(
        startIndex,
        startIndex + itemsPerMePage_StockMarketNewsNCL
      );
    }
  );
  const changeYear_StockMarketNewsNCL = (event, newValue, index) => {
    setValue(newValue);
    changePDFView(newValue);
    setFilter(true);
    setCurrentMePage_StockMarketNewsNCL(1);

    const filtered_StockMarketNewsNCL =
      GetDatas_StockMarketNewsNCL?.data.filter(
        (item) => item.attributes.year === newValue
      );
    if (filtered_StockMarketNewsNCL) {
      const itemList = [];
      filtered_StockMarketNewsNCL.forEach((item) => {
        //
        // console.log('item',item.attributes)
        item.attributes.detail?.forEach((item_1) => {
          // console.log('item_1',baseUrl,item_1.file)
          itemList.push([
            <ViewPDF
              // date={"09/08/2567"}
              href={baseUrl + item_1.file?.data?.attributes.url}
              date={
                lang === "en"
                  ? moment(item_1.date).format("DD/MM/YYYY")
                  : moment(item_1.date).add(543, `year`).format("DD/MM/YYYY")
              }
              title={item_1.name}
              year={newValue}
            />,
          ]);
        });
      });
      if (itemList.length === 0) {
        itemList.push([<ViewPDF date={""} title={"nodata"} year={""} />]);
      }
      setStockMarketNewsNCL(itemList);
    }
  };
  //------ Media --------//
  const handleMediaChange_Clipping = (event, value) => {
    setCurrentMePage_Clipping(value);
    scrollToElement("media");
  };

  const itemsPerMePage = 8;
  const pageMeCount_Clipping = Math.ceil(NewsList.length / itemsPerMePage);
  const paginatedMeLists_Clipping = Array.from(
    { length: pageMeCount_Clipping },
    (_, index) => {
      const startIndex = index * itemsPerMePage;
      return NewsList.slice(startIndex, startIndex + itemsPerMePage);
    }
  );
  const changeYear_Clipping = (event, newValue, index) => {
    setValue(newValue);
    changePDFView(newValue);
    setFilter(true);
    setCurrentMePage_Clipping(1);

    const filteredNews = GetDatas?.data.filter(
      (item) => item.attributes.year === newValue
    );
    if (filteredNews) {
      const newNewsList = [];
      filteredNews.forEach((item) => {
        item.attributes.detail.forEach((item_1) => {
          newNewsList.push([
            <ViewPDF
              // date={"09/08/2567"}
              href={baseUrl + item_1.file?.data?.attributes.url}
              date={moment(item_1.date).format("DD/MM/YYYY")}
              title={item_1.name}
              year={newValue}
            />,
          ]);
        });
      });
      if (newNewsList.length === 0) {
        newNewsList.push([<ViewPDF date={""} title={"nodata"} year={""} />]);
      }
      // console.log('newNewsList',newNewsList)
      setNewsList(newNewsList);
    }
  };

  //------ News --------//
  // เปลี่ยน handleChange เพื่อให้เปลี่ยนหน้าเมื่อมีการคลิก Pagination
  const handleChange = (event, value) => {
    setCurrentPage(value);
    scrollToElement("news");
  };

  // console.log('GetDatas_CompanyNews',CompanyNewsList);
  const itemsPerPage = 6;
  const pageCount = Math.ceil(CompanyNewsList.length / itemsPerPage);

  const paginatedLists = Array.from({ length: pageCount }, (_, index) => {
    const startIndex = index * itemsPerPage;
    return CompanyNewsList.slice(startIndex, startIndex + itemsPerPage);
  });

  // เปลี่ยน handleChangeInterView เพื่อให้เปลี่ยนหน้าเมื่อมีการคลิก Pagination
  const handleChangeInterView = (event, value) => {
    setCurrentPageInterView(value);
    scrollToElement("management");
  };

  const itemsPerPageInterView = 8;
  const pageCountInterView = Math.ceil(
    InterViewList.length / itemsPerPageInterView
  );
  const paginatedListsInterView = Array.from(
    { length: pageCountInterView },
    (_, index) => {
      const startIndexInterView = index * itemsPerPageInterView;
      return InterViewList.slice(
        startIndexInterView,
        startIndexInterView + itemsPerPageInterView
      );
    }
  );

  return (
    <Fragment>
      <Seo
        title="Home"
        description="A description of the page"
        name="Company name"
        type="articlec"
        keywords="HTML, CSS, JavaScript"
      />

      <MenuNavbar>
        <div className="align-items-center icon-nav-custom">
          <img
            src="/images/icon/icon-12.png"
            alt={"NCL Language " + lang}
            // height="30"
            className="narbar_link img-icon-nav"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          />
          <span
            style={{ textTransform: "uppercase" }}
            className="pt-1 narbar_font"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          >
            {lang}
          </span>
        </div>
      </MenuNavbar>

      {/* Header */}
      <HeaderBannerOne
        img={"/images/banner/ข่าวสารและกิจกรรม-35_0.jpg"}
        imgMobile={"/images/banner/แบรนเนอร์ข่าวสารและกิจกรรม-Mobile-01_0.jpg"}
        id={"BgBanner"}
        title1={lang === "th" ? "ข่าวสาร และกิจกรรม" : "News & Events"}
        colorH1={"text-white"}
        colorP={"text-white"}
      />

      {/*------- ข่าวแจ้งตลาดหลักทรัพย์ -----------*/}
      <section id="market" className="section container">
        <h1 className="title-line fw-bold">
          {lang === "th" ? `ข่าวแจ้งตลาดหลักทรัพย์` : `Stock Market News`}
        </h1>

        <Box
          className="mx-auto mt-3 mb-5"
          sx={{ maxWidth: { xs: 420, sm: 580 }, bgcolor: "background.paper" }}
        >
          <Tabs
            value={value}
            onChange={changeYear_StockMarketNewsNCL}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            {GetDatas_StockMarketNewsNCL?.data.map((item, index) => (
              <Tab
                className="fs-4"
                label={item.attributes.year}
                value={item.attributes.year}
                index={index}
              />
            ))}
          </Tabs>
        </Box>

        <Stack>
          <Typography>
            <div>
              {paginatedMeLists_StockMarketNewsNCL.length === 0
                ? ""
                : paginatedMeLists_StockMarketNewsNCL[
                    currentMePage_StockMarketNewsNCL - 1
                  ].map((list, index) => list)}
            </div>
          </Typography>
          <Pagination
            count={pageMeCount_StockMarketNewsNCL}
            page={currentMePage_StockMarketNewsNCL}
            onChange={handleMediaChange_StockMarketNewsNCL}
            hidePrevButton
            hideNextButton
            shape="rounded"
            className="d-flex justify-content-end pt-5 pe-4"
            sx={{
              "& .Mui-selected": { bgcolor: "#cccccc !important" },
              "& button": { bgcolor: "#e6e6e6" },
            }}
          ></Pagination>
        </Stack>
      </section>

      {/*------- ข่าวแจ้งสื่อมวลชน -----------*/}
      <section id="news" className="bg-blue">
        <div className="container section">
          <h1 className=" text-white py-4 fw-bolder tit`le-line">
            {lang === "th" ? `ข่าวแจ้งสื่อมวลชน` : `NCL News`}
          </h1>
          <Stack spacing={2}>
            <Typography>
              <div className="row d-flex justify-content-start">
                {paginatedLists.length === 0
                  ? ""
                  : paginatedLists[currentPage - 1].map((list, index) => list)}
              </div>
            </Typography>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handleChange}
              className="d-flex justify-content-center"
              sx={{
                "& .Mui-selected": { bgcolor: "#bc6c3c !important" },
                "$ .Mui-selected:hover": { bgcolor: "#bc6c3c !important" },
              }}
              shape="rounded"
              size="large"
            ></Pagination>
          </Stack>
        </div>
      </section>
      <HeaderBanner
        img="/images/banner/ข่าวสารและกิจกรรม-36_0.jpg"
        imgMobile={"/images/banner/ข่าวสารและกิจกรรม-Mobile-02_0.jpg"}
      />

      {/*------- ข่าวจากสื่อสิ่งพิมพ์ ------*/}
      <section id="media" className="pt-5 container">
        <h1 className="title-line fw-bold">
          {lang === "th" ? `ข่าวจากสื่อสิ่งพิมพ์` : `News Clippings`}
        </h1>

        <Box
          className="mx-auto mt-3 mb-5"
          sx={{ maxWidth: { xs: 420, sm: 580 }, bgcolor: "background.paper" }}
        >
          <Tabs
            value={value}
            onChange={changeYear_Clipping}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            {GetDatas?.data.map((item, index) => (
              <Tab
                className="fs-4"
                label={item.attributes.year}
                value={item.attributes.year}
                index={index}
              />
            ))}
          </Tabs>
        </Box>

        <Stack>
          <Typography>
            <div>
              {paginatedMeLists_Clipping.length === 0
                ? ""
                : paginatedMeLists_Clipping[currentMePage_Clipping - 1].map(
                    (list, index) => list
                  )}
            </div>
          </Typography>
          <Pagination
            count={pageMeCount_Clipping}
            page={currentMePage_Clipping}
            onChange={handleMediaChange_Clipping}
            hidePrevButton
            hideNextButton
            shape="rounded"
            className="d-flex justify-content-end pt-5 pe-4"
            sx={{
              "& .Mui-selected": { bgcolor: "#cccccc !important" },
              "& button": { bgcolor: "#e6e6e6" },
            }}
          ></Pagination>
        </Stack>
      </section>

      {/*------- สัมภาษณ์ผู้บริหาร ------*/}

      <div id="management" className="section sec-news pt-5">
        <div className="container">
          <div className="row align-items-center justify-content-center text-center pb-5">
            <div className="col-lg-6">
              <h1
                className="heading mb-3 aos-init aos-animate fw-bold"
                data-aos="fade-up"
              >
                {t("str_interview_title")}
              </h1>
              <hr
                style={{
                  height: "5px",
                  maxWidth: "20%",
                  margin: "0px auto",
                  color: "#bc6c3c",
                  opacity: 1,
                }}
              ></hr>
            </div>
          </div>
          <div className="row">
            {/* {GetDatas_Interview?.data.map((element) => {
              return (
                <InterViews
                  data={element.attributes}
                  id={element.id}
                  key={element.id}
                />
              );
            })} */}
            <Stack spacing={2}>
              <Typography>
                <div className="row d-flex justify-content-start">
                  {paginatedListsInterView.length === 0
                    ? ""
                    : paginatedListsInterView[currentPageInterView - 1].map(
                        (list, index) => list
                      )}
                </div>
              </Typography>
              <Pagination
                count={pageCountInterView}
                page={currentPageInterView}
                onChange={handleChangeInterView}
                className="d-flex justify-content-center"
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#666666 !important",
                  },
                }}
                shape="rounded"
                size="large"
              ></Pagination>
            </Stack>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
export default News;
