import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import Collapse from "react-bootstrap/Collapse";

function NavItemAndDropdown({
  title,
  items,
  id,
  isDropdown,
  titleHref,
  href,
  itemsHref,
}) {
  // const [Test, setTest] = useState('/');
  const [open, setOpen] = useState(false);
  const [isMobileNav, setIsMobileNav] = useState(false);

  // const navigate = useNavigate();
  // const onNavigate = () => {
  //   navigate(`${titleHref}`);
  //   console.log("titleHref", titleHref);
  // };

  const windowWidthOnLoad = () => {
    const browser = window.innerWidth;
    if (browser <= 1200) {
      setIsMobileNav(true);
    } else {
      setIsMobileNav(false);
    }
  };

  const windowWidthOnResize = () => {
    const browser = window.innerWidth;
    if (browser <= 1200) {
      setIsMobileNav(true);
    } else {
      setIsMobileNav(false);
    }
  };

  useEffect(() => {
    windowWidthOnLoad();
    window.addEventListener("resize", windowWidthOnResize);
  }, []);

  // $(document).ready(function () {
  //   $(".collapse-list").hover(
  //     function () {
  //       $(this).children(".collapse").fadeIn("3000");
  //     },
  //     function () {
  //       $(this).children(".collapse").fadeOut("slow");
  //     }
  //   );
  // });

  const handleOnScroll = (event, attrLang) => {
    // if(`#${event.split('#')[1]}` !== Test){
    //    setTest(`#${event.split('#')[1]}`);
    //    localStorage.setItem('itemsHref',`#${event.split('#')[1]}`)
    // }

    if (`${event.split("#")[0]}` !== window.location.pathname) {
      window.location.href = `${event}`;
    }

    if (
      `#${event.split("#")[1]}` !== "/"
      // `#${event.split("#")[1]}` !== `#${event.split("#")[1]}`
    ) {
      const element = document.querySelector(`#${event.split("#")[1]}`);
      if (element) {
        console.log(element);
        const offset = 70;
        const offsetTop = element.offsetTop - offset;
        // element.scrollIntoView({ behavior: "smooth" });

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth", // เลื่อนโดยการ animate ให้เรียบร้อย
        });
      }
    }
  };

  return (
    <li className="collapse-list w-100  d-flex flex-column border-bottom border-secondary">
      {isMobileNav ? (
        <a
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          // href={href}
          style={{ cursor: "pointer" }}
          className=" fs-5 nav-link d-flex justify-content-between align-items-center "
        >
          <span className="py-2 ">{title}</span>{" "}
          {isDropdown ? <div className="dropdown-toggle pe-2"></div> : null}
        </a>
      ) : (
        <a
          // onClick={onNavigate}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          aria-controls="example-collapse-text"
          href={href}
          style={{ cursor: "pointer" }}
          className=" fs-5 nav-link d-flex justify-content-between align-items-center "
        >
          {" "}
          <span className="py-2 ">{title}</span>{" "}
          {isDropdown ? <div className="dropdown-toggle pe-2"></div> : null}
        </a>
      )}
      {/* <a
        onClick={onNavigate}

        href={href}
        // data-bs-toggle="collapse"
        style={{ cursor: "pointer" }}
        className=" fs-5 nav-link d-flex justify-content-between align-items-center "
      >
        <span className="py-2 ">{title}</span>{" "}
        {isDropdown ? <div className="dropdown-toggle pe-2"></div> : null}
      </a> */}
      <Collapse in={open}>
        <ul
          className=" nav ms-5 mb-3"
          id={id}
          data-bs-parent="#menu"
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          {items?.map((item, index) => (
            <li key={index} className="w-100 ">
              <div data-bs-dismiss="offcanvas" aria-label="Close">
                <span
                  className="nav-link px-0 fs-6"
                  onClick={(event) => handleOnScroll(itemsHref[index])}
                  style={{ cursor: "pointer" }}
                >
                  {item}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </Collapse>
    </li>
  );
}

export default NavItemAndDropdown;
