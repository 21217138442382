import React from "react";

function HeaderBannerOne({ id, title, colorH1, img, imgMobile }) {
  const ClassH1 = "display-1 fw-bold ";
  return (
    <>
      <header
        id={id}
        className="d-sm-flex d-none text-start "
        style={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // backgroundSize: "100%",
          backgroundPosition: "center",
        }}
      >
        <div
          className="container d-flex flex-column justify-content-end align-items-start  w-75 pb-5 BS"
          style={{ height: "700px" }}
        >
          <h1 className={colorH1 ? ClassH1 + " " + colorH1 : ClassH1}>
            {title}
          </h1>
        </div>
      </header>
      <header
        id={id}
        className="d-sm-none text-start"
        style={{
          backgroundImage: `url(${imgMobile})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // backgroundSize: "100%",
          backgroundPosition: "center",
        }}
      >
        <div
          className="container d-flex flex-column justify-content-end align-items-start  w-75 pb-5 BS"
          style={{ height: "700px" }}
        >
          <h1 className={colorH1 ? ClassH1 + " " + colorH1 : ClassH1}>
            {title}
          </h1>
        </div>
      </header>
    </>
  );
}

export default HeaderBannerOne;
