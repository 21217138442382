import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

import VisibilitySensor from "react-visibility-sensor";

function ConsumptionItem({ lang, title, num, src, decimals }) {
  // const [hasAnimated, setHasAnimated] = useState(false);
  // const rowRef = useRef(null);

  // const genNumber = () => {
  //   document
  //     .querySelector(" .animate .five")
  //     ?.style.setProperty("--percent", 107.659);
  //   // document
  //   //   .querySelector(" .animate .six")
  //   //   ?.style.setProperty("--percent", 1.6);
  // };

  // setTimeout(genNumber);

  // useEffect(() => {
  //   const rowObserver = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting && !hasAnimated) {
  //           // เมื่อองค์ประกอบอยู่ในมุมมอง
  //           rowRef.current.classList.add("animate"); // เพิ่มคลาส animate เพื่อเริ่ม animation
  //           setHasAnimated(true);
  //         }
  //       });
  //     },
  //     { threshold: 0.5 } // เป็นค่าเปอร์เซ็นต์ของการมองเห็นที่ต้องการสำหรับการเริ่มต้น animation
  //   );

  //   if (rowRef.current) {
  //     rowObserver.observe(rowRef.current);
  //   }

  //   return () => {
  //     if (rowRef.current) {
  //       rowObserver.unobserve(rowRef.current);
  //     }
  //   };
  // }, [hasAnimated]);

  return (
    <div
      // ref={rowRef}
      className="d-flex text-center align-items-center justify-content-center gap-4 py-2"
    >
      <img src={src} style={{ height: "100px" }} />
      <div
        className="d-flex flex-column justify-content-center"
        style={{ width: "330px" }}
      >
        <h4 className="m-0">{title}</h4>
        <VisibilitySensor>
          {({ isVisible }) => (
            <h1 className={`display-3 fw-bold my-2 `}>
              {isVisible ? (
                <CountUp end={num} duration={3} decimals={decimals} />
              ) : (
                "invisible"
              )}
            </h1>
          )}
        </VisibilitySensor>
      </div>
    </div>
  );
}

export default ConsumptionItem;
