// NameNuT 20240423
import axios from "axios";
let baseDomain;
baseDomain = "https://admin.nclthailand.com";
// baseDomain = 'http://192.168.1.57:1337';

export const customHeaders = {
  Accept: "application/json",
  Authorization:
    "Bearer 3bff55550a9d4d711208d9c0944b6a5b3bdc684d4d14dfa66eb641995adb9e2c72e32cc0ea6dea542254b3882bd0f239df647c2ad89c1e52ca9cb77cf3646dab6a374c983989e4bf09be6214caf5103cc1adf6f0719c59d3dc8d21722edf860214d2db374818c5703e6d408345a7be76fae50f79a7ee279b7c5922cf1e2bb159",
};
export const baseUrl = `${baseDomain}`;

export default axios.create({
  baseUrl,
  headers: customHeaders,
});

export const serializeQuery = (query) => {
  return Object.keys(query)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
    )
    .join("&");
};
