// import React from 'react';
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouters from "./routes/AppRouters";
import AOS from "aos";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/template/css/tiny-slider.css";
import "./assets/template/css/aos.css";
import "./assets/template/css/style.css";
import "./assets/template/css/flatpickr.min.css";
import "./assets/template/css/ct-navbar.css";
import "./App.css";
import "./custom.css";

// import "./assets/template/js/navbar.js";
// import "./assets/template/js/counter.js";
// import "./assets/template/js/custom.js";

function App() {
  AOS.init({
    duration: 800,
    easing: "slide",
    once: true,
  });

  useEffect(() => {
    var loader = document.querySelector(".loader");
    var overlay = document.getElementById("overlayer");

    function fadeOut(el) {
      el.style.opacity = 1;
      (function fade() {
        if ((el.style.opacity -= 0.1) < 0) {
          el.style.display = "none";
        } else {
          requestAnimationFrame(fade);
        }
      })();
    }

    setTimeout(function () {
      fadeOut(loader);
      fadeOut(overlay);
    }, 500);
  }, []);

  return (
    <BrowserRouter>
      <AppRouters />

      <div id="overlayer"></div>
      <div className="loader text-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">
            {localStorage.getItem("ncl_lang") === "th"
              ? "กำลังโหลด..."
              : "Loading..."}
          </span>
        </div>
        <span>
          <br />
          {localStorage.getItem("ncl_lang") === "th"
            ? "กำลังโหลด..."
            : "Loading..."}
        </span>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        limit={3}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </BrowserRouter>
  );
}

export default App;
