import Environment, { baseUrl } from "./Environment";

class StrAPIPage {
    async getStrAPIPage(path, locale='th' ,populate = '&populate=*') {
        console.log(`${baseUrl}/api/${path}?locale=${locale}${populate}`)
        const response = await Environment.get(`${baseUrl}/api/${path}?locale=${locale}${populate}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    // async getById(payload) {
    //     const response = await Environment.get(`${baseUrl}/api/home/${payload}?populate=*`)
    //         .then((response) => {
    //             return response.data;
    //         }).catch((error) => ({ error: JSON.stringify(error) }));
    //     return response;
    // }
    // async post(path, locale) {
    //     const response = await Environment.post(`${baseUrl}/api/${path}?locale=${locale}&populate=*`)
    //         .then((response) => {
    //             return response.data;
    //         })
    //         .catch((error) => ({ error: JSON.stringify(error) }));
    //     return response;
    // }

    // async postById(payload) {
    //     const response = await Environment.post(`${baseUrl}/api/home/${payload}?populate=*`)
    //         .then((response) => {
    //             return response.data;
    //         }).catch((error) => ({ error: JSON.stringify(error) }));
    //     return response;
    // }
}

export default new StrAPIPage();