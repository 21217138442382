// import React, { useState } from "react";
import React, { Fragment, useEffect, useState } from "react";
import { InputGroup, Stack } from "react-bootstrap";
import { Pagination, Typography } from "@mui/material";
import { Box, Tab, Tabs, tabsClasses } from "@mui/material";
import ViewPDF from "../../../ViewPDF/ViewPDF";
import SwapColor from "../../../SwapColor/SwapColor";
import NavBarItem from "../../../Navbar/NavBarItem";
import OnlineList from "../../../OnlineList/OnlineList";

import { baseUrl } from "../../../../services/Environment";
import useStrAPIPage from "../../../../hooks/useStrAPIPage";
import { Dataset } from "@mui/icons-material";

const ReportLists_1 = [
  { title: ` รายงานประจำปี 2567`, url: "asdasd." },
  { title: ` รายงานประจำปี 2567`, url: "asdasd." },
  { title: ` รายงานประจำปี 2567`, url: "asdasd." },
  { title: ` รายงานประจำปี 2567`, url: "asdasd." },
  { title: ` รายงานประจำปี 2567`, url: "asdasd." },
  { title: ` รายงานประจำปี 2567`, url: "asdasd." },
  { title: ` รายงานประจำปี 2567`, url: "asdasd." },
];

const OpportunityList_1 = [
  { title: ` รายงานประจำปี 2567`, url: "asdasd." },
  { title: ` รายงานประจำปี 2567`, url: "asdasd." },
  { title: ` รายงานประจำปี 2567`, url: "asdasd." },
  { title: ` รายงานประจำปี 2567`, url: "asdasd." },
  { title: ` กิจกรรมพบปะนักลงทุน 2562`, url: "asdasd." },
  { title: ` กิจกรรมพบปะนักลงทุน 2562`, url: "asdasd." },
  { title: ` กิจกรรมพบปะนักลงทุน 2561`, url: "asdasd." },
];

const FormLists_1 = [
  <ViewPDF title={`แบบแสดงรายการข้อมูลประจำปี (56-1) ปี 2564`} />,
  <ViewPDF title={`แบบแสดงรายการข้อมูลประจำปี (56-1) ปี 2563`} />,
  <ViewPDF title={`แบบแสดงรายการข้อมูลประจำปี (56-1) ปี 2562`} />,
  <ViewPDF title={`แบบแสดงรายการข้อมูลประจำปี (56-1) ปี 2561`} />,
  <ViewPDF title={`แบบแสดงรายการข้อมูลประจำปี (56-1) ปี 2560`} />,
  <ViewPDF title={`แบบแสดงรายการข้อมูลประจำปี (56-1) ปี 2559`} />,
  <ViewPDF title={`แบบแสดงรายการข้อมูลประจำปี (56-1) ปี 2558`} />,
  <ViewPDF title={`แบบแสดงรายการข้อมูลประจำปี (56-1) ปี 2557`} />,
  <ViewPDF title={`แบบแสดงรายการข้อมูลประจำปี (56-1) ปี 2576`} />,
];

const CalendarLists = [
  <SwapColor title={2561} lists={[`CalendarLists `]} year={2561} />,
  <SwapColor title={2562} lists={[`CalendarLists `]} year={2562} />,
  <SwapColor title={2563} lists={[`CalendarLists `]} year={2563} />,
  <SwapColor title={2564} lists={[`CalendarLists `]} year={2564} />,
  <SwapColor title={2565} lists={[`CalendarLists `]} year={2565} />,
  <SwapColor title={2566} lists={[`CalendarLists `]} year={2566} />,
  <SwapColor title={2555} lists={[`CalendarLists `]} year={2555} />,
  <SwapColor title={2554} lists={[`CalendarLists `]} year={2554} />,
  <SwapColor title={2553} lists={[`CalendarLists `]} year={2553} />,
];

const AnalystLists = [
  <ViewPDF title={`AnalystList`} />,
  <ViewPDF title={`AnalystList`} />,
  <ViewPDF title={`AnalystList`} />,
  <ViewPDF title={`AnalystList`} />,
  <ViewPDF title={`AnalystList`} />,
];

function Publication({ lang }) {
  const [cValue, setCValue] = useState(0);

  const [currentRPage, setCurrentRPage] = useState(1);
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentCPage, setCurrentCPage] = useState(1);
  const [currentAPage, setCurrentAPage] = useState(1);
  const [currentOPage, setCurrentOPage] = useState(1);
  const [filterCItems, setFilterCItems] = useState([]);
  const [ReportLists, setReportLists] = useState([]);
  const [FormLists, setFormLists] = useState([]);
  const [OpportunityList, setOpportunityList] = useState([]);
  const [IMGReportLists, setIMGReportLists] = useState("");
  const [IMGOpportunityList, setIMGOpportunityList] = useState("");

  const { PageAPI, loadingPage, getAPIPage } = useStrAPIPage();

  useEffect(() => {
    if (lang !== undefined) {
      getAPIPage(
        "shareholding-publication",
        lang,
        "&populate[annual_report][populate]=*&populate[form_56_1][populate]=*&populate[opportunity_day][populate]=*"
      ); //populate
    }
  }, [lang]);

  useEffect(() => {
    // if (ReportLists) {
    setReportLists(
      PageAPI?.data.attributes?.annual_report.map((item) => ({
        title: item.name,
        url: baseUrl + item.file.data?.attributes.url,
      })) || [
        { title: "title", url: "url" },
        // <ViewPDF title={"--------------"} value="-------" />
      ]
    );
    setFormLists(
      PageAPI?.data.attributes?.form_56_1.map((item) => (
        // {"title": item.name, "url": baseUrl+item.file.data?.attributes.url}
        <ViewPDF
          title={item.name}
          href={baseUrl + item.file.data?.attributes.url}
        />
      )) || [
        // {"title": 'title', "url": 'url'}
        <ViewPDF title={"--------------"} value="-------" />,
      ]
    );
    setOpportunityList(
      PageAPI?.data.attributes?.opportunity_day.map((item) => ({
        title: item.name,
        url: item.url,
      })) || [
        { title: "title", url: "url" },
        // <ViewPDF title={"--------------"} value="-------" />
      ]
    );
    // };
    // }
    // console.log('annual_report',PageAPI?.data.attributes.annual_report[0].img)
    setIMGReportLists(
      baseUrl +
        PageAPI?.data.attributes.annual_report[0].img.data?.attributes.url
    );
    setIMGOpportunityList(
      baseUrl +
        PageAPI?.data.attributes.opportunity_day[0].img.data?.attributes.url
    );
  }, [PageAPI, loadingPage]);
  // console.log('ss',IMGReportLists,IMGOpportunityList)
  function scrollToElement(id) {
    let offset = 90; // ระยะ offset ที่ต้องการ
    const element = document.getElementById(id);
    const offsetTop = element.offsetTop - offset; // คำนวณระยะ offset ที่ต้องการเลื่อน

    window.scrollTo({
      top: offsetTop,
      behavior: "smooth", // เลื่อนโดยการ animate ให้เรียบร้อย
    });
  }

  //------ ReportLists --------//
  const itemsPerPage = 6;
  // console.log(ReportLists,ReportLists.length)
  const pageCount = Math.ceil(ReportLists.length / itemsPerPage);
  const paginatedReportLists = Array.from({ length: pageCount }, (_, index) => {
    const startIndex = index * itemsPerPage;
    return ReportLists.slice(startIndex, startIndex + itemsPerPage);
  });
  const handleChange = (event, value) => {
    setCurrentRPage(value);
    scrollToElement("Report");
  };

  //------ FormLists --------//
  const itemsFPerPage = 6;
  const pageFCount = Math.ceil(FormLists.length / itemsFPerPage);
  const paginatedFormLists = Array.from({ length: pageFCount }, (_, index) => {
    const startIndex = index * itemsFPerPage;
    return FormLists.slice(startIndex, startIndex + itemsFPerPage);
  });
  const handleFormChange = (event, value) => {
    setCurrentFPage(value);
    scrollToElement("Form");
  };

  //------ Calendar --------//
  const handleCalendarChange = (event, value) => {
    setCurrentCPage(value);
  };
  const itemsCPerPage = 3;
  const pageCCount = Math.ceil(CalendarLists.length / itemsCPerPage);
  const paginatedCalendarLists = Array.from(
    { length: pageCCount },
    (_, index) => {
      const startIndex = index * itemsCPerPage;
      return CalendarLists.slice(startIndex, startIndex + itemsCPerPage);
    }
  );
  const changeCPDF = (selectedYear) => {
    const selectedCalendarPDFs = CalendarLists.filter(
      (pdf) => pdf.props.year == selectedYear
    );
    setFilterCItems(selectedCalendarPDFs);
  };

  const changeCalendarYear = (selectedYear, newValue) => {
    setCValue(newValue);
    changeCPDF(newValue);
  };
  console.log("filterCItems", filterCItems);

  //---------- Analyst ------------//

  const itemsAPerPage = 3;
  const pageACount = Math.ceil(AnalystLists.length / itemsAPerPage);
  const paginatedAnalystLists = Array.from(
    { length: pageACount },
    (_, index) => {
      const startIndex = index * itemsAPerPage;
      return AnalystLists.slice(startIndex, startIndex + itemsAPerPage);
    }
  );
  const handleAnalystChange = (event, value) => {
    setCurrentAPage(value);
  };

  //---------- OpportunityList ------------//
  const itemsPerOPage = 6;
  const pageOCount = Math.ceil(OpportunityList.length / itemsPerPage);
  const paginatedOpportunityList = Array.from(
    { length: pageCount },
    (_, index) => {
      const startIndex = index * itemsPerPage;
      return OpportunityList.slice(startIndex, startIndex + itemsPerPage);
    }
  );
  const handleOpportunityChange = (event, value) => {
    setCurrentOPage(value);
    scrollToElement("Opportunity");
  };

  return (
    <>
      {/*----- navBar -----*/}
      <nav className="mt-3 mobile_m0 d-flex py-3 gap-3 align-items-center xl-col justify-content-center">
        <div className="d-flex gap-3 py-1">
          <NavBarItem id={"Annual"}>
            {lang === "th" ? ` รายงานประจำปี` : `Annual Report`}
          </NavBarItem>
          <NavBarItem id={"Form"}>
            {lang === "th" ? `แบบฟอร์ม 56-1 ` : `Form 56-1 `}
          </NavBarItem>
        </div>
        <div className="d-flex gap-3 py-1">
          <NavBarItem id={"Opportunity"}>
            {lang === "th" ? `กิจกรรมพบปะนักลงทุน` : `Opportunity Day`}
          </NavBarItem>
        </div>
        {/* <div className="d-flex gap-3">
          <NavBarItem id={"Calendar"}>
            {lang === "th" ? `ปฏิทินกิจกรรมนักลงทุน ` : `IR Calendar  `}
          </NavBarItem>
          <NavBarItem id={"Analyst"}>
            {lang === "th" ? ` บทวิเคราะห์หลักทรัพย์` : `Analyst Report`}
          </NavBarItem>
        </div> */}
      </nav>
      {/*------- เอกสารเผยแพร่ --------*/}
      <section id="Annual" className="container pt-5">
        <h1 className="title-line fw-bold">
          {lang === "th" ? `เอกสารเผยแพร่ ` : `Publications`}
        </h1>
        <div id="Report" className="mx-auto py-5 " style={{ width: "90%" }}>
          <h2 className="fw-bold">
            {" "}
            {lang === "th" ? `รายงานประจำปี` : `Annual Report`}
          </h2>
          <div className="row">
            <div className="col-xl-3 col-lg-5 ">
              <div
                id="BgBanner"
                className="mx_auto my-4 mx-auto"
                style={{
                  backgroundImage: `url(${
                    IMGReportLists == null
                      ? "/images/noimg.jpg"
                      : IMGReportLists
                  })`,
                  // backgroundImage: `url(${(PageAPI?.data?.attributes?.annual_report[0]?.img?.data?.attributes?.url == null ? "/images/noimg.jpg" : (baseUrl+PageAPI?.data.attributes.annual_report[0].img.data.attributes.url))})`,
                  width: "clamp(150px,50vw,250px)",
                  height: "clamp(275px,50vw,350px)",
                  backgroundSize: "",
                }}
              ></div>
            </div>
            <div className="col-xl-9 col-lg-7 my-4">
              <Stack spacing={2}>
                <Typography>
                  <div className="row d-flex justify-content-start">
                    {paginatedReportLists[currentRPage - 1]?.map(
                      (list, index) => (
                        <OnlineList content={list} row={index} />
                      )
                    )}
                    {/* {paginatedReportLists[currentRPage - 1].map(
                        (list, index) => list
                      )} */}
                  </div>
                </Typography>
                <Pagination
                  count={pageCount}
                  page={currentRPage}
                  hidePrevButton
                  hideNextButton
                  onChange={handleChange}
                  className="d-flex justify-content-end pt-4 pe-4"
                  sx={{
                    "& .Mui-selected": { bgcolor: "#cccccc !important" },
                    "& button": { bgcolor: "#e6e6e6 !important" },
                    "& button:hover": { bgcolor: "lightgray !important" },
                  }}
                  shape="rounded"
                  size="large"
                ></Pagination>
              </Stack>
            </div>
          </div>
        </div>
      </section>

      {/*---------- แบบฟอร์ม 56-1 ----------*/}

      <section id="Form" className="bg-Lgray py-5">
        <div className="container ">
          <div className="mx-auto " style={{ width: "90%" }}>
            <h2 className="fw-bold mb-3">
              {lang === "th" ? ` แบบฟอร์ม 56-1 ` : `Form 56-1 `}
            </h2>
            <Stack spacing={2}>
              <Typography>
                <div className="row d-flex justify-content-start">
                  {paginatedFormLists[currentFPage - 1]?.map(
                    (list, index) => list
                  )}
                </div>
              </Typography>
              <Pagination
                count={pageCount}
                page={currentFPage}
                hidePrevButton
                hideNextButton
                onChange={handleFormChange}
                className="d-flex justify-content-end pt-4 pe-4"
                sx={{
                  "& .Mui-selected": { bgcolor: "#cccccc !important" },
                  "& button": { bgcolor: "#e6e6e6 !important" },
                  "& button:hover": { bgcolor: "lightgray !important" },
                }}
                shape="rounded"
                size="large"
              ></Pagination>
            </Stack>
          </div>
        </div>
      </section>

      {/*------- กิจกรรมพบปะนักลงทุน --------*/}
      <section id="Opportunity" className="container pt-5">
        <div className="mx-auto my-5 " style={{ width: "90%" }}>
          <h2 className="fw-bold">
            {" "}
            {lang === "th" ? `กิจกรรมพบปะนักลงทุน` : `Opportunity Day`}
          </h2>
          <div className="row ">
            <div className="col-xl-3 col-lg-5 ">
              <img
                id="BgBanner"
                className="mx_auto my-4 w-100"
                src={
                  IMGOpportunityList == null
                    ? "/images/noimg.jpg"
                    : IMGOpportunityList
                }
                alt="img"

                // style={{
                //   backgroundImage: `url(${
                //     IMGOpportunityList == null
                //       ? "/images/noimg.jpg"
                //       : IMGOpportunityList
                //   })`,
                // backgroundImage:  `url(${(PageAPI?.data?.attributes?.opportunity_day[0]?.img?.data == null ? "/images/noimg.jpg" : (baseUrl+PageAPI?.data.attributes.opportunity_day[0]?.img.data.attributes.url))})`,

                //   "url(/images/shareholding/นักลงทุนสัมพันธ์-30.jpg)",
                //   width: "clamp(150px,50vw,250px)",
                //   height: "clamp(275px,50vw,350px)",
                //   backgroundSize: "",
                // }}
              ></img>
            </div>
            <div id="Opportunity" className="col-xl-9 col-lg-7 my-4">
              <Stack spacing={2}>
                <Typography>
                  <div className="row d-flex justify-content-start">
                    {paginatedOpportunityList[currentOPage - 1]?.map(
                      (list, index) => (
                        <OnlineList content={list} row={index} />
                      )
                    )}
                    {/* {paginatedReportLists[currentRPage - 1].map(
                        (list, index) => list
                      )} */}
                  </div>
                </Typography>
                <Pagination
                  count={pageOCount}
                  page={currentOPage}
                  hidePrevButton
                  hideNextButton
                  onChange={handleOpportunityChange}
                  className="d-flex justify-content-end pt-4 pe-4"
                  sx={{
                    "& .Mui-selected": { bgcolor: "#cccccc !important" },
                    "& button": { bgcolor: "#e6e6e6 !important" },
                    "& button:hover": { bgcolor: "lightgray !important" },
                  }}
                  shape="rounded"
                  size="large"
                ></Pagination>
              </Stack>
            </div>
          </div>
        </div>
      </section>

      {/*--------- ยังไม่เปิดใช้งาน ---------*/}

      {/*--------- ปฎิทินกิจกรรมนักลงทุน ---------*/}
      {/* <section id="Calendar" className="container pt-5">
        <div className="mx-auto" style={{ width: "90%" }}>
          <h2 className="fw-bold">
            {lang === "th" ? `ปฎิทินกิจกรรมนักลงทุน` : `IR Calendar`}
          </h2>

          <Box
            className="mx-auto mt-3 mb-5"
            sx={{
              maxWidth: { xs: 420, sm: 580 },
              bgcolor: "background.paper",
            }}
          >
            <Tabs
              value={cValue}
              onChange={changeCalendarYear}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="visible arrows tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
            >
              <Tab className="fs-4" label="2561" value="2561" />
              <Tab className="fs-4" label="2562" value="2562" />
              <Tab className="fs-4" label="2563" value="2563" />
              <Tab className="fs-4" label="2564" value="2564" />
              <Tab className="fs-4" label="2565" value="2565" />
              <Tab className="fs-4" label="2566" value="2566" />
              <Tab className="fs-4" label="2567" value="2567" />
            </Tabs>
          </Box>
          <Stack>
            <Typography>
              <div>
                {paginatedCalendarLists[currentCPage - 1]?.map(
                  (list, index) => list
                )}
              </div>
            </Typography>
            <Pagination
              count={pageCCount}
              page={currentCPage}
              onChange={handleCalendarChange}
              hidePrevButton
              hideNextButton
              shape="rounded"
              className="d-flex justify-content-end pt-4 pe-4"
              size="large"
              sx={{
                "& .Mui-selected": { bgcolor: "#cccccc !important" },
                "& button": { bgcolor: "#e6e6e6" },
                "& button:hover": { bgcolor: "lightgray" },
              }}
            ></Pagination>
          </Stack>
        </div>
      </section> */}
      {/*--------- บทวิเคราะห์หลักทรัพย์ ---------*/}
      {/* <section id="Analyst" className="container py-5">
        <div className="mx-auto" style={{ width: "90%" }}>
          <h2 className="fw-bold ">
            {lang === "th" ? `บทวิเคราะห์หลักทรัพย์ ` : `Analyst Report  `}
          </h2>
          <Stack>
            <Typography>
              <div>
                {paginatedAnalystLists[currentAPage - 1]?.map(
                  (list, index) => list
                )}
              </div>
            </Typography>
            <Pagination
              count={pageACount}
              page={currentAPage}
              onChange={handleAnalystChange}
              hidePrevButton
              hideNextButton
              shape="rounded"
              className="d-flex justify-content-end pt-4 pe-4"
              size="large"
              sx={{
                "& .Mui-selected": { bgcolor: "#cccccc !important" },
                "& button": { bgcolor: "#e6e6e6" },
                "& button:hover": { bgcolor: "lightgray" },
              }}
            ></Pagination>
          </Stack>
        </div>
      </section> */}
      {/*--------- ยังไม่เปิดใช้งาน ---------*/}
    </>
  );
}

export default Publication;
