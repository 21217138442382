import React from "react";

function ModalCard({ content, title, src, href, target }) {
  return (
    <>
      <a
        href={href}
        target={target}
        style={{ visibility: "hidden", display: "none" }}
        className="col-lg-4 xl-visible align-items-center gap-4 mt-3 py-3 px-4 border rounded-4 ModalCard"
      >
        <img className="logoImg1 " src={src} />
        <div className="d-flex flex-column gap-2">
          <h5 className="m-0 fw-bold">{title}</h5>
          <p className="m-0">{content}</p>
        </div>
      </a>

      <a href={href} target={target} className="col-lg-4 py-4 xl-invisible">
        <div
          className="d-flex flex-column align-items-center justify-content-center text-center gap-3 border rounded-4 p-4 ModalCard shadow"
          style={{ height: "210px", cursor: "pointer" }}
        >
          <img className="logoImg1 " src={src} />
          <div className="d-flex  flex-column gap-1">
            <h5 className="m-0 fw-bold">{title}</h5>
            <p className="m-0 text-secondary">{content}</p>
          </div>
        </div>
      </a>
    </>
  );
}

export default ModalCard;
