import { useState, useEffect } from "react";
import StrAPI from "../services/StrAPI";

export default function useStrAPIFinancialManagement() {
    const [LoadingFinancialManagement, setLoadingFinancialManagement] = useState(false);
    const [GetDatasFinancialManagement, setGetDatasFinancialManagement] = useState(null);
    const [getContact, setContactForm] = useState(null);
    const [Path, setPath] = useState(null);

    return {

        LoadingFinancialManagement,
        GetDatasFinancialManagement,
        Path,
        getContact,
        setPagesFinancialManagement: (payload) => {
            setGetDatasFinancialManagement(payload);
        },

        setLoadingFinancialManagement: (payload) => {
            setLoadingFinancialManagement(payload);
        },

        getStrAPIFinancialManagement: async (Path, lang, populate = '&populate=*') => {
            console.log(lang,populate);
            setLoadingFinancialManagement(true);
            let responseData = await StrAPI.getStrAPI(Path, lang, populate);

            if (responseData) {
                setGetDatasFinancialManagement(responseData);
                setTimeout(
                    function () {
                        setLoadingFinancialManagement(false);
                    }.bind(this),
                    1000
                );
            }
        },

        postForm: async (Path, json) => {
            setLoadingFinancialManagement(true);
            const responseData = await StrAPI.postAPI(Path, json);
            if (responseData) {
                setContactForm(responseData);
                setTimeout(
                    function () {
                        setLoadingFinancialManagement(false);
                    }.bind(this),
                    250
                );
            }
        }
    }
}