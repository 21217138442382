import React from "react";
import { baseUrl } from "../../services/Environment";
import { Renderers } from "../../components";
import { useTranslation } from "react-i18next";

const About = ({ pagesAbout }) => {
  const { t, i18n } = useTranslation();
  const aboutData = pagesAbout.data.attributes.about[0];
  return (
    <>
      <div className="section py-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-2 mb-4 mb-lg-0">
              <img
                src={baseUrl + aboutData.images.data.attributes.url}
                alt={aboutData.images.data.attributes.alternativeText}
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 pe-lg-5 my-3">
              <div className="mb-5">
                <h1>
                  <span className="text-under-title fw-bold">
                    {aboutData.title.split(" ")[0]}
                  </span>{" "}
                  <span className="fw-bold">
                    {aboutData.title.split(" ")[1]}
                  </span>
                </h1>
              </div>
              <div className="d-flex mb-3 service-alt">
                {/* <h5> */}
                <Renderers markdownContent={aboutData.details}></Renderers>
                {/* </h5> */}
              </div>
              <div
                className="align-items-center mb-5 mm aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <a
                  href="/aboutus"
                  className="btn me-4"
                  style={{ backgroundColor: "#e5e1e2", color: "#3e5d75" }}
                >
                  {t("str_readmore")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
