import React from "react";

function HeaderBanner({ id, title, content, colorH1, colorP, img }) {
  const ClassH1 = "display-1 fw-bold ";
  const ClassP = "";
  return (
    <header
      id={id}
      className="text-center"
      style={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // backgroundSize: "100%",
        backgroundPosition: "center",
      }}
    >
      <div
        className="container d-flex flex-column justify-content-center align-items-center w-50 BS"
        style={{ height: "700px" }}
      >
        <h1 className={colorH1 ? ClassH1 + " " + colorH1 : ClassH1}>{title}</h1>
        <p className={colorP ? ClassP + " " + colorP : ClassP}>{content}</p>
      </div>
    </header>
  );
}

export default HeaderBanner;