import { Opacity } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function ListNews({ src, content, date, subDate, title, slug }) {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  function hoverClasses(hover = false) {
    let classes = "listNews";
    if (hover == true) {
      classes = "list_animate";
    }
    return classes;
  }

  function textHover(hover = false) {
    let classes = "line_3 text-white";
    if (hover === true) {
      classes += "";
    }
    return classes;
  }

  return title === "nodata" ? (
    <>
      <div className="col-xxl-4 col-lg-6 col-12 p-4">
        <div className=" ratio ratio-1x1">
          <div className={`w-100 p-3 bg-white`} id="BgBanner">
            <div
              className="p-3 d-flex flex-column h-100 newsCard"
              style={{ cursor: "pointer" }}
            >
              <div class="d-flex justify-content-center">
                <div class="spinner-grow m-5" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="col-xxl-4 col-lg-6 col-12 p-4 ">
        <div className="ratio ratio-1x1">
          {/* <a href={`/news-detail/${slug}`}> */}
          <a
            style={{ backgroundImage: `url( ${src} )` }}
            className={`w-100 p-3 bg-white  ${hoverClasses(isHover)}`}
            id="BgBanner"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            href={`/news-detail/${slug}`}
            target="_blank"
          >
            <div
              className="p-3 d-flex flex-column h-100 newsCard"
              style={{ cursor: "pointer" }}
            >
              <div className="mobile_flex">
                <h1
                  className={`text-secondary position-relative display-4 fw-bold  ${textHover(
                    isHover
                  )}`}
                >
                  {date}
                </h1>
                <h6
                  className={`text-secondary fw-bold position-relative ${textHover(
                    isHover
                  )}`}
                >
                  {subDate}
                </h6>
              </div>

              <div className="mt-auto">
                <h6
                  className={`text-brown fw-bold position-relative  ${textHover(
                    isHover
                  )}`}
                >
                  ข่าวสาร และกิจกรรม
                </h6>
                <h3
                  className={`fw-bold position-relative  ${textHover(isHover)}`}
                >
                  {title}
                </h3>

                <p
                  className={`position-relative ${textHover(isHover)}`}
                  style={{ wordBreak: "break-word" }}
                >
                  {content}
                </p>
              </div>

              {/* <div
                className="d-flex gap-1 justify-content-end align-items-center btn cursor-pointer border-0"
                onClick={() => handleOnCheck()}
                >
                <p className="text-end text-brown m-0">Read more</p>
                <i class="fa-solid fa-play" style={{ color: "#a77662" }}></i>
                </div> */}
            </div>
          </a>
        </div>
      </div>
    </>
  );
}

export default ListNews;
