import React, { useEffect, useRef, useState } from "react";

function SustainableItem({ title, content, src, LorR }) {
  const [hasAnimated, setHasAnimated] = useState(false);
  const rowRef = useRef(null);
  const A = LorR === "left" ? true : false;

  useEffect(() => {
    const rowObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasAnimated) {
            // เมื่อองค์ประกอบอยู่ในมุมมอง
            rowRef.current.classList.add("animate"); // เพิ่มคลาส animate เพื่อเริ่ม animation
            setHasAnimated(true);
          }
        });
      },
      { threshold: 0.5 } // เป็นค่าเปอร์เซ็นต์ของการมองเห็นที่ต้องการสำหรับการเริ่มต้น animation
    );

    if (rowRef.current) {
      rowObserver.observe(rowRef.current);
    }

    return () => {
      if (rowRef.current) {
        rowObserver.unobserve(rowRef.current);
      }
    };
  }, [hasAnimated]);
  return (
    <>
      {A ? (
        <>
          <div
            className="row xl-w100 mobile-textCenter mobile_mx0"
            style={{ width: "80%" }}
            ref={rowRef}
          >
            <div className="d-flex justify-content-center">
              <img
                className="logoImg4 movingRBox mobile-visible"
                src={src}
                style={{ zIndex: "1", visibility: "hidden", display: "none" }}
              />
            </div>
            <div className="col-sm-3">
              <img
                className="logoImg4 movingLBox mobile-invisible"
                src={src}
                style={{ zIndex: "1" }}
              />
            </div>
            <div
              className="col-sm-9 my-auto movingRBox"
              style={{ zIndex: "-1" }}
            >
              <p className="fw-bold m-0">{title}</p>
              <p>{content}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="row ms-auto xl-w100 mobile-my12 mobile_mx0"
            style={{ width: "80%" }}
            ref={rowRef}
          >
            <div className="d-flex justify-content-center">
              <img
                className=" logoImg4  movingLBox mobile-visible"
                src={src}
                style={{ visibility: "hidden", display: "none" }}
              />
            </div>
            <div className="col-sm-9 my-auto movingLBox">
              <p className="fw-bold m-0 text-end mobile-textCenter">{title}</p>
              <p className="text-end mobile-textCenter">{content}</p>
            </div>
            <div className="col-sm-3">
              <div className="d-flex justify-content-center">
                <img
                  className="logoImg4  movingLBox mobile-invisible"
                  src={src}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SustainableItem;
