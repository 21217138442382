import React from "react";

function Banner({ id, title, content, colorH1, colorP, img, imgMobile }) {
  const ClassH1 = "display-1 fw-bold ";
  const ClassP = "";
  return (
    <>
      <header
        id={id}
        className="d-sm-flex d-none text-center"
        style={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="container d-flex flex-column justify-content-center align-items-center w-50 BS"
          style={{ height: "clamp(450px,30vw,600px)" }}
        >
          <h1 className={colorH1 ? ClassH1 + " " + colorH1 : ClassH1}>
            {title}
          </h1>
          <p className={colorP ? ClassP + " " + colorP : ClassP}>{content}</p>
        </div>
      </header>
      <header
        id={id}
        className="text-center d-sm-none"
        style={{
          backgroundImage: `url(${imgMobile})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="container d-flex flex-column justify-content-center align-items-center w-50 BS"
          style={{ height: "220px" }}
        >
          <h1 className={colorH1 ? ClassH1 + " " + colorH1 : ClassH1}>
            {title}
          </h1>
          <p className={colorP ? ClassP + " " + colorP : ClassP}>{content}</p>
        </div>
      </header>
    </>
  );
}

export default Banner;
