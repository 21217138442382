import { useState, useEffect } from "react";
import StrAPI from "../services/StrAPIPage";

export default function useStrAPIPage() {
    const [loadingPage, setLoadingPage] = useState(false);
    const [PageAPI, setPageAPI] = useState(null);
    const [Path, setPath] = useState(null);
    return {

        loadingPage,
        PageAPI,
        Path,
        setPages: (payload) => {
            setPageAPI(payload)
        },

        setLoading: (payload) => {
            setLoadingPage(payload);
        },
        getAPIPage: async (Path, lang, populate = '&populate=*') => {
            // console.log(populate);
            setLoadingPage(true);
            let responseData = await StrAPI.getStrAPIPage(Path, lang, populate);

            if (responseData) {
                setPageAPI(responseData);
                setTimeout(
                    function () {
                        setLoadingPage(false);
                    }.bind(this),
                    1000
                );
            }
        },
        // getExecutiveById: async (payload) => {
        //     setLoading(true);
        //     const responseData = await PageService.getExecutiveById(payload);
        //     if (responseData) {
        //         setExecutive(responseData);
        //         setTimeout(
        //             function () {
        //                 setLoading(false);
        //             }.bind(this),
        //             250
        //         );
        //     }
        // }
    }
}