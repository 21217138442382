import React from "react";

function BannerDetail({ src }) {
  return (
    <div className="d-flex justify-content-center w-100">
      <img
        id="BgBanner"
        className="mx-auto mt-5"
        style={{
          width: "clamp(350px,55vw,750px)",
        }}
        src={src}
      />
    </div>
  );
}

export default BannerDetail;
