import React from "react";

function BoardList({ name, position, src }) {
  return (
    <div className="row d-flex align-items-center justify-content-center my-4">
      <div className="col-lg-6 d-flex xl-col align-items-center">
        <img src={src} style={{ height: "150px" }} />
        <div className="col-1"></div>
        <h4 className="fw-bold">{name}</h4>
        <h4 className="col-lg-5 xl-visible" style={{ display: "none" }}>
          {position}
        </h4>
      </div>
      <h4 className="col-lg-5 xl-invisible visible">{position}</h4>
    </div>
  );
}

export default BoardList;
