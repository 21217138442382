import React from "react";

function Social({ lang }) {
  return (
    <>
      <section className="section container">
        {lang === "th" ? ` ` : ` `}
        <h1 className="fw-bold title-line">
          {lang === "th"
            ? `กิจกรรมเพื่อประโยชน์ต่อสังคมและสิ่งแวดล้อม (After Process)
 `
            : `Social and Environmental Activities (After Process)
 `}
        </h1>
        <p className="fw-bold mt-4 mb-1">
          {lang === "th"
            ? `กิจกรรมเพื่อประโยชน์ต่อสังคมและสิ่งแวดล้อม (After Process)`
            : `Social and Environmental Activities (After Process)`}
        </p>

        <p>
          {lang === "th"
            ? `บริษัทฯ ส่งเสริมและสนับสนุนการทำกิจกรรมเพื่อประโยชน์ต่อสังคมและสิ่งแวดล้อมอย่างต่อเนื่อง ในทุก ๆ ด้าน เช่น การสนับสนุนทางการด้านศึกษาและปลูกฝังคุณธรรมให้แก่เยาวชน ซึ่งจะเติบโตเป็นกำลังสำคัญของประเทศในอนาคต การสนับสนุนในทางศาสนา เพื่อดำรงซึ่งคุณธรรม จริยธรรมในสังคมไทย รวมไปถึงด้านสุขภาพชีวอนามัย เป็นต้น`
            : `The Company continuously promotes and supports social and environmental activities. In particular, supporting for education and cultivating virtue for the youth, which will grow into a major force in the country in the future, Religious Support to maintain virtue Ethics in Thai society Including health, hygiene, etc.`}
        </p>
      </section>

      <section className="bg-Lgray section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 my-auto">
              <h5 className="fw-bold pb-3">
                {lang === "th"
                  ? `การบริจาคของใช้ เพื่อบรรเทาความเดือดร้อนในช่วงสถานการณ์การแพร่ระบาดของโรคติดเชื้อไวรัสโคโรนา 2019`
                  : `Donation to relieve the suffering during the epidemic of the coronavirus disease starting in 2019 (COVID-19) `}
              </h5>
              <p>
                {lang === "th"
                  ? ` จากสถานการณ์การแพร่ระบาดของโรคติดเชื้อไวรัสโคโรนา 2019 บริษัทฯ จึงขอร่วมเป็นส่วนหนึ่งของสังคมโดยได้ร่วมมือกับพันธมิตรทางธุรกิจ ร่วมบริจาคอุปกรณ์การแพทย์ และสิ่งของจำเป็น เพื่อช่วยแบ่งเบาและเป็นกำลังใจให้แก่สังคม `
                  : ` Due to the spread of the coronavirus disease starting in 2019 (COVID-19), the Company would like to be a part of the society by joining with business partners to donate medical equipment necessities to encourage the suffering in the society.`}
              </p>
              <p>
                {lang === "th"
                  ? `นอกจากนี้ สืบเนื่องจากสถานการณ์การแพร่ระบาดของโรคติดเชื้อไวรัสโคโรนา 2019 ในช่วงปี 2564 จนถึงช่วงกลางปี 2565 บริษัทฯ ได้เล็งเห็นถึงความสำคัญด้านสุขภาพและสุขอนามัยของผู้ป่วยโรคติดเชื้อไวรัสโคโรนา 2019 จึงร่วมส่งเสริมโครงการ Community Isolation กับ บริษัท รุ่งตะวันเนอร์สซิ่งแคร์ จำกัด เพื่อดูแลช่วยเหลือผู้ป่วยโรคติดเชื้อไวรัสโคโรนา 2019 โดยสร้างห้องแรงดันลบตามมาตรฐาน เพื่อป้องกันการแพร่กระจายพื้นที่ใกล้เคียง ซึ่งไม่มีการคิดค่าใช้จ่ายในการรักษาพยาบาลกับผู้เข้ารับการรักษา มาจนถึงช่วงที่กระทรวงสาธารณสุขประกาศยกเลิกโรคติดเชื้อไวรัสโคโรนา 2019 จากการเป็นโรคติดต่ออันตราย
 `
                  : `Due to the epidemic of the coronavirus disease starting in 2019 (COVID-19), in 2021 until the middle of 2022 The Company recognizes the importance of health and COVID-19 patients’ health, so the Company and Rung Tawan Nursing Care Co. Ltd. jointly set up and establish a community isolation project to provide assistance to COVID-19 patients by constructing a standard negative-pressure room in order to prevent spreading virus nearby areas. This project does not charge any expense to the COVID-19 patients until the Ministry of Public Health announced the cancellation of the coronavirus disease starting in 2019 (COVID-19) from being a dangerous contagious disease. `}
              </p>
            </div>
            <div className="col-lg-4 ">
              <div className="d-flex justify-content-center">
                <img
                  src="/images/sustainable/CSR1.jpg"
                  style={{ height: "350px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container section">
        <p>
          {lang === "th"
            ? ` บริษัทฯ ได้ ส่งเสริมและสนับสนุนด้านการศึกษา ซึ่งถือว่าเป็นรากฐานสำคัญในการพัฒนาประเทศ หากรากฐานดี มั่นคงและแข็งแรงก็จะนำพาไปสู่การพัฒนาประเทศชาติในอนาคต เมื่อวันที่ 7 ธันวาคม 2562 บริษัทฯ โดยนายกิตติ พัวถาวรสกุล ประธานเจ้าหน้าที่บริหารบริษัท ร่วมกับคณะกรรมการมูลนิธิทุนแพทย์เพื่อปวงประชา นำโดยนายสุรชัย สุทธิวรชัย ประธานมูลนิธิฯ ที่ตระหนักว่าผู้ที่เข้าศึกษาวิชาแพทย์ต้องมีความพร้อมทั้งสติปัญญาและทุนการศึกษา จึงได้มอบทุนการศึกษาแก่นิสิตแพทย์ที่มีผลการเรียนดี ความประพฤติดี แต่ขาดแคลนทุนทรัพย์ คนละ 60,000 บาทต่อปีตลอดการศึกษา ทั้งนี้เพื่อให้สำเร็จการศึกษาเป็นแพทย์ที่มีคุณค่า คุณธรรมพร้อมให้การดูแลสุขภาพของประชาชนและมีความสำนึกรักบ้านเกิดต่อไป
          `
            : ` The Company has promoted and supported education which is considered as an important foundation for nation development, if the foundation is good, stable and strong, it will lead to national development in the future. On December 7,2019, the Company by Mr. Kitti Phuathavornskul, the President in collaboration with the Board of Directors of the Medical Student Scholarships Foundation for Benefits of Mankind led by Mr. Surachai Sutthiworachai, President of the Foundation who realize those who study about medicine must have the intelligence and scholarships. Therefore, he has offered the scholarships to medical students who has good grades, good behavior but lacking funds as of amount 60,000 baht per person per year throughout the study. In order to graduate as a valuable physician, morality and ready to take care of the health of people and have a sense of love for the homeland.
          `}
        </p>
        <div className="row">
          <div className="col-lg-4 col-md-6 h-100 mt-4 ">
            <div className="d-flex justify-content-center">
              <img
                src="/images/sustainable/CSR3.png"
                style={{ width: "clamp(300px,30vw, 400px)" }}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 h-100 mt-4 ">
            <div className="d-flex justify-content-center">
              <img
                src="/images/sustainable/CSR4.png"
                style={{ width: "clamp(300px,30vw, 400px)" }}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 h-100 mt-4 ">
            <div className="d-flex justify-content-center">
              <img
                src="/images/sustainable/CSR5.png"
                style={{ width: "clamp(300px,30vw, 400px)" }}
              />
            </div>
          </div>
        </div>

        <p className="mt-4">
          {lang === "th"
            ? `บริษัทฯ โดยตัวแทนผู้บริหาร นางสาวเนติรัด สังข์งาม รองประธานเจ้าหน้าที่บริหารบริษัทฯ ร่วมกับตัวแทนผู้เข้าอบรมหลักสูตรนักบริหารระดับสูงธรรมศาสตร์เพื่อสังคมรุ่น 10 (นมธ.10) กลุ่มสีแดง และ นมธ.11 ได้ร่วมสนับสนุนเพื่อซ่อมแซมโรงเรียนบ้านบุ่งเบ้าห้วยเจริญ อำเภอเสลภูมิ จังหวัดร้อยเอ็ด เพื่อปรับปรุงอาคาร 3 ที่ถูกน้ำท่วมในรอบปีที่ผ่าน ได้แก่อาคารเรียน อาคารอเนกประสงค์ และห้องน้ำ พร้อมทั้งแจกชุดนักเรียนอนุบาล และ ชุดนักเรียนประถม แก่นักเรียนด้วย ในช่วงวันที่ 30 สิงหาคม – 8 กันยายน 2562 ที่ผ่านมา `
            : ` The Company by the representatives of the Company Executive; Miss Netirad Sang-ngam, Deputy Chief Executive Officer of the Company together with the representatives of the Thammasat Executives Training Program, Class 10 (Nor Mhor Thor 11) have jointly supported to repair Ban Bung Bao Huai Charoen School, Selaphum District, Roi Et Province, to improve 3 buildings that was flooded in the past year which were the school building, multi-purpose building and bathroom, as well as hand out kindergarten and elementary school uniform to the students during August 30 – September 8, 2019.`}
        </p>
        <div className="row">
          <div className="col-md-6 col-12 ">
            <div className="d-flex justify-content-center ">
              <img
                src="/images/sustainable/CSR6.png"
                style={{ height: "clamp(200px,25vw, 450px)" }}
              />
              <img
                src="/images/sustainable/CSR7.png"
                style={{ height: "clamp(200px,25vw, 450px)" }}
              />
            </div>
            <p className="text-center mt-3">
              {lang === "th" ? `ภาพก่อนปรับปรุง` : `Before renovation`}
            </p>
          </div>
          <div className="col-md-6 col-12 ">
            <div className="d-flex justify-content-center">
              <img
                src="/images/sustainable/CSR8.png"
                style={{ height: "clamp(200px,25vw, 450px)" }}
              />
              <img
                src="/images/sustainable/CSR9.png"
                style={{ height: "clamp(200px,25vw, 450px)" }}
              />
            </div>
            <p className="text-center mt-3">
              {lang === "th" ? `ภาพหลังปรับปรุง` : `After renovation`}
            </p>
          </div>
        </div>
      </section>
      {lang === "th" ? ` ` : ` `}

      <section className="section bg-Lgray">
        <div className="container">
          <p>
            {lang === "th"
              ? `บริษัทฯ ได้ส่งตัวแทนเข้าร่วมการแข่งขันกอล์ฟการกุศล ในโอกาสครบรอบ 20 ปี ตลาดหลักทรัพย์ เอ็ม เอ ไอ (MAI) เมื่อวันที่ 11 ธันวาคม 2562 ณ สนามอัลไพน์ กอล์ฟ แอนด์สปอต คลับ โดยรายได้หลังหักค่าใช้จ่ายจะนำไปบริจาคเพื่อสาธารณะกุศล`
              : ` The Company had sent the representatives to participate in the charity golf tournament. On the occasion of the 20th anniversary on December 11,2019 at the Alpine Golf and Sport Club, income after deducting expenses will be donated to public charities.`}
          </p>
          <div className="row">
            <div className="col-xxl-3 col-lg-4 col-sm-6 h-100 mt-4 ">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src="/images/sustainable/CSR10.png"
                  style={{ height: "clamp(220px,15vw, 240px)" }}
                />
              </div>
            </div>
            <div className="col-xxl-3 col-lg-4 col-sm-6 h-100 mt-4 ">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src="/images/sustainable/CSR11.png"
                  style={{ height: "clamp(220px,15vw, 240px)" }}
                />
              </div>
            </div>
            <div className="col-xxl-3 col-lg-4 col-sm-6 h-100 mt-4 ">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src="/images/sustainable/CSR12.jpg"
                  style={{ height: "clamp(220px,15vw, 240px)" }}
                />
              </div>
            </div>
            <div className="col-xxl-3 col-lg-4 col-sm-6 h-100 mt-4 ">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src="/images/sustainable/CSR13.jpg"
                  style={{ height: "clamp(220px,15vw, 240px)" }}
                />
              </div>
            </div>
          </div>

          <p className="mt-4">
            {lang === "th"
              ? `และเมื่อวันที่ 16 ตุลาคม 2562 ได้ร่วมกิจกรรมแข่งขันกอล์ฟการกุศล ณ สนามยูนิแลนด์ กอล์ฟ แอนด์ คันทรี่คลับอำเภอเมือง จังหวัดนครปฐม มีวัตถุประสงค์เพื่อมอบทุนการศึกษา ให้กับนักศึกษามหาวิทยาลัยราชภัฏนครปฐม ตลอดจนใช้ในการจัดกิจกรรม ของคณะกรรมการส่งเสริมกิจการมหาวิทยาลัย
 `
              : ` And on October 16,2019, had participated in a charity golf tournament at Uniland Golf and Country Club, Muang District, Nakhon Pathom Province. The objective is to provide scholarships to the students of Nakhon Pathom Rajabhat University as well as using in the activities of the University Affairs Board.
`}
          </p>
        </div>
      </section>
    </>
  );
}

export default Social;
