import React, { useEffect, useRef } from "react";
import "../../nav.css";
import TimeLineItem from "./TimeLineItem";

function TimeLine(item) {
  // console.log("data", data);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <>
      {/*------------ TimeLine ---------------*/}
      <section
        className="container  overflow-auto timeline"
        style={{
          height: "800px",
        }}
      >
        {item.data.map((item, index) => (
          <TimeLineItem
            title={item.title}
            subTitle={item.title_detail}
            content={item.details}
            key={index}
            row={index}
          />
        ))}
        {/* <TimeLineItem
            title={"2018"}
            subTitle={"asdrfgaertgaertgar"}
            content={`LOREM IPSUM GENERATOR Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.`}
          /> */}
      </section>
    </>
  );
}

export default TimeLine;
