import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import {
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  FormControl,
  Typography,
  Stack,
  OutlinedInput,
} from "@mui/material";
import NavBarItem from "../../../Navbar/NavBarItem";
import ViewPDF from "../../../ViewPDF/ViewPDF";
import HeaderBanner from "../../../HeaderCore/HeaderBanner";
import { baseUrl } from "../../../../services/Environment";
import useStrAPIFinancialStatement from "../../../../hooks/useStrAPIFinancialStatement";
import useStrAPIFinancialManagement from "../../../../hooks/useStrAPIFinancialManagement";
import useStrAPIFinancialHighlights from "../../../../hooks/useStrAPIFinancialHighlights";
// import DataTable from "datatables.net-dt";

// const Lists = [
//   <ViewPDF title={"งบการเงินรายปี 2566"} year="2566" />,
//   <ViewPDF title={"งบการเงินรายปี 2565"} year="2565" />,
//   <ViewPDF title={"งบการเงินรายปี 2565"} year="2565" />,
//   <ViewPDF title={"งบการเงินรายปี 2565"} year="2565" />,
//   <ViewPDF title={"งบการเงินรายปี 2564"} year="2564" />,
//   <ViewPDF title={"งบการเงินรายปี 2564"} year="2564" />,
//   <ViewPDF title={"งบการเงินรายปี 2563"} year="2563" />,
//   <ViewPDF title={"งบการเงินรายปี 2563"} year="2563" />,
//   <ViewPDF title={"งบการเงินรายปี 2563"} year="2563" />,
//   <ViewPDF title={"งบการเงินรายปี 2562"} year="2562" />,
//   <ViewPDF title={"งบการเงินรายปี 2562"} year="2562" />,
//   <ViewPDF title={"งบการเงินรายปี 2562"} year="2562" />,
//   <ViewPDF title={"งบการเงินรายปี 2561"} year="2561" />,
// ];

const StatementLists01 = [
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2566"} value="2566" />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2566"} value={2566} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2565"} value="2565" />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2565"} value={2565} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2565"} value={2565} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2565"} value={2565} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2564"} value={2564} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2563"} value={2563} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2563"} value={2563} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2563"} value={2563} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2563"} value={2563} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2562"} value={2562} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2561"} value={2561} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2561"} value={2561} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2561"} value={2561} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2561"} value={2561} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2560"} value={2560} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2560"} value={2560} />,
  <ViewPDF title={"การวิเคราะห์ และคำอธิบายชองฝ่ายจัดการ 2560"} value={2560} />,
];

function Financial({ lang }) {
  const [currentSPage, setCurrentSPage] = useState(1);
  const [filterItems, setFilterItems] = useState([]);
  const [fhitems, setFinancialHighlights] = useState([]);
  const [statement_lists, setStatementLists] = useState([
    <ViewPDF title={"--------------"} value="-------" />,
  ]);
  const [age, setAge] = useState(
    lang === "en"
      ? moment().format("YYYY")
      : moment().add(543, `year`).format("YYYY")
  );
  const { GetDatas, loading, getStrAPI } = useStrAPIFinancialStatement();
  const {
    GetDatasFinancialManagement,
    LoadingFinancialManagement,
    getStrAPIFinancialManagement,
  } = useStrAPIFinancialManagement();

  const {
    GetDatasFinancialHighlights,
    LoadingFinancialHighlights,
    getStrAPIFinancialHighlights,
  } = useStrAPIFinancialHighlights();

  const [YearNow, setYearNow] = useState(
    lang === "en"
      ? moment().format("YYYY")
      : moment().add(543, `year`).format("YYYY")
  );

  useEffect(() => {
    if (lang !== undefined) {
      getStrAPI(
        "financial-statements",
        lang,
        "&populate[statements][populate]=*"
      );
      getStrAPIFinancialManagement(
        "financial-managements",
        lang,
        "&populate[file_management][populate]=*"
      );
      getStrAPIFinancialHighlights(
        "financial-highlights",
        lang,
        "&sort[0]=year:DESC&populate=*&pagination[page]=1&pagination[pageSize]=3"
      );
      setAge(
        lang === "en"
          ? moment().format("YYYY")
          : moment().add(543, `year`).format("YYYY")
      );
      setYearNow(
        lang === "en"
          ? moment().format("YYYY")
          : moment().add(543, `year`).format("YYYY")
      );
    }
  }, [lang]);

  useEffect(() => {
    //  console.log('loading',loading)

    if (filterItems) {
      // console.log('YearNow',YearNow)
      const yearData = GetDatas?.data.find(
        (item) => item.attributes.year === `${YearNow}`
      );

      if (yearData) {
        const renderStatements = (statements) => {
          return (
            statements.map((statement) => (
              <ViewPDF
                // key={statement.id} // Use a unique key for each component
                title={statement.name} // Use statement name or other appropriate field
                year={yearData.attributes.year} // Assuming you want to use the year from yearData
                href={
                  statement.file.data?.attributes.url == undefined
                    ? "/404"
                    : baseUrl + statement.file.data?.attributes.url
                }
              />
            )) || [<ViewPDF title="----" year="----" />]
          );
        };
        const renderedStatements = renderStatements(
          yearData.attributes.statements
        );
        setFilterItems(renderedStatements);
      } else {
        // console.log("YearNow", yearData);
        setFilterItems([<ViewPDF title="----" year="----" />]);
      }
    }
  }, [GetDatas, loading]);

  useEffect(() => {
    if (GetDatasFinancialManagement) {
      //  console.log('statement_lists',statement_lists)
      // console.log('YearNow',YearNow)
      // const yearData = GetDatasFinancialManagement?.data.find(item => item.attributes.year === `${YearNow}`);

      // if(yearData){
      const renderStatements = (managements) => {
        return (
          managements.attributes.file_management.map((management) => (
            <ViewPDF
              // key={statement.id} // Use a unique key for each component
              title={management.name} // Use statement name or other appropriate field
              // year={management.attributes.file_management.name} // Assuming you want to use the year from yearData
              // href={baseUrl+management.file.data?.attributes.url}
              href={
                management.file.data?.attributes.url == undefined
                  ? "/404"
                  : baseUrl + management.file.data?.attributes.url
              }
              //  value="-------"
            />
          )) || [<ViewPDF title={"--------------"} value="-------" />]
        );
      };
      // console.log('.........645646',GetDatasFinancialManagement)
      const renderedStatements = renderStatements(
        GetDatasFinancialManagement?.data[0]
      );
      setStatementLists([renderedStatements]);
    } else {
      //  console.log('YearNow',yearData)
      setStatementLists([<ViewPDF title={"--------------"} value="-------" />]);
    }

    // }
  }, [GetDatasFinancialManagement, LoadingFinancialManagement]);

  useEffect(() => {
    if (GetDatasFinancialHighlights) {
      console.log(
        "GetDatasFinancialHighlights",
        GetDatasFinancialHighlights.data
      );
      setFinancialHighlights(GetDatasFinancialHighlights.data);
    }
  }, [GetDatasFinancialHighlights, LoadingFinancialHighlights]);

  // console.log('statement_lists',statement_lists)
  function scrollToElement(id) {
    let offset = 80; // ระยะ offset ที่ต้องการ
    const element = document.getElementById(id);
    const offsetTop = element.offsetTop - offset; // คำนวณระยะ offset ที่ต้องการเลื่อน

    window.scrollTo({
      top: offsetTop,
      behavior: "smooth", // เลื่อนโดยการ animate ให้เรียบร้อย
    });
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  const handleChange = (event, value) => {
    setCurrentSPage(value);
    scrollToElement("Management");
  };

  const itemsPerPage = 8;
  const pageCount = Math.ceil(statement_lists.length / itemsPerPage);
  const paginatedStatementLists = Array.from(
    { length: pageCount },
    (_, index) => {
      const startIndex = index * itemsPerPage;
      return statement_lists.slice(startIndex, startIndex + itemsPerPage);
    }
  );

  const changePDFView = (selectedYear) => {
    // หา ViewPDF ที่ตรงกับปีที่เลือก
    // console.log('GetDatas',selectedYear)
    //  console.log('GetDatas',GetDatas.data)
    // const selectedPDFs = GetDatas.data.filter((pdf) => pdf.attributes.year == selectedYear);
    let yearData = GetDatas.data.find(
      (item) => item.attributes.year === `${selectedYear}`
    );

    // console.log("selectedPDFs", yearData);
    // if (yearData) {
    //   // Return statements for the found year
    //   console.log("selectedPDFs", yearData.attributes.statements);
    //   // return yearData.attributes.statements;
    // } else {
    //   return [];
    // }

    // แสดง ViewPDF ที่เลือก หรือจะทำอะไรก็ตามที่ต้องการ
    // <ViewPDF title={"งบการเงินรายปี 2566"} year="2566" />
    if (yearData) {
      const renderStatements = (statements) => {
        return statements.map((statement) => (
          <ViewPDF
            key={statement.id} // Use a unique key for each component
            title={statement.name} // Use statement name or other appropriate field
            year={yearData.attributes.year} // Assuming you want to use the year from yearData
            // href={baseUrl+statement.file.data?.attributes.url}
            href={
              statement.file.data?.attributes.url == undefined
                ? "/404"
                : baseUrl + statement.file.data?.attributes.url
            }
          />
        ));
      };

      // Example usage: Render ViewPDF components for yearData
      const renderedStatements = renderStatements(
        yearData.attributes.statements
      );

      setFilterItems(renderedStatements);
    } else {
      setFilterItems([<ViewPDF title="----" year="----" />]);
    }

    // console.log("selectedPDFs", selectedPDFs);
  };

  const changeYear = (e, newValue) => {
    setAge(e.target.value);
    changePDFView(e.target.value);
  };
  // console.log("filterItems", filterItems);

  const currentYear = moment().format("YYYY"); // Get current year
  // const startYear = 2566; // Starting year
  const years = [];

  // Determine the language condition
  // const lang = "en"; // Example: Replace with your actual language condition

  // Determine the language condition
  // const lang = "en"; // Example: Replace with your actual language condition

  for (let year = currentYear; year >= currentYear - 10; year--) {
    // Adjust year based on language
    const displayYear = lang === "en" ? year : parseInt(year, 10) + 543;

    years.push(
      <MenuItem
        value={displayYear}
        selected={
          lang == "en"
            ? currentYear == displayYear
              ? true
              : false
            : parseInt(currentYear, 10) + 543 == displayYear
            ? true
            : false
        }
      >
        {displayYear}
      </MenuItem>
    );
  }

  console.log(years);
  return (
    <>
      <nav className="mt-3 mobile_m0 d-flex  py-3 gap-4 align-items-center xl-col justify-content-center">
        <div className="d-flex gap-3">
          <NavBarItem id={"Highlights"}>
            {lang === "th" ? `ข้อมูลทางการเงิน` : `Financial Highlights`}
          </NavBarItem>
          <NavBarItem id={"Statement"}>
            {lang === "th" ? `งบการเงิน ` : `Financial Statement `}
          </NavBarItem>
        </div>
      </nav>

      {/*--------- ข้อมูลทางการเงิน -------*/}
      <section id="Highlights" className="container pt-5 mobile_p">
        <h1 className="title-line fw-bold">
          {lang === "th" ? "ข้อมูลทางการเงิน" : "Financial Highlights"}
        </h1>

        <div
          className="d-flex justify-content-between mobile_column mt-4 mx-auto"
          style={{ width: "95%" }}
        >
          <h3 className="fw-bold">
            {" "}
            {lang === "th" ? "ข้อมูลทางการเงิน" : "Financial Highlights"}
          </h3>
          <p style={{ paddingRight: "clamp(10px,5vw,40px)" }}>
            {lang === "th" ? `หน่วย : ล้านบาท` : `Unit: Million Bath`}
          </p>
        </div>

        <div className="mx-auto " style={{ overflowX: "auto", width: "95%" }}>
          {fhitems.length != 0 ? (
            // <>
            <table className="financial-table">
              <tr>
                <td className="headcol w-100"></td>
                <td
                  colspan="3"
                  className="text-center"
                  style={{ textWrap: "wrap", maxWidth: "300px" }}
                >
                  {lang === "th"
                    ? `งบการเงินรวม`
                    : `The Consolidated Financial Statement`}
                </td>
                <td
                  colspan="3"
                  className="text-center"
                  style={{ textWrap: "wrap", maxWidth: "300px" }}
                >
                  {lang === "th"
                    ? "งบเฉพาะกิจการ"
                    : "The Company Financial Statement"}
                </td>
              </tr>
              <tr>
                <td className="headcol w-100"></td>
                <td>{fhitems[2].attributes.year}</td>
                <td>{fhitems[1].attributes.year}</td>
                <td>{fhitems[0].attributes.year}</td>

                <td>{fhitems[2].attributes.year}</td>
                <td>{fhitems[1].attributes.year}</td>
                <td>{fhitems[0].attributes.year}</td>
              </tr>
              <tr className="bg-blue">
                <td colspan="4" className="headtable bg-blue text-white w-100">
                  {lang === "th"
                    ? "งบกำไรขาดทุนเบ็ดเสร็จ"
                    : "Statement of comprehensive income"}
                </td>
                <td colSpan="3" className="bg-blue"></td>
              </tr>
              <tr>
                <td className="headcol w-100">
                  {lang === "th"
                    ? "รายได้จากการให้บริการและการขาย"
                    : "Sales and service income"}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Sales_and_service_income
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Sales_and_service_income
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Sales_and_service_income
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Sales_and_service_income
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Sales_and_service_income
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Sales_and_service_income
                  }
                </td>
              </tr>

              <tr>
                <td className="headcol">
                  {lang === "th" ? "กำไร(ขาดทุน)สุทธิ" : "Net Profit"}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0].Net_Profit
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0].Net_Profit
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0].Net_Profit
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Net_Profit
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Net_Profit
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Net_Profit
                  }
                </td>
              </tr>
              {/* </table> */}
              {/* </div> */}

              {/* <div className="mx-auto" style={{ overflowX: "auto", width: "95%" }}> */}
              {/* <table className="financial-table"> */}
              <tr className="bg-blue">
                <td colspan="4" className="headtable w-100 text-white">
                  {lang === "th"
                    ? "งบแสดงฐานะทางการเงิน"
                    : "Statement of financial position"}
                </td>
                <td className="headtable" colspan="3"></td>
              </tr>

              <tr>
                <td className="headcol w-100">
                  {lang === "th" ? "รวมสินทรัพย์" : "Total Asset"}{" "}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0].Total_Asset
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0].Total_Asset
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0].Total_Asset
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Total_Asset
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Total_Asset
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Total_Asset
                  }
                </td>
              </tr>
              <tr>
                <td className="headcol ">
                  {lang === "th" ? "รวมหนี้สิน" : "Total Liability"}{" "}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0].Total_Liability
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0].Total_Liability
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0].Total_Liability
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Total_Liability
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Total_Liability
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Total_Liability
                  }
                </td>
              </tr>
              <tr>
                <td className="headcol ">
                  {lang === "th"
                    ? "รวมส่วนของผู้ถือหุ้น"
                    : "Total shareholders' equity"}{" "}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Total_shareholders_equity
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Total_shareholders_equity
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Total_shareholders_equity
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Total_shareholders_equity
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Total_shareholders_equity
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Total_shareholders_equity
                  }
                </td>
              </tr>
              {/* </table> */}
              {/* </div> */}
              {/* <div className="mx-auto" style={{ overflowX: "auto", width: "95%" }}> */}
              {/* <table className="financial-table"> */}
              <tr className="bg-blue">
                <td colspan="4" className="headtable w-100 text-white">
                  {lang === "th" ? "งบกระแสเงินสด" : "Cash Flow Statement"}
                </td>
                <td colSpan={3} className="headtable">
                  {" "}
                </td>
              </tr>
              <tr>
                <td className="headcol w-100">
                  {lang === "th"
                    ? "เงินสุทธิได้มาจากกิจกรรมดำเนินงาน"
                    : "Cash flows from operating activities"}{" "}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Cash_flows_from_operating_activities
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Cash_flows_from_operating_activities
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Cash_flows_from_operating_activities
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Cash_flows_from_operating_activities
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Cash_flows_from_operating_activities
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Cash_flows_from_operating_activities
                  }
                </td>
              </tr>
              <tr>
                <td className="headcol">
                  {lang === "th"
                    ? "เงินสดสุทธิได้มา(ใช้ไป)จากกิจกรรมลงทุน"
                    : "Cash flows from investing activities"}{" "}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Cash_flows_from_investing_activities
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Cash_flows_from_investing_activities
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Cash_flows_from_investing_activities
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Cash_flows_from_investing_activities
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Cash_flows_from_investing_activities
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Cash_flows_from_investing_activities
                  }
                </td>
              </tr>
              <tr>
                <td className="headcol">
                  {lang === "th"
                    ? "เงินสุทธิได้มา(ใช้ไป)จากกิจกรรมจัดหาเงิน"
                    : "Cash flows from financing activities"}{" "}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Cash_flows_from_financing_activities
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Cash_flows_from_financing_activities
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Cash_flows_from_financing_activities
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Cash_flows_from_financing_activities
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Cash_flows_from_financing_activities
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Cash_flows_from_financing_activities
                  }
                </td>
              </tr>
              {/* </table> */}
              {/* </div> */}

              {/* <div className="mx-auto" style={{ overflowX: "auto", width: "95%" }}> */}
              {/* <table className="financial-table"> */}
              <tr className="bg-blue">
                <td colspan="4" className="headtable w-100 text-white">
                  {lang === "th"
                    ? "อัตราส่วนทางการเงินที่สำคัญ"
                    : "Key Financial Ratio"}
                </td>
                <td colSpan={3} className="headtable"></td>
              </tr>

              <tr>
                <td className="headcol w-100">
                  {lang === "th"
                    ? "อัตรากำไรขั้นต้น (%)"
                    : "Gross profit margin (%)"}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Gross_profit_margin
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Gross_profit_margin
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Gross_profit_margin
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Gross_profit_margin
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Gross_profit_margin
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Gross_profit_margin
                  }
                </td>
              </tr>
              <tr>
                <td className="headcol">
                  {lang === "th"
                    ? "อัตรากำไร(ขาดทุน)สุทธิ  (%)"
                    : "Net profit margin  (%)"}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0].Net_profit_margin
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0].Net_profit_margin
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0].Net_profit_margin
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Net_profit_margin
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Net_profit_margin
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Net_profit_margin
                  }
                </td>
              </tr>
              <tr>
                <td className="headcol">
                  {lang === "th"
                    ? "อัตราผลตอบแทนจากสินทรัพย์  (%)"
                    : "Return on asset  (%)"}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0].Return_on_asset
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0].Return_on_asset
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0].Return_on_asset
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Return_on_asset
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Return_on_asset
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Return_on_asset
                  }
                </td>
              </tr>
              <tr>
                <td className="headcol">
                  {lang === "th"
                    ? "อัตราส่วนหนี้สินต่อส่วนของผู้ถือหุ้น (%)"
                    : "Debt to Equity ratio (%)"}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Debt_to_Equity_ratio
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Debt_to_Equity_ratio
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Debt_to_Equity_ratio
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Debt_to_Equity_ratio
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Debt_to_Equity_ratio
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Debt_to_Equity_ratio
                  }
                </td>
              </tr>
              <tr>
                <td className="headcol">
                  {lang === "th"
                    ? "อัตราผลตอบแทนผู้ถือหุ้น (%)"
                    : "Return on equity (%)"}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0].Return_on_equity
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0].Return_on_equity
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0].Return_on_equity
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Return_on_equity
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Return_on_equity
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Return_on_equity
                  }
                </td>
              </tr>
              <tr>
                <td className="headcol">
                  {lang === "th"
                    ? "อัตราการจ่ายเงินปันผล (%)"
                    : "Dividend payout (%)"}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0].Dividend_payout
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0].Dividend_payout
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0].Dividend_payout
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Dividend_payout
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Dividend_payout
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Dividend_payout
                  }
                </td>
              </tr>
              <tr>
                <td className="headcol">
                  {lang === "th"
                    ? "กำไร(ขาดทุน)ต่อหุ้นขั้นพื้นฐานส่วนที่เป็นของบริษัท (บาทต่อหุ้น)"
                    : "Basic earnings per share(unit: Baht)"}
                </td>
                <td>
                  {
                    fhitems[2].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Basic_earnings_per_share
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Basic_earnings_per_share
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes
                      .The_Consolidated_Financial_Statement[0]
                      .Basic_earnings_per_share
                  }
                </td>
                <td>
                  {
                    fhitems[2].attributes.The_Company_Financial_Statement[0]
                      .Basic_earnings_per_share
                  }
                </td>
                <td>
                  {
                    fhitems[1].attributes.The_Company_Financial_Statement[0]
                      .Basic_earnings_per_share
                  }
                </td>
                <td>
                  {
                    fhitems[0].attributes.The_Company_Financial_Statement[0]
                      .Basic_earnings_per_share
                  }
                </td>
              </tr>
            </table>
          ) : (
            // </>
            ""
          )}
        </div>
      </section>

      <HeaderBanner
        img="/images/banner/นักลงทุนสัมพันธ์งบการเงิน-27.png"
        imgMobile={"/images/banner/คั่นกลางนักลงทุนสัมพันธ์-02.jpg"}
      />

      {/*------- งบการเงิน ------*/}
      <section id="Statement" className="container pt-5 ">
        <div className="d-flex gap-4 mb-5">
          <h1 className="title-line ">
            {lang === "th" ? "งบการเงิน" : "Financial Statement"}
          </h1>

          <div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              {/* <InputLabel id="demo-simple-select-autowidth-label">
                Age
              </InputLabel> */}
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={age}
                onChange={changeYear}
                autoWidth
                MenuProps={MenuProps}
                className="text-center"
                style={{ fontSize: "20px" }}
                // label="Age"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {/* <MenuItem value={2560}>2560</MenuItem>
                <MenuItem value={2561}>2561</MenuItem>
                <MenuItem value={2562}>2562</MenuItem>
                <MenuItem value={2563}>2563</MenuItem>
                <MenuItem value={2564}>2564</MenuItem>
                <MenuItem value={2565}>2565</MenuItem>
                <MenuItem value={2566}>2566</MenuItem> */}

                {years}
                {/* {years.map((year) => (
                    <MenuItem key={year} value={year}>
                        {year}
                    </MenuItem>
                ))} */}
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          <h3 className="fw-bold mb-4">เอกสารการเงิน</h3>
          {filterItems.map((list, index) => list)}
          {/* <h3 className="fw-bold mb-4">
            {lang === "th" ? "เอกสารการเงิน" : "Financial Statement Documents"}
          </h3>
          {Lists.map((list, index) => list)} */}
        </div>

        {/*------- คำอธิบาย และการวิเคราะห์ของฝ่ายจัดการ --------*/}

        <Stack className="pb-5" spacing={2}>
          <div id="Management">
            <h3 className="fw-bold mt-5 mb-4">
              {lang === "th"
                ? "คำอธิบาย และการวิเคราะห์ของฝ่ายจัดการ"
                : "Management Discussion and Analysis"}
            </h3>

            <Typography>
              <div className="row d-flex justify-content-start">
                {paginatedStatementLists[currentSPage - 1].map(
                  (list, index) => list
                )}
              </div>
            </Typography>
          </div>
          <Pagination
            count={pageCount}
            page={currentSPage}
            hidePrevButton
            hideNextButton
            onChange={handleChange}
            className="d-flex justify-content-end pt-4 pe-4"
            sx={{
              "& .Mui-selected": { bgcolor: "#cccccc !important" },
              "& button": { bgcolor: "#e6e6e6 !important" },
              "& button:hover": { bgcolor: "lightgray !important" },
            }}
            shape="rounded"
            size="large"
          ></Pagination>
        </Stack>
      </section>
    </>
  );
}
export default Financial;
