import React, { Fragment, useState, useEffect } from "react";
import { baseUrl } from "../../services/Environment";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const HeaderOverlayTwo = ({ details, images, imagesFull, title, id }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    if (localStorage.getItem("ncl_lang")) {
      setLang(localStorage.getItem("ncl_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }
  }, [lang]);

  return (
    <>
      <Modal
        show={modalShow === id}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>
            <div href="/aboutus#business" style={{ color: "black" }}>
              {title}
            </div>
            {/* <a href="/aboutus#business" style={{ color: "black" }}>
              <i class="fas fa-external-link-alt mx-3"></i>
            </a> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {details}
          <img
            src={baseUrl + imagesFull.data.attributes.formats.large.url}
            alt={imagesFull.data.alternativeText}
            className="img-fluid card-img-sm-left mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <a href="/aboutus#business" style={{ color: "black" }}>
            <i class="fas fa-external-link-alt"></i>
          </a>
        </Modal.Footer>
      </Modal>

      <div className="card post-entry rounded-3 page-banner-card page-banner-position">
        <div
          className="card-body py-4"
          style={lang === "th" ? {} : { minHeight: "235px" }}
        >
          <div className="row">
            <div className="col-lg-3 col-md-4 col-3 ">
              <img
                src={baseUrl + images.data.attributes.url}
                alt={images.data.alternativeText}
                className="img-fluid card-img-sm-left"
              />
            </div>
            <div className="col-lg-9 col-md-8 col-9 ">
              <h4 className="card-title" style={{ fontWeight: "inherit" }}>
                <div className="text-black" href="/aboutus#business">
                  {title}
                </div>
              </h4>

              <p className="line-clamp-3">{details}</p>
              <p className="mb-0">
                <a
                  href="javascript:void(0)"
                  onClick={() => setModalShow(id)}
                  className="text-black"
                >
                  {t("str_readmore")}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right-square-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1" />
                  </svg>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderOverlayTwo;
