import React, { Fragment, useEffect, useRef, useState } from "react";
import { HeaderOverlay, MenuNavbar, Seo } from "../components";
import { useTranslation } from "react-i18next";
// import useGetPage from "../hooks/useGetPage";
import Footer from "../components/Footer/Footer";
import HeaderBannerOne from "../components/HeaderCore/HeaderBannerOne";
import TimeLineItem from "../components/TimeLines/TimeLineItem";
import ViewPDF from "../components/ViewPDF/ViewPDF";
import useStrAPI from "../hooks/useStrAPI";
import { baseUrl } from "./../services/Environment";
import SustainableItem from "../components/Sustainable/SustainableItem";
import MidLine from "../components/MidLine/MidLine";
import { useParams } from "react-router-dom";
import Development from "../components/Sustainable/MainPages/Development";
import Policy from "../components/Sustainable/MainPages/Policy";
import Operation from "../components/Sustainable/MainPages/Operation";
import Social from "../components/Sustainable/MainPages/Social";
import Report from "../components/Sustainable/MainPages/Report";
import { height } from "@mui/system";

function Sustainable() {
  const [lang, setLang] = useState();
  const { t, i18n } = useTranslation();
  const { GetDatas, loading, getStrAPI } = useStrAPI();

  let { mainpage } = useParams();

  useEffect(() => {
    if (localStorage.getItem("ncl_lang")) {
      setLang(localStorage.getItem("ncl_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }

    if (lang !== undefined) {
      getStrAPI("file-managements", lang);
    }
  }, [lang]);

  const itemsHref = window.location.hash;
  if (
    itemsHref !== "" &&
    itemsHref !== "#" &&
    itemsHref !== "itemsHref" &&
    itemsHref !== undefined &&
    itemsHref !== null
  ) {
    const element = document.querySelector(itemsHref);
    if (element) {
      const offset = 80;
      const offsetTop = element.offsetTop - offset;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth", // เลื่อนโดยการ animate ให้เรียบร้อย
      });
    }
  }

  const handleLang = (event, attrLang) => {
    let changeText = attrLang === "th" ? "en" : "th";
    localStorage.setItem("ncl_lang", changeText);
    setLang(localStorage.getItem("ncl_lang"));
    i18n.changeLanguage(lang);
  };

  // console.log(baseUrl+GetDatas?.data[0].attributes.file.data.attributes.url)
  return (
    <Fragment>
      <Seo
        title="Home"
        description="A description of the page"
        name="Company name"
        type="articlec"
        keywords="HTML, CSS, JavaScript"
      />

      <MenuNavbar>
        <div className="align-items-center icon-nav-custom">
          <img
            src="/images/icon/icon-12.png"
            alt={"NCL Language " + lang}
            // height="30"
            className="narbar_link img-icon-nav"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          />
          <span
            style={{ textTransform: "uppercase" }}
            className="pt-1 narbar_font"
            data-lang={lang}
            onClick={(event) =>
              handleLang(
                event.target,
                event.target.attributes.getNamedItem("data-lang").value
              )
            }
          >
            {lang}
          </span>
        </div>
      </MenuNavbar>

      {/* Header */}

      <video
        autoPlay
        muted
        loop
        style={{ width: "clamp(50%,100vw,100%)" }}
        className=" d-none d-lg-block"
      >
        <source
          // src="/images/LANY.mp4"
          src="/images/sustainable/Banner_NCL_CSR(1920x686)_final.mp4"
          type="video/mp4"
        ></source>
      </video>

      <video
        autoPlay
        muted
        loop
        style={{ width: "clamp(50%,100vw,100%)" }}
        className="d-lg-none d-block "
      >
        <source
          // src="/images/LANY.mp4"
          src="/images/sustainable/Banner_NCL_CSR_mobile(1080x1920)_final.mp4"
          type="video/mp4"
        ></source>
      </video>

      {mainpage === "development" && <Development lang={lang} />}
      {mainpage === "policy" && <Policy lang={lang} />}
      {mainpage === "operation" && <Operation lang={lang} />}
      {mainpage === "social" && <Social lang={lang} />}
      {mainpage === "report" && <Report lang={lang} />}

      <Footer />
    </Fragment>
  );
}

export default Sustainable;
