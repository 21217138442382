import React from "react";

function NavItem({ href, title }) {
  return (
    <li className="  w-100  d-flex flex-column border-bottom border-secondary">
      <a
        href={href}
        className=" fs-5 nav-link d-flex justify-content-between align-items-center "
      >
        <span className="py-2 ">{title}</span>{" "}
      </a>
    </li>
  );
}

export default NavItem;
